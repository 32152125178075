import { SelectRadioProps } from './SelectRadio.types';
import { colors } from 'styles';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div<Omit<SelectRadioProps, 'label'>>`
  width: 100%;
  min-height: 66px;
  display: flex;
  align-items: center;
  border: none;
  border-bottom: 1px solid ${transparentize(0.87, colors.black)};
  user-select: none;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
  padding: 5px;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Helvetica Now Display Bold';
    font-size: 16px;
    cursor: pointer;
  }
`;

export const Input = styled.input.attrs({ type: 'radio' })`
  -webkit-appearance: none;
  appearance: none;
  display: grid;
  place-content: center;
  cursor: pointer;
  background-color: ${colors.whiteLight};
  margin: 0;
  padding: 0;
  font: inherit;
  width: 28px;
  height: 28px;
  border: 1px solid ${colors.grayLight};
  border-radius: 50%;

  &:checked {
    border-color: ${colors.black};
  }

  &::before {
    content: '';
    width: 9px;
    height: 9px;
    background-color: ${colors.whiteLight};
    border: 9px solid ${colors.black};
    border-radius: 50%;
    opacity: 0;
    transition: 120ms opacity ease-in-out;
  }

  &:checked::before {
    opacity: 1;
  }
`;
