import * as Style from './AlertModal.styles';
import { AlertModalProps } from './AlertModal.types';
import * as Text from 'styles/text';
import { colors } from 'styles';
import { GenericButton } from 'components/Buttons';
import { When } from 'react-if';

import CloseIcon from 'assets/images/icons/close-delete.svg';

const AlertModal = (props: AlertModalProps) => {
  return (
    <Style.ModalBackGround>
      <Style.ModalContainer>
        <Style.TitleCloseBtnContainer>

          <Text.Heading4Bold marginLeft={30} marginTop={5}>
            {props.title}
          </Text.Heading4Bold>
          <Style.CloseBtn onClick={props.setOpenAlert}>
            <img src={CloseIcon} alt="Close icon" />
          </Style.CloseBtn>

        </Style.TitleCloseBtnContainer>

        <Style.BodyContainer>
          {props.children}
        </Style.BodyContainer>

        <Style.BottomContainer justifyContent={!props.primaryButtonAction ? `flex-end` : `space-between`}>
          <GenericButton
            noInlineBorder
            grayHover
            buttonType="inline"
            onClick={props.primaryButtonAction}
          >
            {props.primaryButtonText}
          </GenericButton>

          <GenericButton
            buttonType="secondary"
            onClick={props.secondaryButtonAction}
          >
            {props.secondaryButtonText}
          </GenericButton>

        </Style.BottomContainer>
      </Style.ModalContainer>
    </Style.ModalBackGround>
  );
};

export default AlertModal;
