import * as Style from '../Kyc.styles';
import * as Text from 'styles/text';

import React, { useEffect, useState } from 'react';

import { CheckSelect } from '~/components';
import Footer from '~/components/Footer';
import { ReactComponent as GlobeIcon } from 'assets/images/icons/globe.svg';
import Header from '~/components/Header';
import { StepProps } from '../Kyc.types';
import { ReactComponent as WorldIcon } from 'assets/images/icons/world.svg';
import { useFormContext } from 'react-hook-form';
import { useProgressBar } from '~/store';
import { useTranslation } from 'react-i18next';
import { userId } from '~/services/url';

export default function FormNationality({ goToStep }: StepProps) {
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const { setProgressPct, totalOfSteps } = useProgressBar();
  const [isBrazilian, setIsBrazilian] = useState(null);

  const userUuid = userId()

  const selectIfIsBrazilian = (value: boolean) => {
    if (isBrazilian === value) return;
    setIsBrazilian(value);
    setValue('isBrazilian', value);
  };
  useEffect(() => {
    // @ts-ignore
    gtag('event', 'screen_view', {
      'screen_name': 'Nationality_step',
      userId: userUuid
    });
    
    setProgressPct((3 / totalOfSteps) * 100);
  }, []);

  const customGoStep = () => {
    if (watch('isBrazilian') === false) {
      goToStep('next');
    } else {
      setValue('country', 'Brasil');
      goToStep('formOccupation');
    }
  };

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<WorldIcon />} />
      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t("What's your nationality?")}
      </Text.Heading1Medium>

      <CheckSelect
        icon={<GlobeIcon />}
        title={t('I am Brazilian')}
        selected={watch('isBrazilian') === true}
        onClick={() => selectIfIsBrazilian(true)}
      />
      <CheckSelect
        icon={<GlobeIcon />}
        title={t("I'm a foreigner")}
        selected={watch('isBrazilian') === false}
        onClick={() => selectIfIsBrazilian(false)}
      />

      <Footer
        onClickLeftButton={() => goToStep('prev')}
        leftButtonContent={t('Back')}
        onClickGenericButton={customGoStep}
        disableGenericButton={
          watch('isBrazilian') === undefined || watch('isBrazilian') === null
        }
        genericButtonType="secondary"
        genericButtonContent={t('Continue')}
      />
    </Style.StepContent>
  );
}
