import * as Style from "../Kyc.styles";

import { AnimatePresence, motion } from "framer-motion";
import { Case, Else, If, Switch, Then, When } from "react-if";
import React, { useEffect, useRef, useState } from "react";
import { getBase64, rotateImage } from "~/helpers/images/manipulate";

import { Circles } from "react-loader-spinner";
import FrontAndBackDocs from "./CameraPages/Pages/FrontAndBackDocs";
import ImageViewer from "~/components/ImageViewer";
import Modal from "~/components/Modal";
import SeeCameraPage from "./CameraPages/Pages/SeeCameraPage";
import SeePhotoAndRotate from "./CameraPages/Pages/SeePhotoAndRotate";
import SendSelfie from "./19.SendSelfieDefaultFlow";
import { StepProps } from "../Kyc.types";
import aBlurCorrect from "assets/images/modalExamples/a-blur-correct.png";
import aBlurWrong from "assets/images/modalExamples/a-blur-wrong.png";
import axiosInst from "~/services/axios";
import clearDocPhoto from "assets/images/modalExamples/clear-doc-photo.png";
import { colors } from "~/styles";
import correctDocBack from "assets/images/modalExamples/correct-document-back.png";
import foldDocTip from "assets/images/modalExamples/fold-doc.png";
import imageCompression from "browser-image-compression";
import { supportsMediaDevices } from "~/helpers";
import unClearDocPhoto from "assets/images/modalExamples/unclear-doc-photo.png";
import unFoldDocTip from "assets/images/modalExamples/unfold-doc.png";
import useDetectDevice from "~/hooks/detectDevice";
import { useProgressBar } from "~/store";
import { useTranslation } from "react-i18next";
import { userId } from "~/services/url";
import wrongDocBack from "assets/images/modalExamples/wrong-document-back.png";

export default function SendDocsDefaultFlow(props: StepProps) {
  const userUuid = userId();
  const { goToStep, pageArgs } = props;
  const { setProgressPct } = useProgressBar();

  const options = {
    maxSizeMB: 0.9216,
    maxWidthOrHeight: 1400,
    useWebWorker: true,
    fileType: "image/jpeg",
  };
  // 1400 x 1000

  const isRgMask = ["rg", "rnm", "dni", "rne"].includes(pageArgs?.maskType);
  const inputRef = useRef<HTMLInputElement>(null);
  const webcamRef = useRef<any>(null);
  const openCamera = () => inputRef.current.click();

  const [docPosition, setDocPosition] = useState<"front" | "back">("front");
  const [frontImg, setFrontImg] = useState<any>("");
  const [backImg, setBackImg] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [seeRotate, setSeeRotate] = useState(false);
  const [seeSelfie, setSeeSelfie] = useState(false);
  const [openWebCam, setOpenWebCam] = useState(false);
  const [rotateDeg, setRotateDeg] = useState(0);
  const [loadingRotate, setLoadingRotate] = useState(false);
  const [useNativeCam, setUseNativeCam] = useState(false);
  const [openViewerImage, setOpenViewerImage] = useState(false);
  const [showInfoDocModal, setShowInfoDocModal] = useState(false);
  const [loadingSendDocs, setLoadingSendDocs] = useState(false);
  const [originalImgs, setOriginalImgs] = useState({
    front: null,
    back: null,
  });

  const device = useDetectDevice();

  useEffect(() => {
    // @ts-ignore
    gtag("event", "screen_view", {
      screen_name: "send_doc_native_step",
      userId: userUuid,
    });

    if (!supportsMediaDevices() || device.isIos() || device.isAndroid())
      setUseNativeCam(true);
    setProgressPct(90);
  }, []);

  const { t } = useTranslation();

  const hasAllImgs = frontImg.length > 1 && backImg.length > 1;
  const currentPhoto = originalImgs.back
    ? originalImgs.back
    : originalImgs.front;

  const saveFile = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    let photoB64WebCam = null;

    if (!useNativeCam) {
      let base64 = await webcamRef.current.takePhoto();
      if (!base64) return;

      photoB64WebCam = await imageCompression.getFilefromDataUrl(
        base64,
        "file"
      );

      setOpenWebCam(false);
    }
    setLoading(true);

    const compressedFile = !useNativeCam ? photoB64WebCam : e.target.files[0];

    let compressToB64 = await getBase64(compressedFile);

    await rotateImage(
      String(compressToB64),
      rotateDeg - 90,
      async (img: string) => {
        if (docPosition === "front") {
          setOriginalImgs({
            back: null,
            front: img,
          });
          setFrontImg(img);
          setDocPosition("back");
        } else {
          setOriginalImgs((prev) => ({
            ...prev,
            back: img,
          }));
          setBackImg(img);
        }
        inputRef.current.value = "";
        setLoading(false);
        setSeeRotate(true);
      }
    );
  };

  const clearAllPhotos = () => {
    setFrontImg("");
    setBackImg("");
    setOriginalImgs({
      front: null,
      back: null,
    });
  };

  const stepSend = (direction: any, pageArgs: any) => {
    setRotateDeg(0);
    goToStep(direction, { ...pageArgs });
  };

  const rotateAndCompressImg = async () => {
    setLoadingRotate(true);
    setLoading(true);
    const imgSrc = document.getElementById("rotated-img").getAttribute("src");

    await rotateImage(imgSrc, rotateDeg, (img: string) => {
      if (frontImg.length > 1 && backImg.length < 1) {
        setOriginalImgs({
          back: null,
          front: img,
        });
      } else {
        setOriginalImgs((prev) => ({
          ...prev,
          back: img,
        }));
      }
    });

    await rotateImage(imgSrc, rotateDeg + 90, async (img: string) => {
      const imgFile = await imageCompression.getFilefromDataUrl(img, "file");
      const imgFileCompressed = await imageCompression(imgFile, options);
      const imgFileB64 = await imageCompression.getDataUrlFromFile(
        imgFileCompressed
      );

      if (backImg.length > 1) {
        setBackImg(imgFileB64);
        setLoadingRotate(false);
        setLoading(false);
      } else if (frontImg.length > 1) {
        setFrontImg(imgFileB64);
        setLoadingRotate(false);
        setLoading(false);
      }
    });
  };

  const rotateImageControl = (position: string) => {
    if (loadingRotate) return;
    if (position === "left") {
      setRotateDeg((prev: number) => prev - 90);
    } else {
      setRotateDeg((prev: number) => prev + 90);
    }
  };

  const goBack = () => {
    if (frontImg.length < 1) {
      goToStep("prev");
    } else {
      if (hasAllImgs && seeSelfie) {
        goToStep("prev");
      } else if (backImg.length > 1) {
        if (!useNativeCam) {
          setOpenWebCam(true);
        }
        setBackDocStep(0);
        setSeeRotate(false);
        setBackImg("");
        setOriginalImgs({ ...originalImgs, back: null });
        setShowInfoDocModal(false);
      } else if (frontImg.length > 1 && seeRotate) {
        setSeeRotate(false);
        setDocPosition("front");
        if (!useNativeCam) {
          setOpenWebCam(true);
        }
        clearAllPhotos();
        setFrontDocStep(0);
        setBackDocStep(0);
        setShowInfoDocModal(false);
      } else {
        setSeeRotate(true);
      }
    }
    setRotateDeg(0);
  };

  const nextStep = async () => {
    if (seeRotate) {
      rotateAndCompressImg();
    }

    setRotateDeg(0);
    if (frontImg.length > 1 && seeRotate) {
      if (hasAllImgs) {
        if (backDocStep === 0 && backImg.length > 1) {
          setShowInfoDocModal(true);
          return;
        }

        setLoadingSendDocs(true);

        await rotateImage(backImg, rotateDeg + 90, async (img: string) => {
          const imgFile = await imageCompression.getFilefromDataUrl(
            img,
            "file"
          );
          const imgFileCompressed = await imageCompression(imgFile, options);
          const imgFileB64 = await imageCompression.getDataUrlFromFile(
            imgFileCompressed
          );

          const resp = {
            userKycData: {
              documentB64: [frontImg, imgFileB64],
            },
          };
          try {
            await axiosInst.post("registercustomer/" + userUuid, resp);
            setSeeSelfie(true);
            setSeeRotate(false);
            setFrontImg("clear files");
            setBackImg("clear files");
            setOriginalImgs({
              front: null,
              back: null,
            });
          } catch (e) {
            alert(t("Error on send docs, please retry."));
            console.error(e);
          }
          setLoadingSendDocs(false);
        });
        return;
      }

      if (frontDocStep === 0) {
        setShowInfoDocModal(true);
      } else {
        setShowInfoDocModal(false);
        setSeeRotate(false);
      }
    } else {
      if (supportsMediaDevices() && !useNativeCam) {
        setOpenWebCam(true);
      } else {
        openCamera();
      }
    }
  };

  const useNativeWebCam = () => {
    openCamera();
    setUseNativeCam(true);
    setOpenWebCam(false);
  };

  ////// confirm modal part

  const [frontDocStep, setFrontDocStep] = useState(0);
  const [backDocStep, setBackDocStep] = useState(0);

  const tryPhotoAgain = () => {
    goBack();
  };

  const nextModalStep = () => {
    if (backImg.length > 1) {
      setBackDocStep((prev) => prev + 1);
    } else {
      setFrontDocStep((prev) => prev + 1);
    }
  };

  const docModalStepFront = [
    <Modal
      key={1}
      image={currentPhoto}
      secondImage={aBlurWrong}
      thirdImage={aBlurCorrect}
      title={t("Is all information readable, no shadows or blurs?")}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that all information is legible and in good lighting."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal
      type="doc"
      stepPosition="1 / 3"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextModalStep}
    />,
    <Modal
      key={2}
      secondImage={foldDocTip}
      thirdImage={unFoldDocTip}
      image={currentPhoto}
      title={t(
        "Is the document correctly folded as shown in the illustration below?"
      )}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t("I confirm that the document is folded correctly.")}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="doc"
      stepPosition="2 / 3"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextModalStep}
    />,
    <Modal
      key={3}
      image={currentPhoto}
      secondImage={unClearDocPhoto}
      thirdImage={clearDocPhoto}
      title={t("Is the background of the image clear and not patterned?")}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that the background of the image is clear and without patterns."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="doc"
      stepPosition="3 / 3"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextStep}
    />,
  ];

  const docModalStepBack = [
    <Modal
      key={1}
      secondImage={aBlurWrong}
      thirdImage={aBlurCorrect}
      image={currentPhoto}
      title={t("Is all the text information in the image readable?")}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that the text in the image is fully readable."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="doc"
      stepPosition="1 / 3"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextModalStep}
    />,
    <Modal
      key={2}
      image={currentPhoto}
      secondImage={wrongDocBack}
      thirdImage={correctDocBack}
      title={t(
        "Does the image match the back of the document, as shown in the illustration below?"
      )}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that the image matches the back of the document."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="doc"
      stepPosition="2 / 3"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextModalStep}
    />,
    <Modal
      key={3}
      image={currentPhoto}
      secondImage={unClearDocPhoto}
      thirdImage={clearDocPhoto}
      title={t("Is the background of the image clear and not patterned?")}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that the background of the image is clear and without patterns."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="doc"
      stepPosition="3 / 3"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextStep}
    />,
  ];

  // end confirm modal

  return (
    <>
      <Style.ContainerCamera>
        <AnimatePresence>
          {loading && (
            <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <div className="loading-container">
                <Circles
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  color={colors.primary}
                  height={80}
                  width={80}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <Switch>
          <Case condition={openWebCam}>
            <SeeCameraPage
              webcamRef={webcamRef}
              setUseNativeCam={useNativeWebCam}
              goBack={() => setOpenWebCam(false)}
              docPosition={docPosition}
              isRgMask={isRgMask}
              saveFile={saveFile}
            />
          </Case>
          <Case condition={seeRotate}>
            <SeePhotoAndRotate
              docType={pageArgs?.maskType}
              docPosition={backImg.length > 1 ? "back" : "front"}
              rotateImageControl={rotateImageControl}
              isRgMask={isRgMask}
              currentPhoto={currentPhoto}
              goBack={goBack}
              loadingRotate={loadingRotate}
              nextStep={nextStep}
              rotateDeg={rotateDeg}
              loadingSendDocs={loadingSendDocs}
            />
          </Case>
          <Case condition={frontImg.length < 1 || backImg.length < 1}>
            <FrontAndBackDocs
              docType={pageArgs?.maskType}
              isRgMask={isRgMask}
              docPosition={docPosition}
              nextStep={nextStep}
              goBack={goBack}
            />
          </Case>
          <Case condition={seeSelfie}>
            <SendSelfie
              showNativeCam={useNativeCam}
              goBack={goBack}
              goToStep={stepSend}
            />
          </Case>
        </Switch>
      </Style.ContainerCamera>

      <When
        condition={
          seeRotate &&
          frontImg.length > 1 &&
          frontDocStep < 3 &&
          showInfoDocModal
        }
      >
        {docModalStepFront[frontDocStep]}
      </When>

      <When
        condition={
          seeRotate && backImg.length > 1 && backDocStep < 4 && showInfoDocModal
        }
      >
        {docModalStepBack[backDocStep]}
      </When>

      <input
        ref={inputRef}
        style={{ display: "none" }}
        id="my_file"
        type="file"
        accept="image/png, image/jpeg"
        capture
        onChange={(e) => {
          saveFile(e);
        }}
      />

      <When condition={openViewerImage}>
        <ImageViewer
          image={currentPhoto}
          setOpenViewerImage={setOpenViewerImage}
        />
      </When>
    </>
  );
}
