import * as Style from "./CheckSelect.styles";
import * as Text from "styles/text";
import { CheckSelectProps } from "./CheckSelect.types";
import { colors } from "styles";
import { ReactComponent as BlueIconFiled } from "assets/images/icons/blue-check-full.svg";
import { forwardRef } from "react";
import { If, Then, When, Else } from "react-if";

const CheckSelect = forwardRef((props: CheckSelectProps, _ref) => {
  return (
    <Style.Container onClick={props.onClick}>
      <Style.BodyContainer className="move-top">
        <Style.ContainerIcon>
          <If condition={!!props.selected}>
            <Then>
              <BlueIconFiled />
            </Then>
            <Else>{props.icon}</Else>
          </If>
        </Style.ContainerIcon>
        <Style.ContainerText>
          <When condition={!!props.title}>
            <Text.Heading4>{props.title}</Text.Heading4>
          </When>
          <When condition={!!props.text}>
            <Text.Small color={colors.grayMedium} marginTop={5}>
              {props.text}
            </Text.Small>
          </When>
        </Style.ContainerText>
      </Style.BodyContainer>
    </Style.Container>
  );
});

export default CheckSelect;
