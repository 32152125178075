import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { BigInputProps } from './BigInput.types';

export const ContainerInput = styled.div``;

export const Input = styled.input<BigInputProps & { textAlign?: string, fontSize?: number }>`
  width: 100%;
  height: 70px;
  font-family: 'Helvetica Now Display Medium';
  font-weight: 500;
  font-size: ${props => props.fontSize || 31}px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${colors.grayRegular};
  padding: 16px;
  background-color: ${colors.transparent};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  transition: border 0.3s ease-out;
  margin-bottom: 10px;

  :focus {
    border-bottom: 2px solid ${colors.black} !important;
  }

  :hover {
    border-color: ${colors.grayMedium};
  }

  ::placeholder {
    color: ${colors.grayRegular};
    opacity: 1;
  }

  ${(props) => props.error &&
    css`
      border-color: ${colors.danger};

      &:focus {
        border-bottom: solid 2px ${colors.danger} !important;
      }

      &:hover {
        border-color: ${colors.danger};
      }
    `
  }
`;
