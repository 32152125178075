import * as Style from "./DocumentCard.styles";
import * as Text from "styles/text";
import { DocumentCardProps } from "./DocumentCard.types";
import { colors } from "~/styles";
import { If, Then, Else } from "react-if";
import LoadingSpinner from "~/components/LoadingSpinner";
import { ReactComponent as CheckDocumentIcon } from "assets/images/icons/doc-check.svg";
import { useTranslation } from "react-i18next";

const DocumentCard = (props: DocumentCardProps) => {
  const { t } = useTranslation();
  return (
    <Style.Container>
      <div className={`icon-container ${props.isChecked && `checked`}`}>
        {props.documentType}
      </div>

      <If condition={props.isChecked}>
        <Then>
          <CheckDocumentIcon />
        </Then>

        <Else>
          <LoadingSpinner color={colors.primary} strokeSize={9} />
        </Else>
      </If>

      <Text.Heading5 marginTop={11} marginBottom={7} textAlign="center">
        {props.text}
      </Text.Heading5>
      <Text.Smaller
        color={props.isChecked ? colors.primary : colors.grayRegular}
      >
        {props.isChecked ? t("Photo Attached!") : t("Waiting...")}
      </Text.Smaller>
    </Style.Container>
  );
};

export default DocumentCard;
