import styled from 'styled-components';
import { colors } from 'styles';
import { InputSelectProps } from './InputSelect.types';

export const SelectContainer = styled.div<Omit<InputSelectProps, 'options'>>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;

  .input-wrapper {
    display: flex;
    height: 46px;
    position: relative;
  }

  .arrow {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    transition: transform 0.3s ease-out;
  }

  .active {
    transform: rotate(-180deg);
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 47px;
  padding: 0px 15px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${colors.grayRegular};
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 0.3s ease-out;
  background-color: ${colors.transparent};
  z-index: 1;
  
  :hover {
    border-color: ${colors.grayMedium};
  }

  &:invalid {
    color: ${colors.grayRegular} !important;
  }
`;
