import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import {
  CameraProps,
  FacingMode,
  Stream,
  SetStream,
  SetNumberOfCameras,
  SetNotSupported,
  SetPermissionDenied,
} from "./WebCam.types";
import {
  Container,
  Wrapper,
  Canvas,
  Cam,
  WrapperPermissionScreen,
} from "./WebCam.styles";
import * as Text from "styles/text";
import { Else, If, Then, When } from "react-if";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import { useTranslation } from "react-i18next";
import { GenericButton } from "../Buttons";
import { ReactComponent as RedFace } from "assets/images/red-face.svg";

export const Camera = React.forwardRef<unknown, CameraProps>(
  (
    {
      facingMode = "user",
      aspectRatio = "cover",
      numberOfCamerasCallback = () => null,
      errorMessages = {
        noCameraAccessible:
          "No camera device accessible. Please connect your camera or try a different browser.",
        permissionDenied:
          "Permission denied. Please refresh and give camera permission.",
        switchCamera:
          "It is not possible to switch camera to different one because there is only one video device accessible.",
        canvas: "Canvas is not supported.",
      },
      maskImage = null,
      useNativeCam = () => {},
      maskPadding = 0,
    },
    ref
  ) => {
    const player = useRef<HTMLVideoElement>(null);
    const canvas = useRef<HTMLCanvasElement>(null);
    const container = useRef<HTMLDivElement>(null);
    const [numberOfCameras, setNumberOfCameras] = useState<number>(0);
    const [stream, setStream] = useState<Stream>(null);
    const [currentFacingMode, setFacingMode] = useState<FacingMode>(facingMode);
    const [notSupported, setNotSupported] = useState<boolean>(false);
    const [permissionDenied, setPermissionDenied] = useState<boolean>(false);

    const { t } = useTranslation();

    const requestPermission = async () => {
      await requestMediaPermissions()
        .then(() => {
          setPermissionDenied(false);
          setNotSupported(false);
          initCameraStream(
            stream,
            setStream,
            currentFacingMode,
            setNumberOfCameras,
            setNotSupported,
            setPermissionDenied
          );
        })
        .catch((err: MediaPermissionsError) => {
          const { type, name, message } = err;
          if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
            alert(
              t(
                "Please allow camera permission to use this feature, in your browser settings and app settings."
              )
            );
          } else if (
            type === MediaPermissionsErrorType.CouldNotStartVideoSource
          ) {
            alert(t("Camera is in use by another application"));
          } else {
            alert(JSON.stringify(err));
          }
        });
    };

    useEffect(() => {
      numberOfCamerasCallback(numberOfCameras);
    }, [numberOfCameras]);

    useImperativeHandle(ref, () => ({
      takePhoto: () => {
        if (numberOfCameras < 1) {
          throw new Error(errorMessages.noCameraAccessible);
        }

        if (canvas?.current) {
          const playerWidth = player?.current?.videoWidth || 1280;
          const playerHeight = player?.current?.videoHeight || 720;
          const playerAR = playerWidth / playerHeight;

          const canvasWidth = container?.current?.offsetWidth || 1280;
          const canvasHeight = container?.current?.offsetHeight || 1280;
          const canvasAR = canvasWidth / canvasHeight;

          let sX, sY, sW, sH;

          if (playerAR > canvasAR) {
            sH = playerHeight;
            sW = playerHeight * canvasAR;
            sX = (playerWidth - sW) / 2;
            sY = 0;
          } else {
            sW = playerWidth;
            sH = playerWidth / canvasAR;
            sX = 0;
            sY = (playerHeight - sH) / 2;
          }

          canvas.current.width = sW;
          canvas.current.height = sH;

          const context = canvas.current.getContext("2d");
          if (context && player?.current) {
            context.drawImage(player.current, sX, sY, sW, sH, 0, 0, sW, sH);
          }

          const imgData = canvas.current.toDataURL("image/jpeg");
          return imgData;
        } else {
          throw new Error(errorMessages.canvas);
        }
      },
      switchCamera: () => {
        if (numberOfCameras < 1) {
          throw new Error(errorMessages.noCameraAccessible);
        } else if (numberOfCameras < 2) {
          console.error(
            "Error: Unable to switch camera. Only one device is accessible."
          );
        }
        const newFacingMode =
          currentFacingMode === "user" ? "environment" : "user";
        setFacingMode(newFacingMode);
        return newFacingMode;
      },
      getNumberOfCameras: () => {
        return numberOfCameras;
      },
    }));

    useEffect(() => {
      initCameraStream(
        stream,
        setStream,
        currentFacingMode,
        setNumberOfCameras,
        setNotSupported,
        setPermissionDenied
      );
    }, [currentFacingMode]);

    useEffect(() => {
      if (stream && player && player.current) {
        player.current.srcObject = stream;
      }
      return () => {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      };
    }, [stream]);

    const isPortrait = window.matchMedia("(orientation: portrait)").matches;

    const autoHeight =
      window.innerWidth / 0.75 > window.innerHeight - 84 &&
      isPortrait &&
      aspectRatio !== "cover";
    return (
      <If condition={permissionDenied || notSupported}>
        <Then>
          <When condition={permissionDenied || notSupported}>
            <WrapperPermissionScreen>
              <div className="container-body">
                <RedFace style={{ borderRadius: 15 }} />
                <Text.Heading1Bold marginTop={30}>
                  {t(
                    "It looks like your browser doesn't have camera permissions."
                  )}
                </Text.Heading1Bold>
                <Text.Heading4 marginTop={25}>
                  {t(
                    "Enable camera permission in app settings or use native camera to take pictures."
                  )}
                </Text.Heading4>
              </div>
              <div className="container-buttons">
                <GenericButton
                  width="100%"
                  noInlineBorder
                  buttonType="inline"
                  onClick={requestPermission}
                >
                  {t("Ask permission again")}
                </GenericButton>
                <GenericButton
                  buttonType="secondary"
                  width="100%"
                  onClick={useNativeCam}
                >
                  {t("Start Camera")}
                </GenericButton>
              </div>
            </WrapperPermissionScreen>
          </When>
        </Then>
        <Else>
          <Container
            style={
              autoHeight
                ? {
                    height: window.innerHeight - 84,
                    width: !isPortrait ? "80%" : "100%",
                  }
                : {}
            }
            ref={container}
            aspectRatio={autoHeight ? null : aspectRatio}
          >
            <Wrapper>
              <When
                condition={!!maskImage && !permissionDenied && !notSupported}
              >
                <img
                  style={
                    facingMode !== "user"
                      ? {
                          padding: maskPadding,
                          height: !isPortrait
                            ? window.innerWidth * 0.65
                            : "100%",
                          transform: !isPortrait ? "rotate(270deg)" : "unset",
                        }
                      : {
                          width: "100%",
                          height: window.innerHeight,
                          padding: maskPadding,
                        }
                  }
                  className="mask-image"
                  src={maskImage}
                  alt="mask"
                />
              </When>
              <When condition={!permissionDenied && !notSupported}>
                <Cam
                  ref={player}
                  id={"video-webcam"}
                  muted={true}
                  autoPlay={true}
                  playsInline={true}
                  mirrored={currentFacingMode === "user" ? true : false}
                />
                <Canvas ref={canvas} />
              </When>
            </Wrapper>
          </Container>
        </Else>
      </If>
    );
  }
);

Camera.displayName = "Camera";

const initCameraStream = (
  stream: Stream,
  setStream: SetStream,
  currentFacingMode: FacingMode,
  setNumberOfCameras: SetNumberOfCameras,
  setNotSupported: SetNotSupported,
  setPermissionDenied: SetPermissionDenied
) => {
  // stop any active streams in the window
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  const constraints = {
    audio: false,
    video: {
      facingMode: currentFacingMode,
      width: { ideal: 1920 },
      height: { ideal: 1920 },
    },
  };

  if (navigator?.mediaDevices?.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setStream(handleSuccess(stream, setNumberOfCameras));
      })
      .catch((err) => {
        handleError(err, setNotSupported, setPermissionDenied);
      });
  } else {
    const getWebcam =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (getWebcam) {
      getWebcam(
        constraints,
        (stream) => {
          setStream(handleSuccess(stream, setNumberOfCameras));
        },
        (err) => {
          handleError(err as Error, setNotSupported, setPermissionDenied);
        }
      );
    } else {
      setNotSupported(true);
    }
  }
};

const handleSuccess = (
  stream: MediaStream,
  setNumberOfCameras: SetNumberOfCameras
) => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((r) =>
      setNumberOfCameras(r.filter((i) => i.kind === "videoinput").length)
    );

  return stream;
};

const handleError = (
  error: Error,
  setNotSupported: SetNotSupported,
  setPermissionDenied: SetPermissionDenied
) => {
  if (error.name === "PermissionDeniedError") {
    setPermissionDenied(true);
  } else {
    setNotSupported(true);
  }
};
