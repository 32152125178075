import * as Style from "./Kyc.styles";
import * as Text from "styles/text";

import { AnimatePresence, motion } from "framer-motion";
import { FormFields, Steps, StepsMobileEnum, StepsWebEnum } from "./Kyc.types";
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import BannerBackgroundImage from "assets/images/main-background.svg";
import CapitualLogoIcon from "assets/images/logo-white.svg";
import Finish from "./Steps/20.Finish";
import FormAddress from "./Steps/11.FormAddress";
import FormBirthDate from "./Steps/10.FormBirthDate";
import FormCPF from "./Steps/3.FormCPF";
import FormCountry from "./Steps/5.FormCountry";
import FormName from "./Steps/2.FormName";
import FormNationality from "./Steps/4.FormNationality";
import FormPhone from "./Steps/12.FormPhone";
import Income from "./Steps/7.Income";
import MotherName from "./Steps/9.MotherName";
import Occupation from "./Steps/6.Occupation";
import PoliticallyExposed from "./Steps/8.PoliticallyExposed";
import QRCode from "./Steps/13.QRCode";
import SelectDoc from "./Steps/15.SelectDoc";
import PreparationDoc from "./Steps/14.PreparationDoc";
import Start from "./Steps/1.Start";
import { When } from "react-if";
import axiosInst from "~/services/axios";
import { breakpoints } from "styles/metrics";
import { colors } from "~/styles";
import i18n from "i18n";
import useDetectDevice from "hooks/detectDevice";
import { useFlow, useProgressBar } from "~/store";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "hooks/windowDimension";
import { userId } from "~/services/url";
import { validationSchema } from "./validations";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectDocDefaultFlow from "./Steps/17.SelectDocDefaultFlow";
import SendDocsDefaultFlow from "./Steps/18.SendDocsDefaultFlow";
import SendSelfieDefaultFlow from "./Steps/19.SendSelfieDefaultFlow";
import WaitSendDocsDefaultFlow from "./Steps/16.WaitSendDocsDefaultFlow";

const Kyc = () => {
  const userUuid = userId();

  const detect = useDetectDevice();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const { flow } = useFlow();
  const { progressPct, setTotalOfSteps } = useProgressBar();

  const [stepPositions, setStepPositions] = useState([
    "start",
    "formCPF",
    "formName",
    "formNationality",
    "formCountry",
    "formOccupation",
    "formIncome",
    "formPoliticallyExposed",
    "formMotherName",
    "formBirthDate",
    "formAddress",
    "formPhone",
    "qrcode",
    "preparationDoc",
    "selectDoc",
    "waitSendDocsDefaultFlow",
    "selectDocDefaultFlow",
    "sendDocsDefaultFlow",
    "sendSelfieDefaultFlow",
    "finish",
  ]);

  //filter if the user is on mobile or web
  const getStepsForDevices = stepPositions.filter(function (e) {
    return (
      Object.values(
        detect.isDesktop() ? StepsWebEnum : StepsMobileEnum
      ).indexOf(e) > -1
    );
  });

  const [activeStep, setActiveStep] = useState(getStepsForDevices[0]);

  useEffect(() => {
    setTotalOfSteps(getStepsForDevices.length - 2);
  }, [getStepsForDevices.length, setTotalOfSteps]);

  useEffect(() => {
    const flag =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    if (flag === "33452") {
      setStepPositions(["preparationDoc", "selectDoc", "finish"]);
    }
  }, []);

  const [pageArgs, setPageArgs] = useState({} as any);

  // hook form
  const defaultValues: FormFields = {
    fullName: "",
    cpf: "",
    isBrazilian: undefined,
    country: "",
    occupation: "",
    declaredIncome: "",
    politicallyExposed: "",
    motherName: "",
    birthDate: "",
    cep: "",
    state: "",
    city: "",
    address: "",
    number: "",
    neighborhood: "",
    complement: "",
    phone: "",
  };

  const currentValidationSchema = validationSchema[activeStep];
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const methods = useForm<FormFields>({
    shouldUnregister: false,
    defaultValues,
    mode: "onBlur",
    resolver: currentValidationSchema && yupResolver(currentValidationSchema),
  });

  const { handleSubmit, trigger } = methods;

  const changeLanguageHandler = (lang: "pt-BR" | "en-US" | string) => {
    i18n.changeLanguage(lang);
  };

  const isCameraEnabled = activeStep === "selectDoc";

  const handleStep = async (
    direction: "prev" | "next" | Steps,
    args?: object
  ) => {
    //save args between screens
    if (args) {
      setPageArgs({ pageArgs, ...args });
    }

    // show prev
    const isStepValid = activeStep !== "start" && (await trigger());

    if (direction === "prev" && activeStep !== getStepsForDevices[0]) {
      setActiveStep(
        (prev) => getStepsForDevices[0 + getStepsForDevices.indexOf(prev) - 1]
      );
    } else if (
      //show next
      direction === "next" &&
      activeStep !== getStepsForDevices[getStepsForDevices.length - 1]
    ) {
      if (activeStep === "start") {
        setActiveStep(
          (prev) => getStepsForDevices[1 + getStepsForDevices.indexOf(prev)]
        );
      } else if (isStepValid) {
        setActiveStep(
          (prev) => getStepsForDevices[1 + getStepsForDevices.indexOf(prev)]
        );
      }
    } else if (direction !== "next" && direction !== "prev") {
      //show custom step
      setActiveStep(direction);
    }
  };

  const docsTypes = [
    { abv: "rg", name: "idcard" },
    { abv: "cnh", name: "driverlicense" },
    { abv: "rne", name: "rne" },
    { abv: "rnm", name: "crnm" },
    { abv: "dni", name: "dni" },
  ];

  const selectedDocTypeFiltered = docsTypes.filter(
    (doc) => doc.abv === pageArgs?.pageArgs?.maskType
  )[0]?.name;

  const selectedDocTypeFilteredFacetec = docsTypes.filter(
    (doc) => doc.abv === pageArgs?.maskType
  )[0]?.name;

  const onSubmit = async (data: any) => {
    const resp = {
      userPhone: data.phone.replace(/^0-9/g, ""),
      userKycData: {
        documentType:
          flow === "default"
            ? selectedDocTypeFiltered
            : selectedDocTypeFilteredFacetec,
        fullName: data.fullName,
        birthday: data.birthDate.replace(
          /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/,
          "$3-$2-$1"
        ),
        cpfCnpj: data.cpf,
        motherName: data.motherName,
        address: {
          street: data.address,
          number: data.number,
          district: data.neighborhood,
          city: data.city,
          state: data.state,
          zip: data.cep,
          complement: data.complement,
          country: "BR",
        },
        isPep: data.politicallyExposed,
        declaredIncome: data.declaredIncome,
        occupation: data.occupation,
      },
    };

    setPageArgs({ ...pageArgs, uploadStatus: "sending" });

    try {
      await axiosInst
        .post("registercustomer/" + userUuid + "/true", resp)
        .then(() => {
          // @ts-ignore
          gtag("event", "user_data_sent_to_server", {
            screen_name: "finish_step",
          });
        });
      setPageArgs({ ...pageArgs, uploadStatus: "complete" });
    } catch (e) {
      console.error(e);
      // @ts-ignore
      gtag("event", "error_sending_user_data_to_server", {
        screen_name: "finish_step",
        error: e
      });
      setPageArgs({ ...pageArgs, uploadStatus: "failed" });
      setTimeout(() => onSubmit(data), 5000);
    }
  };

  //all steps
  const steps = {
    start: <Start goToStep={handleStep} />,
    formName: <FormName goToStep={handleStep} pageArgs={pageArgs} />,
    formCPF: <FormCPF goToStep={handleStep} />,
    formNationality: <FormNationality goToStep={handleStep} />,
    formCountry: <FormCountry goToStep={handleStep} />,
    formOccupation: <Occupation goToStep={handleStep} />,
    formIncome: <Income goToStep={handleStep} />,
    formPoliticallyExposed: <PoliticallyExposed goToStep={handleStep} />,
    formMotherName: <MotherName goToStep={handleStep} />,
    formBirthDate: <FormBirthDate goToStep={handleStep} />,
    formAddress: <FormAddress goToStep={handleStep} />,
    formPhone: <FormPhone goToStep={handleStep} />,
    qrcode: <QRCode goToStep={handleStep} onSubmit={handleSubmit(onSubmit)} />,

    // Facetec flow steps
    preparationDoc: (
      <PreparationDoc pageArgs={pageArgs} goToStep={handleStep} />
    ),
    selectDoc: <SelectDoc goToStep={handleStep} />,

    // Default flow steps
    waitSendDocsDefaultFlow: <WaitSendDocsDefaultFlow goToStep={handleStep} />,
    selectDocDefaultFlow: <SelectDocDefaultFlow goToStep={handleStep} />,
    sendDocsDefaultFlow: (
      <SendDocsDefaultFlow goToStep={handleStep} pageArgs={pageArgs} />
    ),
    finish: (
      <Finish
        goToStep={handleStep}
        onSubmit={handleSubmit(onSubmit)}
        pageArgs={pageArgs}
      />
    ),
  };

  const language = getLanguage();

  return (
    <Style.KycContainer>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <When condition={breakpoints.tablet < width && !isCameraEnabled}>
            <Style.SideImage>
              <div className="logo-container">
                <img src={CapitualLogoIcon} alt="Capitual logo" />

                <div className="text-container">
                  <Text.Paragraph
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://help.capitual.com/", "_blank")
                    }
                    color={colors.white}
                  >
                    {t("Help")}
                  </Text.Paragraph>
                  <select
                    onChange={(e) => changeLanguageHandler(e.target.value)}
                    name="language"
                    className="text-right"
                    defaultValue={getLanguage()}
                  >
                    <option value="pt-BR">{t("Portuguese")}</option>
                    <option value="en-US">{t("English")}</option>
                    <option value="es-Es">{t("Spanish")}</option>
                  </select>
                </div>
              </div>

              <img src={BannerBackgroundImage} alt="Banner" />

              <Text.Small color={colors.white}>
                &copy; {new Date().getFullYear()} Capitual Instituição de
                Pagamentos LTDA
              </Text.Small>
            </Style.SideImage>
          </When>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={`${activeStep} ${language}`}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Style.Content>
              <FormProvider {...methods}>{steps[activeStep]}</FormProvider>

              <When condition={activeStep !== "finish"}>
                <Style.ProgressContainer>
                  <div
                    className="bar"
                    style={{ width: `${progressPct || 1}%` }}
                  ></div>
                </Style.ProgressContainer>
              </When>
            </Style.Content>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </Style.KycContainer>
  );
};

export default Kyc;
