import { useEffect, useState } from "react";
import isWebview from "is-ua-webview";
import { useFormContext } from "react-hook-form";

import * as Style from "../Kyc.styles";
import * as Text from "styles/text";
import Footer from "~/components/Footer";
import Header from "~/components/Header";
import { StepProps } from "../Kyc.types";
import DocumentIcon from "assets/images/icons/document.svg";
import PersonalDataIcon from "assets/images/icons/personal-data.svg";
import SelfieIcon from "assets/images/icons/selfie.svg";
import { useFlow, useProgressBar, useFormStore } from "~/store";
import { ReactComponent as UserIcon } from "assets/images/icons/user.svg";
import { useTranslation } from "react-i18next";
import { userId } from "~/services/url";
import axiosInst from "~/services/axios";
import { validateFacetecCompatibility } from "~/helpers";

export default function Start({ goToStep, activeStep }: StepProps) {
  const { setProgressPct } = useProgressBar();
  const { setFlow } = useFlow();
  const { formData, loadFormData } = useFormStore();

  const [isProcessOpen, setIsProcessOpen] = useState(false);

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();

  useEffect(() => {
    try {
      for (const [key, value] of Object.entries(formData)) {
        setValue(key, value);
      }
    } catch (error) { }
  }, [formData]);

  useEffect(() => {
    const uid = userId();

    const supportFacetec = validateFacetecCompatibility() === "use_facetec";

    loadFormData();

    // @ts-ignore
    gtag("event", "screen_view", {
      screen_name: "Start",
      userId: uid,
    });

    setProgressPct(0);

    if (uid) {
      axiosInst
        .get("kycprocess/" + uid)
        .then(({ data: uData }) => {
          const useFacetec = uData.useFacetec;

          setFlow(useFacetec && supportFacetec ? "facetec" : "default");

          if (!uData?.kycProcess?.kycId || uData?.status !== '') {
            goToStep("finish", { uploadStatus: "complete" });
          } else {
            setIsProcessOpen(true);
          }
        })
        .catch(console.error);
    }
  }, []);

  const { t } = useTranslation();
  return (
    <Style.StepContent>
      <Header chooseLanguage icon={<UserIcon />} />
      <Text.Heading4 marginTop={24}>{t("BRL License")}</Text.Heading4>
      <Text.Heading1Medium fontSize={1.938} marginTop={8}>
        {t("Enable BRL License")}
      </Text.Heading1Medium>
      <Text.Heading4 marginTop={24} marginBottom={24}>
        {t("We need some of your personal data")}
      </Text.Heading4>

      <div className="instruction-container">
        <div className="instruction-card">
          <img src={DocumentIcon} alt="Identificação do usuário" />

          <Text.Heading5>{t("Personal Data")}</Text.Heading5>
        </div>

        <div className="instruction-card">
          <img src={PersonalDataIcon} alt="Identificação do usuário" />

          <Text.Heading5>{t("Identification Document")}</Text.Heading5>
        </div>

        <div className="instruction-card">
          <img src={SelfieIcon} alt="Identificação do usuário" />

          <Text.Heading5>Selfie</Text.Heading5>
        </div>
      </div>

      <Footer
        onClickGenericButton={() => {
          // if (isWebview(navigator.userAgent)) {
          //   return window.open(window.location.href, '_blank')
          // }

          if (userId() && isProcessOpen) goToStep("next")
        }}
        genericButtonType="secondary"
        genericButtonContent={t("Let's Go")}
      />
    </Style.StepContent>
  );
}
