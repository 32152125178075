import * as Style from "./InputPhone.styles";
import * as Text from "styles/text";
import { InputPhoneProps } from "./InputPhone.types";
import { When } from "react-if";
import { colors } from "styles";
import { forwardRef } from "react";

const InputPhone = forwardRef((props: InputPhoneProps, _ref) => {
  return (
    <Style.Container {...props}>
      <div className="input-container">
        <Style.Input
          {...props}
          onChange={(event) => {
            const { value } = event.target;
            if (!props.maskFunction) {
              props.onChange && props.onChange(event);
            }
            event.target.value = props.maskFunction(value);
            props.onChange && props.onChange(event);
          }}
        />
        <div>{props.ddi || "+55"}</div>
      </div>

      <Style.BottomContent>
        <When condition={!!props.error}>
          <div className="error-container">
            <Text.Small color={colors.danger}>{props.error}</Text.Small>
          </div>
        </When>
      </Style.BottomContent>
    </Style.Container>
  );
});

export default InputPhone;
