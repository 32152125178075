import styled, { css } from "styled-components";
import { colors } from "~/styles";
import { AspectRatio } from "./WebCam.types";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${(window.innerHeight < 450 || window.innerWidth) < 300 &&
  css`
    justify-content: center;
    align-items: center;
    display: flex;
  `}
`;

export const WrapperPermissionScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  .container-body {
    width: 100%;
    height: 85%;
    overflow-y: scroll;
  }

  .container-buttons {
    width: 90%;
    position: absolute;
    bottom: 100px;
  }
`;

export const Container = styled.div<{ aspectRatio: AspectRatio }>`
  width: 100%;

  ${({ aspectRatio }) =>
    aspectRatio === "cover"
      ? `
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;`
      : `
    position: relative;
    padding-bottom: ${100 / aspectRatio}%;`}

  .mask-image {
    z-index: 999;
    position: absolute;
    width: 100%;
    height: ${window.innerHeight * 0.85}px;
    object-fit: contain;
  }
`;

export const Cam = styled.video<{ mirrored: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  transform: rotateY(${({ mirrored }) => (mirrored ? "180deg" : "0deg")});
`;

export const Canvas = styled.canvas`
  display: none;
`;
