import React, { useEffect } from "react";
import * as Text from "styles/text";
import * as Style from "../Kyc.styles";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { StepProps } from "../Kyc.types";

import { ReactComponent as PhoneIcon } from "assets/images/icons/phone.svg";
import { InputPhone } from "~/components";
import { colors } from "~/styles";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { useFlow, useProgressBar, useFormStore } from "~/store";
import { cellphoneMask } from "~/helpers";
import { userId } from "~/services/url";

export default function FormPhone({ goToStep }: StepProps) {
  const { t } = useTranslation();
  const { setProgressPct, totalOfSteps } = useProgressBar();
  const { setFormData, saveFormData } = useFormStore();
  const { flow } = useFlow()
  const {
    control,
    formState: { errors },
    watch,
    getValues,
  } = useFormContext();

  const disableButton = !watch("phone");

  const userUuid = userId()

  useEffect(() => {
     // @ts-ignore
     gtag('event', 'screen_view', {
      'screen_name': 'phone_step',
      userId: userUuid
    });
    setProgressPct((8 / totalOfSteps) * 100);
  }, []);

  const handleGoToNextStep = () => {
    setFormData({ ...getValues() });
    saveFormData({ ...getValues() });

    if(flow === 'default') {
      goToStep("selectDocDefaultFlow");
      return
    }

    if(flow === 'facetec') {
      goToStep("preparationDoc");
      return
    }
  }

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<PhoneIcon />} />
      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t("Now we need a valid phone number")}
      </Text.Heading1Medium>

      <Text.Paragraph color={colors.grayMedium} marginBottom={5}>
        {t("Country/Region")}
      </Text.Paragraph>

      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <InputPhone
            {...field}
            autoFocus
            ddi="+55"
            error={errors[field.name]?.message as string}
            maskFunction={cellphoneMask}
          />
        )}
      />

      {/*<Text.Heading4 marginTop={24}>{t("We’ll send you a link")}</Text.Heading4>*/}

      <Footer
        onClickLeftButton={() => goToStep("prev")}
        leftButtonContent={t("Back")}
        onClickGenericButton={handleGoToNextStep}
        disableGenericButton={disableButton}
        genericButtonType="secondary"
        genericButtonContent={t("Continue")}
      />
    </Style.StepContent>
  );
}
