import UAParser from "ua-parser-js";
import isWebview from "is-ua-webview";

//DEVICE INFOS

export const browserInfo = () => {
  const parser = new UAParser();
  return parser.getResult();
};

//TOKENS ACTIONS
export const setToken = (token: string) => {
  localStorage.setItem("TOKEN", token);
};

export const getToken = () => {
  return localStorage.getItem("TOKEN");
};

export const clearStorage = () => {
  localStorage.clear();
};

// **
//  * Not all browsers support media devices, and some restrict access for
//  * insecure sites and private contexts. This is often reflected by removing
//  * the `mediaDevices` API entirely.
//  */
export function supportsMediaDevices() {
  return typeof navigator !== "undefined" && !!navigator.mediaDevices;
}

export const validateFacetecCompatibility = () => {
  const device = browserInfo();

  if (!device || !device.os || !device.browser) {
    return "use_native";
  }

  const os = device.os.name;
  const osVersion = parseFloat(device.os.version);

  const browser = device.browser.name;
  const browserVersion = parseFloat(device.browser.version);

  const isAndroid = os === "Android";
  const isAndroidOldVersion = osVersion <= 9;

  const isIos = os === "iOS";
  const isIosOldVersion = osVersion < 11;

  const isChrome = browser === "Chrome";
  const isChromeOldVersion = browserVersion < 90;

  const isSafari = browser === "Safari" || browser === "Mobile Safari";
  const isSafariOldVersion = browserVersion < 11;

  const isNotSupportedBrowser =
    browser.includes("Firefox") || browser.includes("Opera");

  if (isWebview(navigator.userAgent)) {
    return "use_native";
  }

  if (isAndroid && isAndroidOldVersion) {
    return "use_native";
  }

  if (isIos && isIosOldVersion) {
    return "use_native";
  }

  if ((isAndroid || isIos) && isNotSupportedBrowser) {
    return "use_native";
  }

  if (isChrome && isChromeOldVersion) {
    return "use_native";
  }

  if (isSafari && isSafariOldVersion) {
    return "use_native";
  }

  return "use_facetec";
};
