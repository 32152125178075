import react, { useState } from 'react';
import * as Style from "./BigInput.styles";
import * as Text from "styles/text";
import { BigInputProps } from "./BigInput.types";
import { When } from "react-if";
import { colors } from "styles";
import { ChangeEvent, forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const BigInput = forwardRef((props: BigInputProps, _ref) => {
  const [fontChange, setFontChange] = useState(31);

  const { t } = useTranslation();
  
  const checkFontSize = (event: any) => {
    if(event.length > 31 && fontChange > 20) {
      setFontChange(fontChange - 1);
    }
  }

  const cleanFontSize = (event: any) => {
    if(event.length == 0) {
      setFontChange(31);
    }
  }

  return (
    <Style.ContainerInput>
      <Style.Input
        {...props}
        id="textarea"
        fontSize={fontChange}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (!props.maskFunction) {
            props.onChange && props.onChange(event);
            checkFontSize(event.target.value);
            cleanFontSize(event.target.value);
          } else {
            const { value } = event.target;
            event.target.value = props.maskFunction(value);
            props.onChange && props.onChange(event);
          }
        }}
      />
      <When condition={!!props.error}>
        <div>
          <Text.Small color={colors.danger}>{t(props.error)}</Text.Small>
        </div>
      </When>
    </Style.ContainerInput>
  );
});

export default BigInput;
