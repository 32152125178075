import LoadingSpinner from "components/LoadingSpinner";
import { Else, If, Then } from "react-if";
import { colors } from "styles";
import * as Style from "./GenericButton.styles";
import { GenericButtonProps } from "./GenericButton.types";

const GenericButton = (props: GenericButtonProps) => {
  return (
    <Style.Container
      className={props.buttonType || "primary"}
      {...props}
      onClick={props.buttonLoading ? () => {} : props.onClick}
    >
      <If condition={props.buttonLoading}>
        <Then>
          <LoadingSpinner
            color={props.buttonType === "inline" ? colors.black : ""}
          />
        </Then>
        <Else>
          <div className="move-top">{props.children}</div>
        </Else>
      </If>
    </Style.Container>
  );
};

export default GenericButton;
