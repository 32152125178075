import * as Style from "./Modal.styles";
import * as Text from "styles/text";
import { ModalProps } from "./Modal.types";
import { colors } from "~/styles";
import { useState } from "react";
import CheckBox from "../Inputs/CheckBox";
import { GenericButton } from "../Buttons";
import { Else, If, Then, When } from "react-if";
import { useTranslation } from "react-i18next";

const Modal = (props: ModalProps) => {
  const [onCheck, setOnCheck] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Style.ModalBackground fadeIn={props.openModal}>
        <Style.ModalContainer className="modal-container">
          <Style.BodyContainer className="body-container">
            <Text.Heading5 color={colors.grayMedium}>
              {props.stepPosition}
            </Text.Heading5>
            <Text.Heading2Bold marginTop={10} textAlign="center">
              {props.title}
            </Text.Heading2Bold>
            <Text.Heading5
              marginTop={10}
              marginBottom={15}
              color={colors.grayMedium}
            >
              {props.subTitle}
            </Text.Heading5>

            <If condition={!!props.imageAndComponent}>
              <Then>
                <div onClick={() => props.onClickImg(true)}>
                  {props.imageAndComponent}
                </div>
              </Then>
              <Else>
                <Style.ImageContainer
                  isSelfie={props.type === "selfie"}
                  style={
                    !props.secondImage
                      ? { justifyContent: "center", height: "100%" }
                      : {}
                  }
                >
                  <div className="doc-image-container">
                    <img
                      className="doc-image"
                      onClick={() => props.onClickImg(true)}
                      src={props.image}
                      alt="img"
                    />
                  </div>
                  <When condition={props.secondImage}>
                    <div className="doc-image-example-alert">
                      <img
                        className="doc-image-example"
                        src={props.secondImage}
                        alt="img"
                      />
                      <div className="triangle-up-red" />
                      <div className="red-tip">
                        <Text.Small color={colors.white}>
                          {t("Wrong")}
                        </Text.Small>
                      </div>
                    </div>
                  </When>
                  <When condition={props.thirdImage}>
                    <div className="doc-image-example-alert">
                      <img
                        className="doc-image-example"
                        src={props.thirdImage}
                        alt="img"
                      />
                      <div className="triangle-up-green" />
                      <div className="green-tip">
                        <Text.Small color={colors.white}>
                          {t("Correct")}
                        </Text.Small>
                      </div>
                    </div>
                  </When>
                </Style.ImageContainer>
              </Else>
            </If>
          </Style.BodyContainer>
          <Style.FooterContainer>
            <CheckBox onCheck={setOnCheck} label={props.checkBoxLabel} />
            <div className="buttons-container">
              <GenericButton
                buttonType="inline"
                onClick={props.buttonLeftClick}
                children={<Text.Heading5>{props.buttonLeftText}</Text.Heading5>}
              />
              <GenericButton
                buttonType="secondary"
                onClick={props.buttonRigthClick}
                disabled={!onCheck}
                children={
                  <Text.Heading5 color={colors.white}>
                    {props.buttonRigthText}
                  </Text.Heading5>
                }
              />
            </div>
          </Style.FooterContainer>
        </Style.ModalContainer>
      </Style.ModalBackground>
    </>
  );
};

export default Modal;
