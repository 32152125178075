import * as Style from '../Kyc.styles';
import * as Text from 'styles/text';

import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { InputForm, SelectRadio } from 'components';
import {
  occupationList,
  occupationListEN,
  occupationListSP,
} from '~/data/occupations';

import { ReactComponent as CardIcon } from 'assets/images/icons/card.svg';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { StepProps } from '../Kyc.types';
import { formatStringToSearch } from 'helpers';
import { useFormContext } from 'react-hook-form';
import { useProgressBar } from '~/store';
import { useTranslation } from 'react-i18next';
import { userId } from '~/services/url';

export default function Occupation({ goToStep }: StepProps) {
  const { setValue, watch } = useFormContext();

  const [search, setSearch] = useState('');
  const [selectedOccupation, setSelectedOccupation] = useState(
    watch('occupation') || ''
  );

  const { setProgressPct, totalOfSteps } = useProgressBar();
  const { t, i18n } = useTranslation();

  const _occupationList = useMemo(() => {
    let list = [];
    switch (i18n.language) {
      case 'es-ES':
        list = occupationListSP;
        break;

      case 'en-US':
        list = occupationListEN;
        break;

      default:
        list = occupationList;
        break;
    }

    return list.filter((c) =>
      formatStringToSearch(c.occupation).startsWith(
        formatStringToSearch(search)
      )
    );
  }, [i18n.language, search]);

  const userUuid = userId()

  const goBack = () => {
    if (watch('isBrazilian')) {
      goToStep('formNationality');
    } else {
      goToStep('formCountry');
    }
  };

  useEffect(() => {
     // @ts-ignore
     gtag('event', 'screen_view', {
      'screen_name': 'ocupation_step',
      userId: userUuid
    });
    setProgressPct((4 / totalOfSteps) * 100);
  }, []);

  useEffect(() => {
    setValue('occupation', selectedOccupation);
  }, [selectedOccupation, setValue]);

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<CardIcon />} />

      <Text.Heading1Medium fontSize={1.938} marginTop={24}>
        {t('What is your occupation?')}
      </Text.Heading1Medium>

      <Text.Heading4 marginTop={24} marginBottom={24}>
        {t(
          'If you work in more than one job, select your main source of income.'
        )}
      </Text.Heading4>

      <InputForm
        searchIcon
        placeholder={t('Search occupation')}
        marginBottom={20}
        value={search}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />

      <Style.List>
        {_occupationList.map((c) => (
          <SelectRadio
            key={c.code}
            label={t(c.occupation)}
            checked={c.code === selectedOccupation}
            onChange={() => setSelectedOccupation(c.code)}
            name="occupation"
          />
        ))}
      </Style.List>

      <Footer
        onClickLeftButton={goBack}
        leftButtonContent={t('Back')}
        onClickGenericButton={() => goToStep('next')}
        disableGenericButton={!watch('occupation')}
        genericButtonType="secondary"
        genericButtonContent={t('Continue')}
      />
    </Style.StepContent>
  );
}
