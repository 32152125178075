import React from "react";
import ReactDOM from "react-dom/client";
import App from "./pages";
import GlobalStyle from "styles/global";
import "~/styles/fonts.css";
import "i18n";
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://09c7077f491e4cbac1bfa52292f4dd19@o236144.ingest.sentry.io/4505793815117824',
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
  integrations: [
    new Sentry.BrowserTracing({}),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'https://kyc.capitual.net', 'https://settlekyc.capstg.dev'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <GlobalStyle />
    <App />
  </>
);