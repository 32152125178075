import { transparentize } from "polished";
import styled, { css } from "styled-components";

import { colors } from "~/styles";
import { screen } from "~/styles/metrics";

interface FullImageContainerProps {
  isSelfie?: boolean;
}

export const FullImageContainer = styled.div<FullImageContainerProps>`
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: ${colors.white};
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    background-color: ${transparentize(0.85, colors.black)};
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
  }

  .button-control {
    border: none;
    background-color: ${transparentize(0.85, colors.black)};
    border-radius: 15px;
    padding: 15px;
    color: ${colors.black};
  }

  .doc-image {
    width: 100%;
    height: ${window.innerHeight * 0.8}px;
    object-fit: contain;

    ${({ isSelfie }) =>
      isSelfie &&
      css`
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      `}
  }
`;
