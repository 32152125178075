import styled from 'styled-components';
import { colors } from 'styles';
import { GeneralStyleProps } from '~/types/general.types';

export const ModalBackGround = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  position: absolute;
  top: 0;
  right: 0;

  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.35);
`;

export const ModalContainer = styled.div`
  width: 460px;
  border-radius: 12px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  animation: fadein 0.3s;
  position: relative;

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
`;

export const TitleCloseBtnContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  padding-bottom: 30px;
`;

export const CloseBtn = styled.button`
  border: none;
  cursor: pointer;
  margin-right: 20px;
  background-color: ${colors.white};
`;

export const BodyContainer = styled.div`
  padding: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

export const BottomContainer = styled.div<Pick<GeneralStyleProps, "justifyContent">>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  
  padding: 0 15px;
  height: 77px;
`;
