import * as Text from 'styles/text';

import { Else, If, Then } from 'react-if';
import React, { useEffect, useState } from 'react';

import CNHBacktAnim from '~/assets/anim/cnh-back.json';
import CNHFrontAnim from '~/assets/anim/cnh-front.json';
import { ReactComponent as CardIcon } from 'assets/images/icons/card.svg';
import { ReactComponent as DontFoldDoc } from 'assets/images/icons/dontFoldDoc.svg';
import { ReactComponent as FocusedDoc } from 'assets/images/icons/focusedDocument.svg';
import Footer from '~/components/Footer';
import { FrontAndBackProps } from '../CommonTypes';
import GenericDocBack from '~/assets/anim/generic-back.json';
import GenericDocFront from '~/assets/anim/generic-front.json';
import Header from '~/components/Header';
import { ReactComponent as LightIcon } from 'assets/images/icons/light.svg';
import Lottie from 'lottie-react-web';
import RgBacktAnim from '~/assets/anim/rg-back.json';
import RgFrontAnim from '~/assets/anim/rg-front.json';
import { ReactComponent as TurnPhoneHorizontal } from 'assets/images/icons/turnPhoneToHorizontal.svg';
import { useTranslation } from 'react-i18next';

export default function FrontAndBackDocs(props: FrontAndBackProps) {
  const { isRgMask, docPosition, goBack, nextStep, docType } = props;

  const { t } = useTranslation();

  const docTips = [
    {
      text: t('Be in a well-lit environment.'),
      icon: <LightIcon />,
    },
    {
      text: t('Use the cell phone in a horizontal position (lying down).'),
      icon: <TurnPhoneHorizontal />,
    },
  ];

  const docTips2 = [
    {
      text: t('Prevent the background of the photos from being stamped.'),
      icon: <FocusedDoc />,
    },
    {
      text: t('It is not necessary to unfold your document.'),
      icon: <DontFoldDoc />,
    },
  ];

  const renderTitle = () => {
    if (docPosition === 'front') {
      if (docType === 'rg') return `Front of rg`;
      if (docType) return `Front of`;
    } else {
      if (docType === 'rg') return `Back of rg`;
      if (docType) return `Back of`;
    }
  };

  const [isLoadingTerms, setIsLoadingTerms] = useState(
    docPosition === 'front' ? true : false
  );

  useEffect(() => {
    setTimeout(() => setIsLoadingTerms(false), 5000);
  }, []);

  return (
    <>
      <div className="content-wrapper-no-img">
        <Header label={t('ID Proof')} icon={<CardIcon />} />
        <div className="example-img-wrapper">
          <div className="example-img">
            {/* <- rg and another types exclude cnh */}
            <If condition={isRgMask}>
              <Then>
                {docPosition === 'front' ? (
                  docType !== 'rg' ? (
                    <Lottie
                      options={{
                        animationData: GenericDocFront,
                        autoplay: true,
                        loop: true,
                      }}
                    />
                  ) : (
                    <Lottie
                      options={{
                        animationData: RgFrontAnim,
                        autoplay: true,
                        loop: true,
                      }}
                    />
                  )
                ) : docType !== 'rg' ? (
                  <Lottie
                    options={{
                      animationData: GenericDocBack,
                      autoplay: true,
                      loop: true,
                    }}
                  />
                ) : (
                  <Lottie
                    options={{
                      animationData: RgBacktAnim,
                      autoplay: true,
                      loop: true,
                    }}
                  />
                )}
              </Then>
              <Else>
                {docPosition === 'front' ? (
                  <Lottie
                    options={{
                      animationData: CNHFrontAnim,
                      autoplay: true,
                      loop: true,
                    }}
                  />
                ) : (
                  <Lottie
                    options={{
                      animationData: CNHBacktAnim,
                      autoplay: true,
                      loop: true,
                    }}
                  />
                )}
              </Else>
            </If>
          </div>
        </div>
        <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={10}>
          {t(renderTitle())}
          {String(docType).toUpperCase()}
        </Text.Heading1Medium>
        <div className="list-item-wrapper">
          {docTips.map((p) => (
            <div key={p.text} className="list-item">
              <div className="list-item-icon">{p.icon}</div>
              <Text.Heading4 marginTop={10}>{p.text}</Text.Heading4>
            </div>
          ))}
        </div>
        <div className="list-item-wrapper">
          {docTips2.map((p) => (
            <div key={p.text} className="list-item">
              <div className="list-item-icon">{p.icon}</div>
              <Text.Heading4 marginTop={10}>{p.text}</Text.Heading4>
            </div>
          ))}
        </div>
      </div>
      <Footer
        loadingGenericButton={isLoadingTerms}
        onClickLeftButton={goBack}
        leftButtonContent={t('Back')}
        onClickGenericButton={nextStep}
        genericButtonType="secondary"
        genericButtonContent={t('Start Camera')}
      />
    </>
  );
}
