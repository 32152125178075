import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { InputPhoneProps } from './InputPhone.types';

export const Container = styled.div<InputPhoneProps>`
  width: ${(props) => props.width || ''};
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
  font-family: 'Helvetica Now Display Medium';
  font-weight: 500;
  font-size: 31px;

  .input-container {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const BottomContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7px;
`;

export const Input = styled.input<Pick<InputPhoneProps, 'error'>>`
  width: 100%;
  height: 70px;
  font-family: 'Helvetica Now Display Medium';
  font-weight: 500;
  font-size: 31px;
  background-color: ${colors.transparent};
  padding: 0 15px;
  border: none;
  border-bottom: 1px solid ${colors.grayRegular};
  border-radius: 0; // we need for mobile
  transition: 0.2s ease-out;
  box-sizing: border-box;

  ::placeholder {
    color: ${colors.grayRegular};
  }

  /* DDI CONTAINER */
  & + div {
    min-width: auto;
    display: flex;
    align-items: center;
    white-space: nowrap;

    background-color: ${colors.transparent};

    padding: 0 0 0 20px;
    border: none;
    border-bottom: 1px solid ${colors.grayRegular};
    color: ${colors.grayRegular};
    transition: 0.2s ease-out;
    user-select: none;
  }

  &:focus {
    border-color: ${colors.black} !important;
    border-width: 2px 2px 2px 0px !important;

    /* DDI CONTAINER */
    & + div {
      border-color: ${colors.black} !important;
      border-width: 2px 0px 2px 2px !important;
    }
  }

  ${(props) =>
    props.error
      ? css`
          border-color: ${colors.danger};

          & + div {
            border-bottom: solid 1px ${colors.danger} !important;
            border-width: 1px 0px 1px 1px !important;
          }

          &:focus {
            border-color: ${colors.danger} !important;

            /* DDI CONTAINER */
            & + div {
              border-color: ${colors.danger} !important;
            }
          }
        `
      : css`
          &:hover {
            border-color: ${colors.grayMedium};

            /* DDI CONTAINER */
            & + div {
              border-color: ${colors.grayMedium};
            }
          }
        `}
`;
