export type Steps =
  | 'start'
  | 'formName'
  | 'formCPF'
  | 'formNationality'
  | 'formCountry'
  | 'formOccupation'
  | 'formIncome'
  | 'formPoliticallyExposed'
  | 'formMotherName'
  | 'formBirthDate'
  | 'formAddress'
  | 'formPhone'
  | 'preparationDoc'
  | 'selectDoc'
  | 'waitSendDocsDefaultFlow'
  | 'selectDocDefaultFlow'
  | 'sendDocsDefaultFlow'
  | 'sendSelfieDefaultFlow'
  | 'finish';

// skip the camera part
export enum StepsWebEnum {
  // "start",
  // "formName",
  // "formCPF",
  // "formNationality",
  // "formCountry",
  // "formMotherName",
  // "formBirthDate",
  // "formAddress",
  // "formPhone",
  'qrcode',
  'waitSendDocs',
  'finish',
}
// skip wait send docs part
export enum StepsMobileEnum {
  'start',
  'formName',
  'formCPF',
  'formNationality',
  'formCountry',
  'formOccupation',
  'formIncome',
  'formPoliticallyExposed',
  'formMotherName',
  'formBirthDate',
  'formAddress',
  'formPhone',
  'preparationDoc',
  'selectDoc',
  'waitSendDocsDefaultFlow',
  'selectDocDefaultFlow',
  'sendDocsDefaultFlow',
  'sendSelfieDefaultFlow',
  'finish'
}

export interface FormFields {
  fullName: string;
  cpf: string;
  isBrazilian: boolean | undefined;
  occupation: string;
  politicallyExposed: string;
  declaredIncome: string;
  country: string;
  motherName: string;
  birthDate: string;
  cep: string;
  state: string;
  city: string;
  address: string;
  number: string;
  neighborhood: string;
  complement: string;
  phone: string;
}

export interface StepProps {
  goToStep: (direction: 'next' | 'prev' | Steps, args?: object) => void;
  onSubmit?: (data?: any) => void;
  activeStep?: boolean;
  pageArgs?: any;
  webcamRef?: any;
  goBack?: () => void;
  setShowNativeCam?: (opt: boolean) => void;
  showNativeCam?: boolean;
}
