import * as Style from '../Kyc.styles';
import * as Text from 'styles/text';

import React, { useEffect, useState } from 'react';


import ArrowDown from 'assets/images/icons/arrow-down-blue.svg';
import { ReactComponent as CardIcon } from 'assets/images/icons/card.svg';
import { CheckSelect } from '~/components';
import { ReactComponent as CnhIcon } from 'assets/images/icons/cnh.svg';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import { ReactComponent as RgIcon } from 'assets/images/icons/rg.svg';
import { StepProps } from '../Kyc.types';
import { When } from 'react-if';
import { colors } from '~/styles';
import { useProgressBar } from '~/store';
import { useTranslation } from 'react-i18next';
import { userId } from '~/services/url';

export default function SelectDocDefaultFlow({ goToStep }: StepProps) {
  const [selected, setSelected] = useState('');
  const [showMoreDocs, setShowMoreDocs] = useState(false);
  const { setProgressPct, totalOfSteps } = useProgressBar();

  const selectItem = (value: string) => {
    if (selected === value) return setSelected('');
    setSelected(value);
  };

  const { t } = useTranslation();

  const goNextStep = () => {
    if (selected !== '') {
      goToStep('next', { maskType: selected });
    }
  };

  const userUuid = userId()

  useEffect(() => {
     // @ts-ignore
     gtag('event', 'screen_view', {
      'screen_name': 'select_doc_native_step',
      userId: userUuid
    });
    setProgressPct((9 / totalOfSteps) * 100);
  }, []);

  const showMoreDocTypes = () => {
    setSelected('');
    setShowMoreDocs((prev) => !prev);
  };

  return (
    <>
      <Style.StepContent>
        <Header label="Personal Data" icon={<CardIcon />} />
        <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
          {t('Choose a document to photograph')}
        </Text.Heading1Medium>

        <CheckSelect
          icon={<RgIcon />}
          title={t('RG')}
          text={t('General Registration')}
          selected={selected === 'rg'}
          onClick={() => selectItem('rg')}
        />
        <CheckSelect
          icon={<CnhIcon />}
          title={t('CNH')}
          text={t('National Driving License')}
          selected={selected === 'cnh'}
          onClick={() => selectItem('cnh')}
        />

        {/* Others types */}

        <div className="container-more-docs">
          <Text.Heading5
            onClick={showMoreDocTypes}
            className="hover"
            color={colors.primary}
          >
            {t('More documents types')}
          </Text.Heading5>

          <div className={showMoreDocs ? 'arrow active' : 'arrow'}>
            <img src={ArrowDown} alt="" />
          </div>
        </div>

        <When condition={showMoreDocs}>
          <CheckSelect
            icon={<RgIcon />}
            title={t('RNE')}
            text={t('National Registry of Foreigners')}
            selected={selected === 'rne'}
            onClick={() => selectItem('rne')}
          />

          <CheckSelect
            icon={<RgIcon />}
            title={t('RNM')}
            text={t('National Migration Registry')}
            selected={selected === 'rnm'}
            onClick={() => selectItem('rnm')}
          />

          <CheckSelect
            icon={<RgIcon />}
            title={t('DNI')}
            text={t('National Identity Document')}
            selected={selected === 'dni'}
            onClick={() => selectItem('dni')}
          />
        </When>
        <Footer
          onClickLeftButton={() => goToStep('prev')}
          leftButtonContent={t('Back')}
          onClickGenericButton={goNextStep}
          genericButtonType="secondary"
          genericButtonContent={t('Continue')}
          disableGenericButton={selected === ''}
        />
      </Style.StepContent>
    </>
  );
}