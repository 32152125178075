import styled from 'styled-components';
import { colors, metrics } from 'styles';
import { screen } from 'styles/metrics';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 33%;

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 148px;
    height: 122px;
    border: 1px solid ${colors.grayLight};
    border-radius: 8px;
    margin-bottom: 15px;

    &.checked {
      background-color: ${colors.white};
      border: 2px solid ${colors.primary};
    }

    @media (max-width: 1310px) {
      width: 95%;
      padding: 30px;
    }

    @media ${screen.desktop} {
      width: 125px;
      height: 115px;
    }

    @media (max-width: 970px) {
      width: 100px;
      height: 105px;
    }
  }
`;