import { transparentize } from "polished";
import styled, { css } from "styled-components";

import { colors } from "~/styles";
import { screen } from "~/styles/metrics";

export const ModalBackground = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${transparentize(0.85, colors.black)};
  position: absolute;
  width: 100%;
  height: 100%;

  @media ${screen.desktop} {
    position: absolute;
    width: 100%;
  }

  ${(props) =>
    props.fadeIn
      ? css`
          opacity: 1;
          transition: opacity 0.2s ease-in;
        `
      : css`
          opacity: 0;
          transition: opacity 0.2s ease-out;
        `}
  .body-container::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: 70%;
  border-radius: 15px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9999999999;
  overflow: hidden;
  padding: 20px;
  justify-content: space-between;
`;

export const BodyContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const FooterContainer = styled.div`
  width:100% ;
  margin-top:15px;

  .buttons-container {
    display:flex ;
    flex-direction:row
    width:100% ;
    justify-content:space-between;
    gap:15px;
  }
`;

interface imageContainerProps {
  isSelfie?: boolean;
}

export const ImageContainer = styled.div<imageContainerProps>`
  width: 100%;
  height: 50%;
  display: flex;
  gap: 5%;
  margin-top: 15px;
  justify-content: center;
  align-items: center;

  .doc-image-container {
    width: 50%;
    max-height: 100%;
    border-radius: 8px;
  }

  .doc-image-example-alert {
    width: 22.5%;
    max-height: 100%;

    .triangle-up-red {
      width: 0;
      height: 0;
      margin-left: 45%;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid ${colors.danger};
    }

    .triangle-up-green {
      width: 0;
      height: 0;
      margin-left: 45%;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid ${colors.success};
    }

    .red-tip {
      width: 100%;
      height: 24px;
      background-color: ${colors.danger};
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 4px;
    }

    .green-tip {
      width: 100%;
      height: 24px;
      background-color: ${colors.success};
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 4px;
    }
  }

  .doc-image-example {
    width: 100%;

    border-radius: 8px;
    object-fit: contain;
    margin-bottom: 11px;
  }

  .doc-image {
    width: 100%;
    max-height: 100%;
    border-radius: 8px;
    object-fit: contain;

    ${({ isSelfie }) =>
      isSelfie &&
      css`
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      `}
  }
`;

export const FullImageContainer = styled.div`
  width: 100vw;
  height: 100%;
  z-index: 9999999999;
  background-color: ${colors.white};
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    background-color: ${transparentize(0.85, colors.black)};
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
  }

  .container-control-buttons {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    bottom: 10px;
    gap: 15px;
  }

  .button-control {
    border: none;
    background-color: ${transparentize(0.85, colors.black)};
    border-radius: 5px;
    padding: 15px;
  }

  .doc-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
