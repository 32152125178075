import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { InputFormProps } from './InputForm.types';

export const Container = styled.div<InputFormProps>`
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
  width: ${(props) => props.width};
`;

export const InputWrapper = styled.div`
  position: relative;
  .search-icon {
  background-color: ${colors.transparent};
  position: absolute;
  bottom: 10px;
  right: 10px;
}
`;

export const Input = styled.input<InputFormProps & { textAlign?: string }>`
  width: 100%;
  height: 47px; 
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${colors.grayRegular};
  padding: 16px;
  padding-right: ${(props) => props.searchIcon ? 30 : 16}px;
  background-color: ${colors.transparent};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  transition: border 0.3s ease-out;

  :focus {
    border-bottom: 2px solid ${colors.black} !important;
  }

  :hover {
    border-color: ${colors.grayMedium};
  }

  ::placeholder {
    color: ${colors.grayRegular};
    opacity: 1;
  }

  ${(props) => props.error &&
    css`
      border-color: ${colors.danger};

      &:focus {
        border-bottom: solid 2px ${colors.danger} !important;
      }

      &:hover {
        border-color: ${colors.danger};
      }
    `
  }
`;
