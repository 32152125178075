import * as Style from "./InputForm.styles";
import * as Text from "styles/text";
import { InputFormProps } from "./InputForm.types";
import { colors } from "styles";
import { When } from "react-if";
import { ChangeEvent, forwardRef } from "react";
import searchIcon from "assets/images/icons/search.svg";
import { useTranslation } from "react-i18next";

const InputForm = forwardRef((props: InputFormProps, _ref) => {
  const { t } = useTranslation();

  return (
    <Style.Container {...props}>
      <Style.InputWrapper>
        <Style.Input
          {...props}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (!props.maskFunction) {
              props.onChange && props.onChange(event);
            } else {
              const { value } = event.target;
              event.target.value = props.maskFunction(value);
              props.onChange && props.onChange(event);
            }
          }}
        />
        <When condition={props.searchIcon}>
          <div className="search-icon">
            <img src={searchIcon} alt="" />
          </div>
        </When>
      </Style.InputWrapper>
      <When condition={!!props.error}>
        <div>
          <Text.Small color={colors.danger} marginTop={5}>
            {t(props.error)}
          </Text.Small>
        </div>
      </When>
    </Style.Container>
  );
});

export default InputForm;
