import styled from "styled-components";
import { colors } from "styles";
import { ContainerCheckProps } from "./CheckBox.types";

export const Container = styled.div<ContainerCheckProps>`
  .container {
    align-items: center;
    justify-content: left;
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Helvetica Now Display Regular", sans-serif;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: ${colors.white};
    border: 2px solid ${colors.black};
    border-radius: 6px;
  }

  .container:hover input ~ .checkmark {
    background-color: ${colors.white};
    border: 2px solid ${colors.black};
    border-radius: 6px;
  }

  .container input:checked ~ .checkmark {
    background-color: ${colors.primary};
    border-radius: 6px;
    border: none;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
