import styled from "styled-components";
import { colors, metrics } from "styles";

export const Container = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  min-height: 74px;
  height: 100%;
  background: ${colors.white};
  z-index: 10;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 85px;
  z-index: 9999999999;

  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 74px;
  }

  .leftButton {
    width: 107px;
    border: none;
    background: none;
    text-align: center;

    @media (max-aspect-ratio: 1/1) {
      flex-direction: row;
      text-align: left;
    }
  }

  .label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 107px;

    @media (max-aspect-ratio: 1/1) {
      flex-direction: row;
    }

    p {
      margin-left: 9.78px;
    }
  }
`;
