import styled from 'styled-components';
import { colors } from 'styles';
import { LoadingSpinnerProps } from './LoadingSpinner.types';

export const Container = styled.div<LoadingSpinnerProps>`
  --color: ${(props) => props.color || colors.white};
  --size: ${(props) => props.size || 24}px;
  --stroke-size: ${(props) => props.strokeSize || 9.8}px;
  --velocity: ${(props) => props.velocity || 1.2}s;

  width: var(--size);
  height: var(--size);
  border-radius: 50%;

  background-image: conic-gradient(transparent, var(--color));

  -webkit-mask: radial-gradient(
    circle var(--stroke-size),
    transparent 98%,
    var(--color) 100%
  );
  mask: radial-gradient(
    circle var(--stroke-size),
    transparent 98%,
    var(--color) 100%
  );

  animation: rotate var(--velocity) linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }
`;
