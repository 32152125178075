export const countriesList = [
  {
    nationality: 'Afegão',
    label: 'Afeganistão',
    value: 'Afeganistão',
    initials: 'AF',
    code: '+93',
  },
  {
    nationality: 'Sul-africana',
    label: 'África do Sul',
    value: 'África do Sul',
    initials: 'ZA',
    code: '+27',
  },
  {
    nationality: 'Albanesa',
    label: 'Albânia',
    value: 'Albânia',
    initials: 'AL',
    code: '+355',
  },
  {
    nationality: 'Alemã',
    label: 'Alemanha',
    value: 'Alemanha',
    initials: 'DE',
    code: '+49',
  },
  {
    nationality: 'Andorrana',
    label: 'Andorra',
    value: 'Andorra',
    initials: 'AD',
    code: '+376',
  },
  {
    nationality: 'Angolana',
    label: 'Angola',
    value: 'Angola',
    initials: 'AO',
    code: '+244',
  },
  {
    nationality: 'Anguillana',
    label: 'Anguilla',
    value: 'Anguilla',
    initials: 'AI',
    code: '+1 264',
  },
  {
    nationality: 'Antártico',
    label: 'Antártida',
    value: 'Antártida',
    initials: 'AQ',
    code: '+672',
  },
  {
    nationality: 'Antiguana',
    label: 'Antígua e Barbuda',
    value: 'Antigua & Barbuda',
    initials: 'AG',
    code: '+1 268',
  },
  {
    nationality: 'Saudita',
    label: 'Arábia Saudita',
    value: 'Arábia Saudita',
    initials: 'SA',
    code: '+966',
  },
  {
    nationality: 'Argelina',
    label: 'Argélia',
    value: 'Argélia',
    initials: 'DZ',
    code: '+213',
  },
  {
    nationality: 'Argentina',
    label: 'Argentina',
    value: 'Argentina',
    initials: 'AR',
    code: '+54',
  },
  {
    nationality: 'Armênia',
    label: 'Armênia',
    value: 'Armênia',
    initials: 'AM',
    code: '+374',
  },
  {
    nationality: 'Arubana',
    label: 'Aruba',
    value: 'Aruba',
    initials: 'AW',
    code: '+297',
  },
  {
    nationality: 'Australiana',
    label: 'Austrália',
    value: 'Austrália',
    initials: 'AU',
    code: '+61',
  },
  {
    nationality: 'Austríaca',
    label: 'Áustria',
    value: 'Áustria',
    initials: 'AT',
    code: '+43',
  },
  {
    nationality: 'Azerbaijano',
    label: 'Azerbaijão',
    value: 'Azerbaijão',
    initials: 'AZ',
    code: '+994',
  },
  {
    nationality: 'Bahamense',
    label: 'Bahamas',
    value: 'Bahamas',
    initials: 'BS',
    code: '+1 242 ',
  },
  {
    nationality: 'Barenita',
    label: 'Bahrein',
    value: 'Bahrein',
    initials: 'BH',
    code: '+973',
  },
  {
    nationality: 'Bengali',
    label: 'Bangladesh',
    value: 'Bangladesh',
    initials: 'BD',
    code: '+880',
  },
  {
    nationality: 'Barbadiana',
    label: 'Barbados',
    value: 'Barbados',
    initials: 'BB',
    code: '+1 246 ',
  },
  {
    nationality: 'Bielo-russa',
    label: 'Belarus',
    value: 'Belarus',
    initials: 'BY',
    code: '+375',
  },
  {
    nationality: 'Belga',
    label: 'Bélgica',
    value: 'Bélgica',
    initials: 'BE',
    code: '+32',
  },
  {
    nationality: 'Belizenha',
    label: 'Belize',
    value: 'Belize',
    initials: 'BZ',
    code: '+501',
  },
  {
    nationality: 'Beninense',
    label: 'Benin',
    value: 'Benin',
    initials: 'BJ',
    code: '+229',
  },
  {
    nationality: 'Bermudiana',
    label: 'Bermudas',
    value: 'Bermudas',
    initials: 'BM',
    code: '+1 441',
  },
  {
    nationality: 'Boliviana',
    label: 'Bolívia',
    value: 'Bolívia',
    initials: 'BO',
    code: '+591',
  },
  {
    nationality: 'Bósnia',
    label: 'Bósnia-Herzegóvina',
    value: 'Bósnia-Herzegóvina',
    initials: 'BA',
    code: '+387',
  },
  {
    nationality: 'Betchuana',
    label: 'Botsuana',
    value: 'Botsuana',
    initials: 'BW',
    code: '+267',
  },
  {
    nationality: 'Brasileira',
    label: 'Brasil',
    value: 'Brasil',
    initials: 'BR',
    code: '+55',
  },
  {
    nationality: 'Bruneiana',
    label: 'Brunei',
    value: 'Brunei',
    initials: 'BN',
    code: '+673',
  },
  {
    nationality: 'Búlgara',
    label: 'Bulgária',
    value: 'Bulgária',
    initials: 'BG',
    code: '+359',
  },
  {
    nationality: 'Burquinês',
    label: 'Burkina Fasso',
    value: 'Burkina Fasso',
    initials: 'BF',
    code: '+226',
  },
  {
    nationality: 'Burundinesa',
    label: 'Burundi',
    value: 'Burundi',
    initials: 'BI',
    code: '+257',
  },
  {
    nationality: 'Butanesa',
    label: 'Butão',
    value: 'Butão',
    initials: 'BT',
    code: '+975',
  },
  {
    nationality: 'Cabo-verdiana',
    label: 'Cabo Verde',
    value: 'Cabo Verde',
    initials: 'CV',
    code: '+238',
  },
  {
    nationality: 'Camaronesa',
    label: 'Camarões',
    value: 'Camarões',
    initials: 'CM',
    code: '+237',
  },
  {
    nationality: 'Cambojana',
    label: 'Camboja',
    value: 'Camboja',
    initials: 'KH',
    code: '+855',
  },
  {
    nationality: 'Canadense',
    label: 'Canadá',
    value: 'Canadá',
    initials: 'CA',
    code: '+1',
  },
  {
    nationality: 'Cazaque',
    label: 'Cazaquistão',
    value: 'Cazaquistão',
    initials: 'KZ',
    code: '+7',
  },
  {
    nationality: 'Chadiana',
    label: 'Chade',
    value: 'Chade',
    initials: 'TD',
    code: '+235',
  },
  {
    nationality: 'Chilena',
    label: 'Chile',
    value: 'Chile',
    initials: 'CL',
    code: '+56',
  },
  {
    nationality: 'Chinesa',
    label: 'China',
    value: 'China',
    initials: 'CN',
    code: '+86',
  },
  {
    nationality: 'Cipriota',
    label: 'Chipre',
    value: 'Chipre',
    initials: 'CY',
    code: '+357',
  },
  {
    nationality: 'Cingapuriana',
    label: 'Cingapura',
    value: 'Cingapura',
    initials: 'SG',
    code: '+65',
  },
  {
    nationality: 'Colombiana',
    label: 'Colômbia',
    value: 'Colômbia',
    initials: 'CO',
    code: '+57',
  },
  {
    nationality: 'Comorense',
    label: 'Comores',
    value: 'Comores',
    initials: 'KM',
    code: '+269',
  },
  {
    nationality: 'Congolesa',
    label: 'República do Congo',
    value: 'República do Congo',
    initials: 'CG',
    code: '+242',
  },
  {
    nationality: 'Norte-coreano',
    label: 'Coréia do Norte',
    value: 'Coréia do Norte',
    initials: 'KP',
    code: '+850',
  },
  {
    nationality: 'Sul-coreano',
    label: 'Coréia do Sul',
    value: 'Coréia do Sul',
    initials: 'KR',
    code: '+82',
  },
  {
    nationality: 'Marfinense',
    label: 'Costa do Marfim',
    value: 'Costa do Marfim',
    initials: 'CI',
    code: '+225',
  },
  {
    nationality: 'Costarriquenha',
    label: 'Costa Rica',
    value: 'Costa Rica',
    initials: 'CR',
    code: '+506',
  },
  {
    nationality: 'Croata',
    label: 'Croácia',
    value: 'Croácia',
    initials: 'HR',
    code: '+385',
  },
  {
    nationality: 'Cubana',
    label: 'Cuba',
    value: 'Cuba',
    initials: 'CU',
    code: '+53',
  },
  {
    nationality: 'Dinamarquesa',
    label: 'Dinamarca',
    value: 'Dinamarca',
    initials: 'DK',
    code: '+45',
  },
  {
    nationality: 'Djibutiana',
    label: 'Djibuti',
    value: 'Djibuti',
    initials: 'DJ',
    code: '+253',
  },
  {
    nationality: 'Dominiquense',
    label: 'Dominica',
    value: 'Dominica',
    initials: 'DM',
    code: '+1 767',
  },
  {
    nationality: 'Egípcia',
    label: 'Egito',
    value: 'Egito',
    initials: 'EG',
    code: '+20',
  },
  {
    nationality: 'Salvadorenha',
    label: 'El Salvador',
    value: 'El Salvador',
    initials: 'SV',
    code: '+503',
  },
  {
    nationality: 'Árabe',
    label: 'Emirados Árabes Unidos',
    value: 'Emirados Árabes Unidos',
    initials: 'AE',
    code: '+971',
  },
  {
    nationality: 'Equatoriana',
    label: 'Equador',
    value: 'Equador',
    initials: 'EC',
    code: '+593',
  },
  {
    nationality: 'Eritreia',
    label: 'Eritréia',
    value: 'Eritréia',
    initials: 'ER',
    code: '+291',
  },
  {
    nationality: 'Eslovaco',
    label: 'Eslováquia',
    value: 'Eslováquia',
    initials: 'SK',
    code: '+421',
  },
  {
    nationality: 'Esloveno',
    label: 'Eslovênia',
    value: 'Eslovênia',
    initials: 'SI',
    code: '+386',
  },
  {
    nationality: 'Espanhola',
    label: 'Espanha',
    value: 'Espanha',
    initials: 'ES',
    code: '+34',
  },
  {
    nationality: 'Norte-americana',
    label: 'Estados Unidos',
    value: 'Estados Unidos',
    initials: 'US',
    code: '+1',
  },
  {
    nationality: 'Estoniana',
    label: 'Estônia',
    value: 'Estônia',
    initials: 'EE',
    code: '+372',
  },
  {
    nationality: 'Etíope',
    label: 'Etiópia',
    value: 'Etiópia',
    initials: 'ET',
    code: '+251',
  },
  {
    nationality: 'Fijiana',
    label: 'Fiji',
    value: 'Fiji',
    initials: 'FJ',
    code: '+679',
  },
  {
    nationality: 'Filipina',
    label: 'Filipinas',
    value: 'Filipinas',
    initials: 'PH',
    code: '+63',
  },
  {
    nationality: 'Finlandesa',
    label: 'Finlândia',
    value: 'Finlândia',
    initials: 'FI',
    code: '+358',
  },
  {
    nationality: 'Francesa',
    label: 'França',
    value: 'França',
    initials: 'FR',
    code: '+33',
  },
  {
    nationality: 'Gabonesa',
    label: 'Gabão',
    value: 'Gabão',
    initials: 'GA',
    code: '+241',
  },
  {
    nationality: 'Gambiana',
    label: 'Gâmbia',
    value: 'Gâmbia',
    initials: 'GM',
    code: '+220',
  },
  {
    nationality: 'Ganense',
    label: 'Gana',
    value: 'Gana',
    initials: 'GH',
    code: '+233',
  },
  {
    nationality: 'Georgiana',
    label: 'Geórgia',
    value: 'Geórgia',
    initials: 'GE',
    code: '+995',
  },
  {
    nationality: 'Gibraltariana',
    label: 'Gibraltar',
    value: 'Gibraltar',
    initials: 'GI',
    code: '+350',
  },
  {
    nationality: 'Inglesa',
    label: 'Grã-Bretanha (Reino Unido, UK)',
    value: 'Grã-Bretanha (Reino Unido, UK)',
    initials: 'GB',
    code: '+44',
  },
  {
    nationality: 'Granadina',
    label: 'Granada',
    value: 'Granada',
    initials: 'GD',
    code: '+1 473',
  },
  {
    nationality: 'Grega',
    label: 'Grécia',
    value: 'Grécia',
    initials: 'GR',
    code: '+30',
  },
  {
    nationality: 'Groenlandesa',
    label: 'Groelândia',
    value: 'Groelândia',
    initials: 'GL',
    code: '+299',
  },
  {
    nationality: 'Guadalupense',
    label: 'Guadalupe',
    value: 'Guadalupe',
    initials: 'GP',
    code: '+590',
  },
  {
    nationality: 'Guamês',
    label: 'Guam (Território dos Estados Unidos)',
    value: 'Guam (Território dos Estados Unidos)',
    initials: 'GU',
    code: '+1 671 ',
  },
  {
    nationality: 'Guatemalteca',
    label: 'Guatemala',
    value: 'Guatemala',
    initials: 'GT',
    code: '+502',
  },
  {
    nationality: 'Guianense',
    label: 'Guiana',
    value: 'Guiana',
    initials: 'GY',
    code: '+592',
  },
  {
    nationality: 'Franco-guianense',
    label: 'Guiana Francesa',
    value: 'Guiana Francesa',
    initials: 'GF',
    code: '+594',
  },
  {
    nationality: 'Equatoguineana',
    label: 'Guiné',
    value: 'Guiné',
    initials: 'GN',
    code: '+224',
  },
  {
    nationality: 'Guinéu-equatoriano',
    label: 'Guiné Equatorial',
    value: 'Guiné Equatorial',
    initials: 'GQ',
    code: '+240',
  },
  {
    nationality: 'Guineense',
    label: 'Guiné-Bissau',
    value: 'Guiné-Bissau',
    initials: 'GW',
    code: '+245',
  },
  {
    nationality: 'Haitiana',
    label: 'Haiti',
    value: 'Haiti',
    initials: 'HT',
    code: '+509',
  },
  {
    nationality: 'Holandês',
    label: 'Holanda',
    value: 'Holanda',
    initials: 'NL',
    code: '+31',
  },
  {
    nationality: 'Hondurenha',
    label: 'Honduras',
    value: 'Honduras',
    initials: 'HN',
    code: '+504',
  },
  {
    nationality: 'Honconguês',
    label: 'Hong Kong',
    value: 'Hong Kong',
    initials: 'HK',
    code: '+852',
  },
  {
    nationality: 'Húngara',
    label: 'Hungria',
    value: 'Hungria',
    initials: 'HU',
    code: '+36',
  },
  {
    nationality: 'Iemenita',
    label: 'Iêmen',
    value: 'Iêmen',
    initials: 'YE',
    code: '+967',
  },
  {
    nationality: 'Da ilha de ascensão',
    label: 'Ilha de Ascensão',
    value: 'Ilha de Ascensão',
    initials: 'AC',
    code: '+247',
  },
  {
    nationality: 'Manês',
    label: 'Ilha de Man',
    value: 'Ilha de Man',
    initials: 'IM',
    code: '+44',
  },
  {
    nationality: 'Natalense',
    label: 'Ilha Natal',
    value: 'Ilha Natal',
    initials: 'CX',
    code: '+61',
  },
  {
    nationality: 'Pitcairnense',
    label: 'Ilha Pitcairn',
    value: 'Ilha Pitcairn',
    initials: 'PN',
    code: '+870',
  },
  {
    nationality: 'Caimanês',
    label: 'Ilhas Cayman',
    value: 'Ilhas Cayman',
    initials: 'KY',
    code: '+1 345 ',
  },
  {
    nationality: 'Coquense',
    label: 'Ilhas Cocos',
    value: 'Ilhas Cocos',
    initials: 'CC',
    code: '+61',
  },
  {
    nationality: 'Cookense',
    label: 'Ilhas Cook',
    value: 'Ilhas Cook',
    initials: 'CK',
    code: '+682',
  },
  {
    nationality: 'Faroense',
    label: 'Ilhas Faroes',
    value: 'Ilhas Faroes',
    initials: 'FO',
    code: '+298',
  },
  {
    nationality: 'Das ilhas geórgia do sul e sandwich do sul',
    label: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    value: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    initials: 'GS',
    code: '+500',
  },
  {
    nationality: 'Malvinense',
    label: 'Ilhas Malvinas',
    value: 'Ilhas Malvinas',
    initials: 'FK',
    code: '+500',
  },
  {
    nationality: 'Norte-marianense',
    label: 'Ilhas Marianas do Norte',
    value: 'Ilhas Marianas do Norte',
    initials: 'MP',
    code: '+1 670',
  },
  {
    nationality: 'Marshallino',
    label: 'Ilhas Marshall',
    value: 'Ilhas Marshall',
    initials: 'MH',
    code: '+692',
  },
  {
    nationality: 'Norfolquino',
    label: 'Ilhas Norfolk',
    value: 'Ilhas Norfolk',
    initials: 'NF',
    code: '+672',
  },
  {
    nationality: 'Salomônico',
    label: 'Ilhas Salomão',
    value: 'Ilhas Salomão',
    initials: 'SB',
    code: '+677',
  },
  {
    nationality: 'Seichelense',
    label: 'Ilhas Seychelles',
    value: 'Ilhas Seychelles',
    initials: 'SC',
    code: '+248',
  },
  {
    nationality: 'Toquelauano',
    label: 'Ilhas Tokelau',
    value: 'Ilhas Tokelau',
    initials: 'TK',
    code: '+690',
  },
  {
    nationality: 'Turquês',
    label: 'Ilhas Turks e Caicos',
    value: 'Ilhas Turks e Caicos',
    initials: 'TC',
    code: '+1 649 ',
  },
  {
    nationality: 'Virginense',
    label: 'Ilhas Virgens (Estados Unidos)',
    value: 'Ilhas Virgens (Estados Unidos)',
    initials: 'VI',
    code: '+1 340 ',
  },
  {
    nationality: 'Virginense',
    label: 'Ilhas Virgens (Inglaterra)',
    value: 'Ilhas Virgens (Inglaterra)',
    initials: 'VG',
    code: '+1 284 ',
  },
  {
    nationality: 'Indiano',
    label: 'Índia',
    value: 'Índia',
    initials: 'IN',
    code: '+91',
  },
  {
    nationality: 'Indonésia',
    label: 'Indonésia',
    value: 'Indonésia',
    initials: 'ID',
    code: '+62',
  },
  {
    nationality: 'Iraniano',
    label: 'Irã',
    value: 'Irã',
    initials: 'IR',
    code: '+98',
  },
  {
    nationality: 'Iraquiana',
    label: 'Iraque',
    value: 'Iraque',
    initials: 'IQ',
    code: '+964',
  },
  {
    nationality: 'Irlandesa',
    label: 'Irlanda',
    value: 'Irlanda',
    initials: 'IE',
    code: '+353',
  },
  {
    nationality: 'Islandesa',
    label: 'Islândia',
    value: 'Islândia',
    initials: 'IS',
    code: '+354',
  },
  {
    nationality: 'Israelense',
    label: 'Israel',
    value: 'Israel',
    initials: 'IL',
    code: '+972',
  },
  {
    nationality: 'Italiana',
    label: 'Itália',
    value: 'Itália',
    initials: 'IT',
    code: '+39',
  },
  {
    nationality: 'Jamaicana',
    label: 'Jamaica',
    value: 'Jamaica',
    initials: 'JM',
    code: '+1 876',
  },
  {
    nationality: 'Japonesa',
    label: 'Japão',
    value: 'Japão',
    initials: 'JP',
    code: '+81',
  },
  {
    nationality: 'Jordaniana',
    label: 'Jordânia',
    value: 'Jordânia',
    initials: 'JO',
    code: '+962',
  },
  {
    nationality: 'Kiribatiana',
    label: 'Kiribati',
    value: 'Kiribati',
    initials: 'KI',
    code: '+686',
  },
  {
    nationality: 'Kosovar',
    label: 'Kosovo',
    value: 'Kosovo',
    initials: 'XK',
    code: '+381',
  },
  {
    nationality: 'Kuwaitiano',
    label: 'Kuait',
    value: 'Kuait',
    initials: 'KW',
    code: '+965',
  },
  {
    nationality: 'Laosiana',
    label: 'Laos',
    value: 'Laos',
    initials: 'LA',
    code: '+856',
  },
  {
    nationality: 'Lesota',
    label: 'Lesoto',
    value: 'Lesoto',
    initials: 'LS',
    code: '+266',
  },
  {
    nationality: 'Letão',
    label: 'Letônia',
    value: 'Letônia',
    initials: 'LV',
    code: '+371',
  },
  {
    nationality: 'Libanesa',
    label: 'Líbano',
    value: 'Líbano',
    initials: 'LB',
    code: '+961',
  },
  {
    nationality: 'Liberiana',
    label: 'Libéria',
    value: 'Libéria',
    initials: 'LR',
    code: '+231',
  },
  {
    nationality: 'Líbia',
    label: 'Líbia',
    value: 'Líbia',
    initials: 'LY',
    code: '+218',
  },
  {
    nationality: 'Liechtensteiniense',
    label: 'Liechtenstein',
    value: 'Liechtenstein',
    initials: 'LI',
    code: '+423',
  },
  {
    nationality: 'Lituana',
    label: 'Lituânia',
    value: 'Lituânia',
    initials: 'LT',
    code: '+370',
  },
  {
    nationality: 'Luxemburguesa',
    label: 'Luxemburgo',
    value: 'Luxemburgo',
    initials: 'LU',
    code: '+352',
  },
  {
    nationality: 'Macauense',
    label: 'Macau',
    value: 'Macau',
    initials: 'MO',
    code: '+853',
  },
  {
    nationality: 'Macedônio',
    label: 'Macedônia (República Yugoslava)',
    value: 'Macedônia (República Yugoslava)',
    initials: 'MK',
    code: '+389',
  },
  {
    nationality: 'Malgaxe',
    label: 'Madagascar',
    value: 'Madagascar',
    initials: 'MG',
    code: '+261',
  },
  {
    nationality: 'Malaia',
    label: 'Malásia',
    value: 'Malásia',
    initials: 'MY',
    code: '+60',
  },
  {
    nationality: 'Malauiano',
    label: 'Malaui',
    value: 'Malaui',
    initials: 'MW',
    code: '+265',
  },
  {
    nationality: 'Maldívia',
    label: 'Maldivas',
    value: 'Maldivas',
    initials: 'MV',
    code: '+960',
  },
  {
    nationality: 'Malinesa',
    label: 'Mali',
    value: 'Mali',
    initials: 'ML',
    code: '+223',
  },
  {
    nationality: 'Maltesa',
    label: 'Malta',
    value: 'Malta',
    initials: 'MT',
    code: '+356',
  },
  {
    nationality: 'Marroquina',
    label: 'Marrocos',
    value: 'Marrocos',
    initials: 'MA',
    code: '+212',
  },
  {
    nationality: 'Martiniquense',
    label: 'Martinica',
    value: 'Martinica',
    initials: 'MQ',
    code: '+596',
  },
  {
    nationality: 'Mauriciana',
    label: 'Maurício',
    value: 'Maurício',
    initials: 'MU',
    code: '+230',
  },
  {
    nationality: 'Mauritana',
    label: 'Mauritânia',
    value: 'Mauritânia',
    initials: 'MR',
    code: '+222',
  },
  {
    nationality: 'Maiotense',
    label: 'Mayotte',
    value: 'Mayotte',
    initials: 'YT',
    code: '+262',
  },
  {
    nationality: 'Mexicana',
    label: 'México',
    value: 'México',
    initials: 'MX',
    code: '+52',
  },
  {
    nationality: 'Micronésia',
    label: 'Micronésia',
    value: 'Micronésia',
    initials: 'FM',
    code: '+691',
  },
  {
    nationality: 'Moçambicana',
    label: 'Moçambique',
    value: 'Moçambique',
    initials: 'MZ',
    code: '+258',
  },
  {
    nationality: 'Moldavo',
    label: 'Moldova',
    value: 'Moldova',
    initials: 'MD',
    code: '+373',
  },
  {
    nationality: 'Monegasca',
    label: 'Mônaco',
    value: 'Mônaco',
    initials: 'MC',
    code: '+377',
  },
  {
    nationality: 'Mongol',
    label: 'Mongólia',
    value: 'Mongólia',
    initials: 'MN',
    code: '+976',
  },
  {
    nationality: 'Montenegra',
    label: 'Montenegro',
    value: 'Montenegro',
    initials: 'ME',
    code: '+382',
  },
  {
    nationality: 'Montserratiano',
    label: 'Montserrat',
    value: 'Montserrat',
    initials: 'MS',
    code: '+1 664',
  },
  {
    nationality: 'Birmanês',
    label: 'Myanma',
    value: 'Myanma',
    initials: 'MM',
    code: '+95',
  },
  {
    nationality: 'Namíbia',
    label: 'Namíbia',
    value: 'Namíbia',
    initials: 'NA',
    code: '+264',
  },
  {
    nationality: 'Nauruana',
    label: 'Nauru',
    value: 'Nauru',
    initials: 'NR',
    code: '+674',
  },
  {
    nationality: 'Nepalesa',
    label: 'Nepal',
    value: 'Nepal',
    initials: 'NP',
    code: '+977',
  },
  {
    nationality: 'Nicaraguense',
    label: 'Nicarágua',
    value: 'Nicarágua',
    initials: 'NI',
    code: '+505',
  },
  {
    nationality: 'Nigerina',
    label: 'Níger',
    value: 'Níger',
    initials: 'NE',
    code: '+227',
  },
  {
    nationality: 'Nigeriana',
    label: 'Nigéria',
    value: 'Nigéria',
    initials: 'NG',
    code: '+234',
  },
  {
    nationality: 'Niueana',
    label: 'Niue',
    value: 'Niue',
    initials: 'NU',
    code: '+683',
  },
  {
    nationality: 'Norueguesa',
    label: 'Noruega',
    value: 'Noruega',
    initials: 'NO',
    code: '+47',
  },
  {
    nationality: 'Caledônia',
    label: 'Nova Caledônia',
    value: 'Nova Caledônia',
    initials: 'NC',
    code: '+687',
  },
  {
    nationality: 'Neozelandesa',
    label: 'Nova Zelândia',
    value: 'Nova Zelândia',
    initials: 'NZ',
    code: '+64',
  },
  {
    nationality: 'Omanense',
    label: 'Omã',
    value: 'Omã',
    initials: 'OM',
    code: '+968',
  },
  {
    nationality: 'Palauano',
    label: 'Palau',
    value: 'Palau',
    initials: 'PW',
    code: '+680',
  },
  {
    nationality: 'Panamenho',
    label: 'Panamá',
    value: 'Panamá',
    initials: 'PA',
    code: '+507',
  },
  {
    nationality: 'Pauásia',
    label: 'Papua-Nova Guiné',
    value: 'Papua-Nova Guiné',
    initials: 'PG',
    code: '+675',
  },
  {
    nationality: 'Paquistanesa',
    label: 'Paquistão',
    value: 'Paquistão',
    initials: 'PK',
    code: '+92',
  },
  {
    nationality: 'Paraguaia',
    label: 'Paraguai',
    value: 'Paraguai',
    initials: 'PY',
    code: '+595',
  },
  {
    nationality: 'Peruana',
    label: 'Peru',
    value: 'Peru',
    initials: 'PE',
    code: '+51',
  },
  {
    nationality: 'Franco-polinésia',
    label: 'Polinésia Francesa',
    value: 'Polinésia Francesa',
    initials: 'PF',
    code: '+689',
  },
  {
    nationality: 'Polonesa',
    label: 'Polônia',
    value: 'Polônia',
    initials: 'PL',
    code: '+48',
  },
  {
    nationality: 'Portorriquenha',
    label: 'Porto Rico',
    value: 'Porto Rico',
    initials: 'PR',
    code: '+1',
  },
  {
    nationality: 'Portuguesa',
    label: 'Portugal',
    value: 'Portugal',
    initials: 'PT',
    code: '+351',
  },
  {
    nationality: 'Catarense',
    label: 'Qatar',
    value: 'Qatar',
    initials: 'QA',
    code: '+974',
  },
  {
    nationality: 'Queniano',
    label: 'Quênia',
    value: 'Quênia',
    initials: 'KE',
    code: '+254',
  },
  {
    nationality: 'Quirguiz',
    label: 'Quirguistão',
    value: 'Quirguistão',
    initials: 'KG',
    code: '+996',
  },
  {
    nationality: 'Centro-africana',
    label: 'República Centro-Africana',
    value: 'República Centro-Africana',
    initials: 'CF',
    code: '+236',
  },
  {
    nationality: 'Congolesa',
    label: 'República Democrática do Congo',
    value: 'República Democrática do Congo',
    initials: 'CD',
    code: '+243',
  },
  {
    nationality: 'Dominicana',
    label: 'República Dominicana',
    value: 'República Dominicana',
    initials: 'DO',
    code: '+1 809',
  },
  {
    nationality: 'Tcheco',
    label: 'República Tcheca',
    value: 'República Tcheca',
    initials: 'CZ',
    code: '+420',
  },
  {
    nationality: 'Romena',
    label: 'Romênia',
    value: 'Romênia',
    initials: 'RO',
    code: '+40',
  },
  {
    nationality: 'Ruandesa',
    label: 'Ruanda',
    value: 'Ruanda',
    initials: 'RW',
    code: '+250',
  },
  {
    nationality: 'Russa',
    label: 'Rússia (antiga URSS) - Federação Russa',
    value: 'Rússia (antiga URSS) - Federação Russa',
    initials: 'RU',
    code: '+7',
  },
  {
    nationality: 'Pedro-miquelonense',
    label: 'Saint-Pierre e Miquelon',
    value: 'Saint-Pierre e Miquelon',
    initials: 'PM',
    code: '+508',
  },
  {
    nationality: 'Samoana',
    label: 'Samoa Americana',
    value: 'Samoa Americana',
    initials: 'AS',
    code: '+1 684',
  },
  {
    nationality: 'Samoano',
    label: 'Samoa Ocidental',
    value: 'Samoa Ocidental',
    initials: 'WS',
    code: '+685',
  },
  {
    nationality: 'Samarinês',
    label: 'San Marino',
    value: 'San Marino',
    initials: 'SM',
    code: '+378',
  },
  {
    nationality: 'Helenense',
    label: 'Santa Helena',
    value: 'Santa Helena',
    initials: 'SH',
    code: '+290',
  },
  {
    nationality: 'Santa-lucense',
    label: 'Santa Lúcia',
    value: 'Santa Lúcia',
    initials: 'LC',
    code: '+1 758',
  },
  {
    nationality: 'São-bartolomeense',
    label: 'São Bartolomeu',
    value: 'São Bartolomeu',
    initials: 'BL',
    code: '+590',
  },
  {
    nationality: 'São-cristovense',
    label: 'São Cristóvão e Névis',
    value: 'São Cristóvão e Névis',
    initials: 'KN',
    code: '+1 869',
  },
  {
    nationality: 'São-martinhense',
    label: 'São Martim',
    value: 'São Martim',
    initials: 'MF',
    code: '+1 599',
  },
  {
    nationality: 'São-tomense',
    label: 'São Tomé e Príncipe',
    value: 'São Tomé e Príncipe',
    initials: 'ST',
    code: '+239',
  },
  {
    nationality: 'Sao-vicentino',
    label: 'São Vicente e Granadinas',
    value: 'São Vicente e Granadinas',
    initials: 'VC',
    code: '+1 784',
  },
  {
    nationality: 'Senegalesa',
    label: 'Senegal',
    value: 'Senegal',
    initials: 'SN',
    code: '+221',
  },
  {
    nationality: 'Leonesa',
    label: 'Serra Leoa',
    value: 'Serra Leoa',
    initials: 'SL',
    code: '+232',
  },
  {
    nationality: 'Sérvia',
    label: 'Sérvia',
    value: 'Sérvia',
    initials: 'RS',
    code: '+381',
  },
  {
    nationality: 'Síria',
    label: 'Síria',
    value: 'Síria',
    initials: 'SY',
    code: '+963',
  },
  {
    nationality: 'Somali',
    label: 'Somália',
    value: 'Somália',
    initials: 'SO',
    code: '+252',
  },
  {
    nationality: 'Cingalesa',
    label: 'Sri Lanka',
    value: 'Sri Lanka',
    initials: 'LK',
    code: '+94',
  },
  {
    nationality: 'Suazi',
    label: 'Suazilândia',
    value: 'Suazilândia',
    initials: 'SZ',
    code: '+268',
  },
  {
    nationality: 'Sudanesa',
    label: 'Sudão',
    value: 'Sudão',
    initials: 'SD',
    code: '+249',
  },
  {
    nationality: 'Sueca',
    label: 'Suécia',
    value: 'Suécia',
    initials: 'SE',
    code: '+46',
  },
  {
    nationality: 'Suíça',
    label: 'Suíça',
    value: 'Suíça',
    initials: 'CH',
    code: '+41',
  },
  {
    nationality: 'Surinamesa',
    label: 'Suriname',
    value: 'Suriname',
    initials: 'SR',
    code: '+597',
  },
  {
    nationality: 'Tajique',
    label: 'Tadjiquistão',
    value: 'Tadjiquistão',
    initials: 'TJ',
    code: '+992',
  },
  {
    nationality: 'Tailandesa',
    label: 'Tailândia',
    value: 'Tailândia',
    initials: 'TH',
    code: '+66',
  },
  {
    nationality: 'Taiwanês',
    label: 'Taiwan',
    value: 'Taiwan',
    initials: 'TW',
    code: '+886',
  },
  {
    nationality: 'Tanzaniana',
    label: 'Tanzânia',
    value: 'Tanzânia',
    initials: 'TZ',
    code: '+255',
  },
  {
    nationality: 'Timorense',
    label: 'Timor-Leste',
    value: 'Timor-Leste',
    initials: 'TL',
    code: '+670',
  },
  {
    nationality: 'Togolesa',
    label: 'Togo',
    value: 'Togo',
    initials: 'TG',
    code: '+228',
  },
  {
    nationality: 'Tonganesa',
    label: 'Tonga',
    value: 'Tonga',
    initials: 'TO',
    code: '+676',
  },
  {
    nationality: 'Trinitário-tobagense',
    label: 'Trinidad e Tobago',
    value: 'Trinidad & Tobago',
    initials: 'TT',
    code: '+1 868',
  },
  {
    nationality: 'Tunisiana',
    label: 'Tunísia',
    value: 'Tunísia',
    initials: 'TN',
    code: '+216',
  },
  {
    nationality: 'Turcomana',
    label: 'Turcomenistão',
    value: 'Turcomenistão',
    initials: 'TM',
    code: '+993',
  },
  {
    nationality: 'Turca',
    label: 'Turquia',
    value: 'Turquia',
    initials: 'TR',
    code: '+90',
  },
  {
    nationality: 'Tuvaluana',
    label: 'Tuvalu',
    value: 'Tuvalu',
    initials: 'TV',
    code: '+688',
  },
  {
    nationality: 'Ucraniana',
    label: 'Ucrânia',
    value: 'Ucrânia',
    initials: 'UA',
    code: '+380',
  },
  {
    nationality: 'Ugandense',
    label: 'Uganda',
    value: 'Uganda',
    initials: 'UG',
    code: '+256',
  },
  {
    nationality: 'Uruguaia',
    label: 'Uruguai',
    value: 'Uruguai',
    initials: 'UY',
    code: '+598',
  },
  {
    nationality: 'Uzbeque',
    label: 'Uzbequistão',
    value: 'Uzbequistão',
    initials: 'UZ',
    code: '+998',
  },
  {
    nationality: 'Vanuatuense',
    label: 'Vanuatu',
    value: 'Vanuatu',
    initials: 'VU',
    code: '+678',
  },
  {
    nationality: 'Venezuelana',
    label: 'Venezuela',
    value: 'Venezuela',
    initials: 'VE',
    code: '+58',
  },
  {
    nationality: 'Vietnamita',
    label: 'Vietnã',
    value: 'Vietnã',
    initials: 'VN',
    code: '+84',
  },
  {
    nationality: 'Wallis-futunense',
    label: 'Wallis e Futuna',
    value: 'Wallis & Futuna',
    initials: 'WF',
    code: '+681',
  },
  {
    nationality: 'Zambiana',
    label: 'Zâmbia',
    value: 'Zâmbia',
    initials: 'ZM',
    code: '+260',
  },
  {
    nationality: 'Zimbabuana',
    label: 'Zimbábue',
    value: 'Zimbábue',
    initials: 'ZW',
    code: '+263',
  },
];
