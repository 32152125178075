import React, { useEffect, useState } from "react";
import { SeeCameraPageProps } from "../CommonTypes";
import { useTranslation } from "react-i18next";
import { Else, If, Then } from "react-if";
import { Camera } from "~/components/WebCam";
import idMask from "assets/masks/id-mask.png";
import faceMask from "assets/masks/face.png";
import cnhFrontMask from "assets/masks/cnh-front-mask.png";
import cnhBackMask from "assets/masks/cnh-back-mask.png";
import FooterCamera from "~/components/FooterCamera";
import * as Text from "styles/text";
import { ReactComponent as FrontIcon } from "assets/images/icons/cnh-front.svg";
import { ReactComponent as BackIcon } from "assets/images/icons/id-back-camera.svg";
import { ReactComponent as UserIcon } from "assets/images/icons/user-gray.svg";

export default function SeeCameraPage(props: SeeCameraPageProps) {
  const {
    goBack,
    isSelfie,
    isRgMask,
    docPosition,
    setUseNativeCam,
    webcamRef,
    saveFile,
  } = props;

  const maskSrc = docPosition === "back" ? cnhBackMask : cnhFrontMask;
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (webcamRef.current) {
      setIsLoading(false);
    }
  }, [webcamRef.current]);

  const error = {
    noCameraAccessible: t("noCameraAccessible"),
    permissionDenied: t("permissionDenied"),
    switchCamera: t("switchCamera"),
    canvas: t("canvas"),
  };

  const getIcon = !docPosition ? (
    <UserIcon />
  ) : docPosition === "front" ? (
    <FrontIcon />
  ) : (
    <BackIcon />
  );

  const getTextLabel = !docPosition
    ? "Selfie"
    : docPosition === "front"
    ? t("DocFront")
    : t("DocBack");

  return (
    <If condition={isPortrait}>
      <Then>
        <If condition={isSelfie}>
          <Then>
            <Camera
              maskPadding={25}
              maskImage={faceMask}
              errorMessages={error}
              facingMode="user"
              ref={webcamRef}
            />
          </Then>
          <Else>
            <div className="container-aspect-ratio">
              <Camera
                useNativeCam={setUseNativeCam}
                maskPadding={40}
                maskImage={isRgMask ? idMask : maskSrc}
                aspectRatio={3 / 4}
                errorMessages={error}
                facingMode="environment"
                ref={webcamRef}
              />
            </div>
          </Else>
        </If>
        <FooterCamera
          leftButtonContent={t("Back")}
          onClickLeftButton={goBack}
          onClickCamera={isLoading ? () => {} : saveFile}
          icon={getIcon}
          loading={isLoading}
          textLabel={getTextLabel}
        />
      </Then>
      <Else>
        <div className="align-center justify-center column w-100 h-100">
          <Text.Heading4Bold alignSelf="center">
            {t("Rotate your phone vertically!")}
          </Text.Heading4Bold>
        </div>
      </Else>
    </If>
  );
}
