import React, { useState } from "react";
import * as Text from "styles/text";
import Header from "~/components/Header";
import { useProgressBar } from "~/store";
import { useTranslation } from "react-i18next";
import { ReactComponent as CardIcon } from "assets/images/icons/card.svg";
import { SeePhotoAndRotateProps } from "../CommonTypes";
import { When } from "react-if";
import { ReactComponent as RotateLeft } from "assets/images/icons/rotate-left.svg";
import { ReactComponent as RotateRight } from "assets/images/icons/rotate-right.svg";
import cnhHorizontal from "assets/images/cnh-horizontal.png";
import cnhHorizontalBack from "assets/images/cnh-horizontal-back.png";
import { ThreeDots } from "react-loader-spinner";
import { colors } from "~/styles";
import Footer from "~/components/Footer";
import CheckBox from "~/components/Inputs/CheckBox";

export default function SeePhotoAndRotate(props: SeePhotoAndRotateProps) {
  const {
    isRgMask,
    docPosition,
    currentPhoto,
    goBack,
    loadingRotate,
    nextStep,
    rotateImageControl,
    rotateDeg,
    loadingSendDocs,
  } = props;

  const { setProgressPct } = useProgressBar();

  const { t } = useTranslation();
  const [confirmHorizontal, setConfirmHorizontal] = useState(false);

  return (
    <>
      <div
        style={{
          overflow: "scroll",
          paddingBottom: 77,
        }}
        className="content"
      >
        <Header label={t("ID Proof")} icon={<CardIcon />} />
        <Text.Heading1Medium marginTop={24} marginBottom={24} fontSize={1.938}>
          {t(
            "Is the image in the correct orientation, with the text horizontally?"
          )}
        </Text.Heading1Medium>

        <div className="align-selfie-resized">
          <img
            style={{
              transform: `rotate(${rotateDeg}deg)`,
              objectFit: "contain",
            }}
            className="container-docs-image"
            src={currentPhoto}
            alt="doc"
            id="rotated-img"
          />
          <When condition={loadingRotate}>
            <div className="loading-rotate-container">
              <ThreeDots color={colors.primary} />
            </div>
          </When>
        </div>

        <div className="container-rotate">
          <button onClick={() => rotateImageControl("left")}>
            <RotateLeft style={{ marginRight: 8 }} />
          </button>
          <img
            src={docPosition !== "back" ? cnhHorizontal : cnhHorizontalBack}
          />
          <button onClick={() => rotateImageControl("right")}>
            <RotateRight style={{ marginRight: 8 }} />
          </button>
        </div>
        <CheckBox
          onCheck={setConfirmHorizontal}
          label={t(
            "I confirm that the image is in the correct orientation (horizontal)."
          )}
        />
      </div>

      <Footer
        onClickLeftButton={goBack}
        leftButtonContent={t("Take a new photo")}
        onClickGenericButton={nextStep}
        genericButtonType="secondary"
        disableGenericButton={!confirmHorizontal}
        genericButtonContent={t("Next")}
        loadingGenericButton={loadingSendDocs}
      />
    </>
  );
}
