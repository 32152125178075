import {create} from "zustand";
import { FlowTypes, ProgressBartPctTypes } from "types/store.types";


export const useProgressBar = create<ProgressBartPctTypes>((set) => ({
  progressPct: 0,
  totalOfSteps: 0,
  setProgressPct: (value) => set(() => ({ progressPct: value })),
  setTotalOfSteps: (value) => set(() => ({ totalOfSteps: value }))
}));

export const useFlow = create<FlowTypes>((set) => ({
  flow: "default",
  setFlow: (value) => set(() => ({ flow: value }))
}))

const localStorageKey = 'form_data_settle_kyc';
export const useFormStore: any = create((set: any) => ({
  formData: {
  },
  setFormData: (data: any) => set({ formData: data }),
  saveFormData: (data: any) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  },
  loadFormData: () => {
    const savedData = localStorage.getItem(localStorageKey);
    if (savedData) {
      set({ formData: JSON.parse(savedData) });
    }
  },
}));
