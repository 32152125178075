import * as yup from "yup";
import moment from "moment";
import { validateCPF } from "~/helpers/validation/personalValidation";

export const validationSchema = {
  formName: yup.object({
    fullName: yup
      .string()
      .required("This field is required")
      .min(5, "Is this your full name?"),
  }),
  formCPF: yup.object({
    cpf: yup
      .string()
      .required("This field is required")
      .min(14, "The CPF must contain at least 11 characters")
      .test("cpf-validate", "Invalid cpf", (value) => validateCPF(value)),
    //includes the separator
  }),
  formNationality: yup.object({
    isBrazilian: yup.boolean().required(),
  }),
  formCountry: yup.object({
    country: yup.string().required(),
  }),
  formMotherName: yup.object({
    motherName: yup
      .string()
      .required("This field is required")
      .min(5, "Is this your full name?"),
  }),
  formBirthDate: yup.object({
    birthDate: yup
      .string()
      .required("This field is required")
      .min(10, "Must have 8 characters")
      .test("is-valid-date", "Invalid date", (value: string) => {
        const date = moment(moment(value, "DD/MM/YYYY").toDate());

        return date.isValid();
      })
      .test(
        "age-test-over-18",
        "Must be over than 18 years old",
        (value: string) => {
          return (
            moment().diff(
              moment(moment(value, "DD/MM/YYYY").toDate()),
              "years"
            ) >= 18
          );
        }
      ),
  }),
  formAddress: yup.object({
    cep: yup
      .string()
      .required("This field is required")
      .min(10, "Must have 8 characters"), //includes the separator
    state: yup
      .string()
      .required("This field is required")
      .min(2, "Must have 2 characters"),
    city: yup.string().required("This field is required"),
    address: yup.string().required("This field is required"),
    number: yup
      .number()
      .typeError("Must be a number")
      .required("This field is required"),
    neighborhood: yup.string().required("This field is required"),
    complement: yup.string(),
  }),
  formPhone: yup.object({
    phone: yup.string().required("This field is required"),
  }),
};
