import * as Style from '../Kyc.styles';
import * as Text from 'styles/text';

import { ChangeEvent, useEffect, useState } from 'react';
import { InputForm, SelectRadio } from 'components';

import Footer from 'components/Footer';
import Header from 'components/Header';
import { StepProps } from '../Kyc.types';
import { ReactComponent as WorldIcon } from 'assets/images/icons/world.svg';
import { countriesList } from 'data/countries';
import { formatStringToSearch } from 'helpers';
import { useFormContext } from 'react-hook-form';
import { useProgressBar } from '~/store';
import { useTranslation } from 'react-i18next';
import { userId } from '~/services/url';

export default function FormCountry({ goToStep }: StepProps) {
  const { setValue, watch } = useFormContext();

  const [search, setSearch] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(
    watch('country') || ''
  );

  const { setProgressPct, totalOfSteps } = useProgressBar();

  const _countriesList = countriesList.filter((c) =>
    formatStringToSearch(c.label).startsWith(formatStringToSearch(search))
  );

  const userUuid = userId()

  useEffect(() => {
     // @ts-ignore
     gtag('event', 'screen_view', {
      'screen_name': 'Country_step',
      userId: userUuid
    });

    setProgressPct((4 / totalOfSteps) * 100);
  }, []);

  useEffect(() => {
    setValue('country', selectedCountry);
  }, [selectedCountry, setValue]);

  const { t } = useTranslation();

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<WorldIcon />} />

      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t('In which country were you born?')}
      </Text.Heading1Medium>

      <InputForm
        searchIcon
        placeholder={t('Search nationality')}
        marginBottom={20}
        value={search}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />

      <Style.List>
        {_countriesList.map((c) => (
          <SelectRadio
            key={c.label}
            label={c.label}
            checked={c.label === selectedCountry}
            onChange={() => setSelectedCountry(c.label)}
            name="country"
          />
        ))}
      </Style.List>

      <Footer
        onClickLeftButton={() => goToStep('prev')}
        leftButtonContent={t('Back')}
        onClickGenericButton={() => goToStep('next')}
        disableGenericButton={!watch('country')}
        genericButtonType="secondary"
        genericButtonContent={t('Continue')}
      />
    </Style.StepContent>
  );
}
