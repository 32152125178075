import * as Style from '../Kyc.styles';
import * as Text from 'styles/text';

import { monthlyIncome, monthlyIncomePT, monthlyIncomeSP } from '~/data/others';
import { useEffect, useMemo, useState } from 'react';

import { ReactComponent as CardIcon } from 'assets/images/icons/card.svg';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { SelectRadio } from 'components';
import { StepProps } from '../Kyc.types';
import { useFormContext } from 'react-hook-form';
import { useProgressBar } from '~/store';
import { useTranslation } from 'react-i18next';
import { userId } from '~/services/url';

export default function Income({ goToStep }: StepProps) {
  const { setValue, watch } = useFormContext();

  const [selectedIncome, setSelectedIncome] = useState(
    watch('declaredIncome') || ''
  );

  const { t, i18n } = useTranslation();
  const { setProgressPct, totalOfSteps } = useProgressBar();

  const incomeList = useMemo(() => {
    let list = [];
    switch (i18n.language) {
      case 'es-ES':
        list = monthlyIncomeSP;
        break;

      case 'en-US':
        list = monthlyIncome;
        break;

      default:
        list = monthlyIncomePT;
        break;
    }

    return list;
  }, [i18n.language]);

  const userUuid = userId()

  useEffect(() => {
     // @ts-ignore
     gtag('event', 'screen_view', {
      'screen_name': 'income_step',
      userId: userUuid
    });
    setProgressPct((4 / totalOfSteps) * 100);
  }, []);

  useEffect(() => {
    setValue('declaredIncome', selectedIncome);
  }, [selectedIncome, setValue]);

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<CardIcon />} />

      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t('What is your gross monthly income?')}
      </Text.Heading1Medium>

      {incomeList.map((c) => (
        <SelectRadio
          key={c.value}
          label={t(c.label)}
          checked={c.value === selectedIncome}
          onChange={() => setSelectedIncome(c.value)}
          name="monthlyIncome"
        />
      ))}

      <Footer
        onClickLeftButton={() => goToStep('formOccupation')}
        leftButtonContent={t('Back')}
        onClickGenericButton={() => goToStep('next')}
        disableGenericButton={!watch('declaredIncome')}
        genericButtonType="secondary"
        genericButtonContent={t('Continue')}
      />
    </Style.StepContent>
  );
}
