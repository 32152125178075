import React, { useEffect } from "react";
import * as Text from "styles/text";
import * as Style from "../Kyc.styles";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { StepProps } from "../Kyc.types";
import { ReactComponent as LocationIcon } from "assets/images/icons/location.svg";
import { BigInput, InputForm } from "~/components";
import { cepMask } from "~/helpers";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { useProgressBar } from "~/store";
import { brazilianStates } from "~/data/brazilianStates";
import { userId } from "~/services/url";

export default function FormAddress({ goToStep }: StepProps) {
  const { t } = useTranslation();
  const {
    getFieldState,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const cep = watch("cep");

  const cepState = getFieldState("cep");
  const disableButton =
    !watch("cep") ||
    !watch("state") ||
    !watch("city") ||
    !watch("address") ||
    !watch("number") ||
    !watch("neighborhood");

  const { setProgressPct, totalOfSteps } = useProgressBar();

  const userUuid = userId();

  useEffect(() => {
    // @ts-ignore
    gtag("event", "screen_view", {
      screen_name: "address_step",
      userId: userUuid,
    });
    setProgressPct((7 / totalOfSteps) * 100);
  }, []);

  const setAddrValues = (obj: any) => {
    setValue("state", obj.uf);
    setValue("city", obj.localidade);
    setValue("address", obj.logradouro);
    setValue("neighborhood", obj.bairro);
  };

  useEffect(() => {
    if (!disableButton) return;

    if (cep.length > 9) {
      fetch(
        `https://viacep.com.br/ws/${cep.replace(/[\.\-]/g, "")}/json/`
      ).then((resp) => {
        resp.json().then(setAddrValues);
      });
    }
  }, [cep]);

  interface stateListType {
    id: number;
    name: string;
    abbreviation: string;
  }

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<LocationIcon />} />

      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t("What’s your full address?")}
      </Text.Heading1Medium>

      <Controller
        name="cep"
        control={control}
        render={({ field }) => (
          <BigInput
            {...field}
            autoFocus
            placeholder={t("Postal Code")}
            maskFunction={cepMask}
            error={errors[field.name]?.message as string}
          />
        )}
      />

      <div className="input-group">
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <select {...field} required>
              <option disabled value="">
                {t("State")}
              </option>

              {brazilianStates.map((states: stateListType) => (
                <option key={states.abbreviation} value={states.abbreviation}>
                  {states.name}
                </option>
              ))}
            </select>
          )}
        />

        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <InputForm
              {...field}
              placeholder={t("City")}
              error={errors[field.name]?.message as string}
            />
          )}
        />
      </div>

      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <InputForm
            {...field}
            placeholder={t("Address")}
            error={errors[field.name]?.message as string}
          />
        )}
      />

      <div className="input-group">
        <Controller
          name="number"
          control={control}
          render={({ field }) => (
            <InputForm
              {...field}
              placeholder={t("Number")}
              error={errors[field.name]?.message as string}
            />
          )}
        />

        <Controller
          name="neighborhood"
          control={control}
          render={({ field }) => (
            <InputForm
              {...field}
              placeholder={t("Neighborhood")}
              error={errors[field.name]?.message as string}
            />
          )}
        />
      </div>
      <Controller
        name="complement"
        control={control}
        render={({ field }) => (
          <InputForm
            {...field}
            placeholder={t("Complement")}
            error={errors[field.name]?.message as string}
          />
        )}
      />

      <Footer
        onClickLeftButton={() => goToStep("prev")}
        leftButtonContent={t("Back")}
        onClickGenericButton={() => goToStep("next")}
        disableGenericButton={disableButton}
        genericButtonType="secondary"
        genericButtonContent={t("Continue")}
      />
    </Style.StepContent>
  );
}
