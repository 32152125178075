export function userId() {
  let [, userId] = window.location.pathname.replace(/^\/+|\/+$/g, '').split('/');
  return userId
}

export function qs(key) {
  var qs = {};
  location.search.substr(1).split("&").forEach(function(item) {
    var k = item.split("=")[0],
      v = decodeURIComponent(item.split("=")[1]);

      if (k in qs) {
        qs[k].push(v);
      } else {
      qs[k] = [v];
      };
  });
  return qs[key]
}