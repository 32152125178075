export var FacetecInitializerUtilities = (function () {
  var currentTheme = "FaceTec Theme";

  function displayStatus(message: string) {
    const statusElement = document.getElementById("status");

    if (!statusElement) return;

    document.getElementById("status")!.innerHTML = message;
  }

  function showMainUI() {

    const mainUIElement =  (document.querySelector(
      ".wrapping-box-container"
    ) as HTMLElement);


    if(!mainUIElement) return;


    mainUIElement.style.width = "100%"; 
    mainUIElement.style.opacity = "1";
  }

  // Disable buttons to prevent hammering, fade out main interface elements, and shuffle the guidance images.
  function fadeOutMainUIAndPrepareForSession() {
    disableAllButtons();
    (document.querySelector(
      ".wrapping-box-container"
    ) as HTMLElement)!.style.opacity = "0";
    
  }

  function disableAllButtons() {
    const buttons = document.querySelectorAll("button");

    if (!buttons) return;

    buttons.forEach(function (button) {
      button.disabled = true;
    });
  }

  function enableAllButtons() {
    const buttons = document.querySelectorAll("button");

    if (!buttons) return;

    buttons.forEach(function (button) {
      button.disabled = false;
    });
  }

  function handleErrorGettingServerSessionToken() {
    showMainUI();
    enableAllButtons();
    displayStatus(
      "Session could not be started due to an unexpected issue during the network request."
    );
  }

  function generateUUId() {
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  function formatUIForDevice() {
    if (isLikelyMobileDevice()) {
      (
        document.getElementsByClassName(
          "wrapping-box-container"
        )[0] as HTMLElement
      ).style.left = "50%";
      (
        document.getElementsByClassName(
          "wrapping-box-container"
        )[0] as HTMLElement
      ).style.transform = "translate(-50%, -50%)";
    }
  }

  function isLikelyMobileDevice() {
    var isMobileDeviceUA =
      !!/Android|iPhone|iPad|iPod|IEMobile|Mobile|mobile/i.test(
        navigator.userAgent || ""
      );
    // ChromeOS/Chromebook detection.
    if (
      isMobileDeviceUA &&
      (navigator.userAgent.indexOf("CrOS") != -1 ||
        navigator.userAgent.indexOf("Chromebook") != -1)
    ) {
      isMobileDeviceUA = false;
    }
    // Mobile device determination based on portrait / landscape and user agent.
    if (document.body.clientWidth < document.body.clientHeight || isMobileDeviceUA) {
      // Assume mobile device when in portrait mode or when determined by the user agent.
      return true;
    } else {
      return false;
    }
  }

  return {
    displayStatus,
    showMainUI,
    fadeOutMainUIAndPrepareForSession,
    disableAllButtons,
    enableAllButtons,
    generateUUId,
    formatUIForDevice,
    handleErrorGettingServerSessionToken,
  };
})();
