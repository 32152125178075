import * as Style from "./FooterCamera.styles";
import * as Text from "styles/text";
import { FooterCameraProps } from "./FooterCamera.types";
import { GenericButton } from "../Buttons";

import { ReactComponent as CameraIcon } from "assets/images/icons/camera.svg";
import { Circles } from "react-loader-spinner";
import { colors } from "~/styles";

const FooterCamera = (props: FooterCameraProps) => {
  return (
    <Style.Container>
      <button className="leftButton" onClick={props.onClickLeftButton}>
        {props.leftButtonContent}
      </button>

      <GenericButton
        buttonType="primary"
        onClick={props.onClickCamera}
        width="20px"
        height={68}
        borderRadius={100}
      >
        {props.loading ? (
          <Circles
            wrapperStyle={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            color={colors.white}
            height={30}
            width={30}
          />
        ) : (
          <CameraIcon />
        )}
      </GenericButton>

      <div className="label-container">
        <>{props.icon}</>
        <Text.Heading5 textAlign="center">{props.textLabel}</Text.Heading5>
      </div>
    </Style.Container>
  );
};

export default FooterCamera;
