import * as Style from "./Header.styles";
import * as Text from "styles/text";
import { HeaderProps } from "./Header.types";

import { If, Then, Else } from "react-if";
import { colors } from "~/styles";
import i18n from "i18n";
import { useTranslation } from "react-i18next";

const Header = (props: HeaderProps) => {
  const changeLanguageHandler = (lang: "pt-BR" | "en-US" | string) => {
    i18n.changeLanguage(lang);
  };
  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  return (
    <Style.Container>
      <>
        {props.icon}
        <If condition={props.chooseLanguage}>
          <Then>
            <select
              defaultValue={getLanguage()}
              onChange={(e) => changeLanguageHandler(e.target.value)}
              name="language"
              className="text-right"
            >
              <option value="pt-BR">{t("Portuguese")}</option>
              <option value="en-US">{t("English")}</option>
              <option value="es-Es">{t("Spanish")}</option>
            </select>
          </Then>

          <Else>
            <Text.Heading5 color={colors.grayMedium}>
              {props.label}
            </Text.Heading5>
          </Else>
        </If>
      </>
    </Style.Container>
  );
};

export default Header;
