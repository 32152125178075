import styled from "styled-components";
import { screen } from "styles/metrics";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .text-right {
    text-align: right;
    padding-right: 9px;
    cursor: pointer;
  }

  svg {
    border-radius: 15px;
  }

  select {
    border: none;
    visibility: hidden;
  }

  p {
    visibility: hidden;
  }

  @media ${screen.tablet} {
    p,
    select {
      visibility: visible;
    }
  }
`;
