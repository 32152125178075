import { ContainerCheckProps } from './CheckSelect.types';
import { colors } from 'styles';
import styled from 'styled-components';

export const Container = styled.div<ContainerCheckProps>`
  border: 1px solid ${colors.grayMedium};
  border-radius: 8px;
  padding: 15px;
  height: 74px;
  margin: 5px 0;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray};
    transition: all ease-out 0.3s;

    .move-top {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      transition: all ease-out 0.3s;
    }
  }
`;

export const BodyContainer = styled.div`
  display: flex;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ContainerIcon = styled.div`
  height: 44px;
  margin-right: 15px;
`;
