export const occupationList = [
  { code: 'OCP0001', occupation: 'Administrador Iniciativa Privada' },
  { code: 'OCP0002', occupation: 'Administrador Público' },
  { code: 'OCP0003', occupation: 'Advogado' },
  { code: 'OCP0004', occupation: 'Agricultor' },
  { code: 'OCP0005', occupation: 'Agrônomo' },
  { code: 'OCP0006', occupation: 'Agropecuarista' },
  { code: 'OCP0007', occupation: 'Analista e Desenvolvedor de Sistemas' },
  { code: 'OCP0008', occupation: 'Animador' },
  { code: 'OCP0009', occupation: 'Arqueólogo' },
  { code: 'OCP0010', occupation: 'Arquiteto' },
  { code: 'OCP0011', occupation: 'Arquivologista' },
  { code: 'OCP0012', occupation: 'Artes e Design' },
  { code: 'OCP0013', occupation: 'Artista Plástico' },
  { code: 'OCP0014', occupation: 'Astrônomo' },
  { code: 'OCP0015', occupation: 'Ator' },
  { code: 'OCP0016', occupation: 'Biblioteconomista' },
  { code: 'OCP0017', occupation: 'Biomédico' },
  { code: 'OCP0018', occupation: 'Bioquímico' },
  { code: 'OCP0019', occupation: 'Biotecnólogo' },
  { code: 'OCP0020', occupation: 'Chefe de Cozinha' },
  { code: 'OCP0021', occupation: 'Ciências Sociais e Humanas' },
  { code: 'OCP0022', occupation: 'Cientista da Computação' },
  { code: 'OCP0023', occupation: 'Construtor Civil' },
  { code: 'OCP0024', occupation: 'Construtor Naval' },
  { code: 'OCP0025', occupation: 'Contabilista' },
  { code: 'OCP0026', occupation: 'Cooperativista' },
  { code: 'OCP0027', occupation: 'Dançarino' },
  { code: 'OCP0028', occupation: 'Dentista' },
  { code: 'OCP0029', occupation: 'Designer' },
  { code: 'OCP0030', occupation: 'Designer de Games' },
  { code: 'OCP0031', occupation: 'Designer de Interiores' },
  { code: 'OCP0032', occupation: 'Designer de Moda' },
  { code: 'OCP0033', occupation: 'Ecologista' },
  { code: 'OCP0034', occupation: 'Economista' },
  { code: 'OCP0035', occupation: 'Educador Físico' },
  { code: 'OCP0036', occupation: 'Educomunicador' },
  { code: 'OCP0037', occupation: 'Enfermeiro' },
  { code: 'OCP0038', occupation: 'Engenheiro' },
  { code: 'OCP0039', occupation: 'Especialista em Comércio Exterior' },
  { code: 'OCP0040', occupation: 'Estatístico' },
  { code: 'OCP0041', occupation: 'Esteticista' },
  { code: 'OCP0042', occupation: 'Farmacêutico' },
  { code: 'OCP0043', occupation: 'Filósofo' },
  { code: 'OCP0044', occupation: 'Físico' },
  { code: 'OCP0045', occupation: 'Fisioterapeuta' },
  { code: 'OCP0046', occupation: 'Fonoaudiólogo' },
  { code: 'OCP0047', occupation: 'Fotógrafo' },
  { code: 'OCP0048', occupation: 'Geofísico' },
  { code: 'OCP0049', occupation: 'Geógrafo' },
  { code: 'OCP0050', occupation: 'Geólogo' },
  { code: 'OCP0051', occupation: 'Gerente Comercial' },
  { code: 'OCP0052', occupation: 'Gerontólogo' },
  { code: 'OCP0053', occupation: 'Gestor Ambiental' },
  { code: 'OCP0054', occupation: 'Gestor da Qualidade' },
  { code: 'OCP0055', occupation: 'Gestor da Tecnologia da Informação' },
  { code: 'OCP0056', occupation: 'Gestor de Recursos Humanos' },
  { code: 'OCP0057', occupation: 'Gestor de Turismo' },
  { code: 'OCP0058', occupation: 'Gestor em Saúde' },
  { code: 'OCP0059', occupation: 'Gestor Hospitalar' },
  { code: 'OCP0060', occupation: 'Gestor Público' },
  { code: 'OCP0061', occupation: 'Historiador' },
  { code: 'OCP0062', occupation: 'Historiador da Arte' },
  { code: 'OCP0063', occupation: 'Hoteleiro' },
  { code: 'OCP0064', occupation: 'Jornalista' },
  { code: 'OCP0065', occupation: 'Linguista' },
  { code: 'OCP0066', occupation: 'Matemático' },
  { code: 'OCP0067', occupation: 'Médico' },
  { code: 'OCP0068', occupation: 'Meteorologista' },
  { code: 'OCP0069', occupation: 'Minerador' },
  { code: 'OCP0070', occupation: 'Museologista' },
  { code: 'OCP0071', occupation: 'Músico' },
  { code: 'OCP0072', occupation: 'Musicoterapeuta' },
  { code: 'OCP0073', occupation: 'Nanotecnólogo' },
  { code: 'OCP0074', occupation: 'Nutricionista' },
  { code: 'OCP0075', occupation: 'Oceanógrafo' },
  { code: 'OCP0076', occupation: 'Pedagogo' },
  { code: 'OCP0077', occupation: 'Piloto de Avião' },
  { code: 'OCP0078', occupation: 'Produtor Audiovisual' },
  { code: 'OCP0079', occupation: 'Produtor Cênico' },
  { code: 'OCP0080', occupation: 'Produtor Cultural' },
  { code: 'OCP0081', occupation: 'Produtor Fonográfico' },
  { code: 'OCP0082', occupation: 'Professor' },
  { code: 'OCP0083', occupation: 'Psicólogo' },
  { code: 'OCP0084', occupation: 'Psicopedagogo' },
  { code: 'OCP0085', occupation: 'Publicitário' },
  { code: 'OCP0086', occupation: 'Químico' },
  { code: 'OCP0087', occupation: 'Radialista' },
  { code: 'OCP0088', occupation: 'Radiologista' },
  { code: 'OCP0089', occupation: 'Relações Internacionais' },
  { code: 'OCP0090', occupation: 'Relações Públicas' },
  { code: 'OCP0091', occupation: 'Saúde e Bem-estar' },
  { code: 'OCP0092', occupation: 'Secretária' },
  { code: 'OCP0093', occupation: 'Silvicultor' },
  { code: 'OCP0094', occupation: 'Sociólogo' },
  { code: 'OCP0095', occupation: 'Teólogo' },
  { code: 'OCP0096', occupation: 'Terapeuta Ocupacional' },
  { code: 'OCP0097', occupation: 'Tradutor e Intérprete' },
  { code: 'OCP0098', occupation: 'Turismólogo' },
  { code: 'OCP0099', occupation: 'Veterinário' },
  { code: 'OCP0100', occupation: 'Zootecnólogo' },
  { code: 'OCP0101', occupation: 'Autônomo' },
  { code: 'OCP0102', occupation: 'Aposentado' },
  { code: 'OCP0103', occupation: 'Sem Ocupação' },
  { code: 'OCP0104', occupation: 'Estudante' },
  { code: 'OCP0105', occupation: 'Outros' },
];

export const occupationListEN = [
  { code: 'OCP0001', occupation: 'Private Initiative Administrator' },
  { code: 'OCP0002', occupation: 'Public Administrator' },
  { code: 'OCP0003', occupation: 'Lawyer' },
  { code: 'OCP0004', occupation: 'Farmer' },
  { code: 'OCP0005', occupation: 'Agronomist' },
  { code: 'OCP0006', occupation: 'Agriculturalist' },
  { code: 'OCP0007', occupation: 'Systems Analyst and Developer' },
  { code: 'OCP0008', occupation: 'Animator' },
  { code: 'OCP0009', occupation: 'Archeologist' },
  { code: 'OCP0010', occupation: 'Architect' },
  { code: 'OCP0011', occupation: 'Archivist' },
  { code: 'OCP0012', occupation: 'Arts and Design' },
  { code: 'OCP0013', occupation: 'Visual Artist' },
  { code: 'OCP0014', occupation: 'Astronomer' },
  { code: 'OCP0015', occupation: 'Actor' },
  { code: 'OCP0016', occupation: 'Librarian' },
  { code: 'OCP0017', occupation: 'Biomedical' },
  { code: 'OCP0018', occupation: 'Biochemist' },
  { code: 'OCP0019', occupation: 'Biotechnologist' },
  { code: 'OCP0020', occupation: 'Head Chef' },
  { code: 'OCP0021', occupation: 'Social and Human Sciences' },
  { code: 'OCP0022', occupation: 'Computer Scientist' },
  { code: 'OCP0023', occupation: 'Civil Constructor' },
  { code: 'OCP0024', occupation: 'Naval Constructor' },
  { code: 'OCP0025', occupation: 'Accountant' },
  { code: 'OCP0026', occupation: 'Cooperativist' },
  { code: 'OCP0027', occupation: 'Dancer' },
  { code: 'OCP0028', occupation: 'Dentist' },
  { code: 'OCP0029', occupation: 'Designer' },
  { code: 'OCP0030', occupation: 'Game Designer' },
  { code: 'OCP0031', occupation: 'Interior Designer' },
  { code: 'OCP0032', occupation: 'Fashion Designer' },
  { code: 'OCP0033', occupation: 'Ecologist' },
  { code: 'OCP0034', occupation: 'Economist' },
  { code: 'OCP0035', occupation: 'Physical Educator' },
  { code: 'OCP0036', occupation: 'Educommunicator' },
  { code: 'OCP0037', occupation: 'Nurse' },
  { code: 'OCP0038', occupation: 'Engineer' },
  { code: 'OCP0039', occupation: 'Expert in Foreign Trade' },
  { code: 'OCP0040', occupation: 'Statistician' },
  { code: 'OCP0041', occupation: 'Esthetician' },
  { code: 'OCP0042', occupation: 'Pharmacist' },
  { code: 'OCP0043', occupation: 'Philosopher' },
  { code: 'OCP0044', occupation: 'Physicist' },
  { code: 'OCP0045', occupation: 'Physical Therapist' },
  { code: 'OCP0046', occupation: 'Speech Therapist' },
  { code: 'OCP0047', occupation: 'Photographer' },
  { code: 'OCP0048', occupation: 'Geophysicist' },
  { code: 'OCP0049', occupation: 'Geographer' },
  { code: 'OCP0050', occupation: 'Geologist' },
  { code: 'OCP0051', occupation: 'Commercial Manager' },
  { code: 'OCP0052', occupation: 'Gerontologist' },
  { code: 'OCP0053', occupation: 'Environmental Manager' },
  { code: 'OCP0054', occupation: 'Quality Manager' },
  { code: 'OCP0055', occupation: 'Information Technology Manager' },
  { code: 'OCP0056', occupation: 'Human Resources Manager' },
  { code: 'OCP0057', occupation: 'Tourism Manager' },
  { code: 'OCP0058', occupation: 'Health Manager' },
  { code: 'OCP0059', occupation: 'Hospital Manager' },
  { code: 'OCP0060', occupation: 'Public Manager' },
  { code: 'OCP0061', occupation: 'Historian' },
  { code: 'OCP0062', occupation: 'Art Historian' },
  { code: 'OCP0063', occupation: 'Hotelier' },
  { code: 'OCP0064', occupation: 'Journalist' },
  { code: 'OCP0065', occupation: 'Linguist' },
  { code: 'OCP0066', occupation: 'Mathematician' },
  { code: 'OCP0067', occupation: 'Doctor' },
  { code: 'OCP0068', occupation: 'Meteorologist' },
  { code: 'OCP0069', occupation: 'Miner' },
  { code: 'OCP0070', occupation: 'Museologist' },
  { code: 'OCP0071', occupation: 'Musician' },
  { code: 'OCP0072', occupation: 'Music Therapist' },
  { code: 'OCP0073', occupation: 'Nanotechnologist' },
  { code: 'OCP0074', occupation: 'Nutritionist' },
  { code: 'OCP0075', occupation: 'Oceanographer' },
  { code: 'OCP0076', occupation: 'Pedagogue' },
  { code: 'OCP0077', occupation: 'Airplane Pilot' },
  { code: 'OCP0078', occupation: 'Audiovisual Producer' },
  { code: 'OCP0079', occupation: 'Scenic Producer' },
  { code: 'OCP0080', occupation: 'Cultural Producer' },
  { code: 'OCP0081', occupation: 'Phonographic Producer' },
  { code: 'OCP0082', occupation: 'Professor' },
  { code: 'OCP0083', occupation: 'Psychologist' },
  { code: 'OCP0084', occupation: 'Psychopedagogue' },
  { code: 'OCP0085', occupation: 'Advertiser' },
  { code: 'OCP0086', occupation: 'Chemist' },
  { code: 'OCP0087', occupation: 'Broadcaster' },
  { code: 'OCP0088', occupation: 'Radiologist' },
  { code: 'OCP0089', occupation: 'International Relations' },
  { code: 'OCP0090', occupation: 'Public Relations' },
  { code: 'OCP0091', occupation: 'Health and Wellness' },
  { code: 'OCP0092', occupation: 'Secretary' },
  { code: 'OCP0093', occupation: 'Forester' },
  { code: 'OCP0094', occupation: 'Sociologist' },
  { code: 'OCP0095', occupation: 'Theologian' },
  { code: 'OCP0096', occupation: 'Occupational Therapist' },
  { code: 'OCP0097', occupation: 'Translator and Interpreter' },
  { code: 'OCP0098', occupation: 'Tourismologist' },
  { code: 'OCP0099', occupation: 'Veterinarian' },
  { code: 'OCP0100', occupation: 'Animal Scientist' },
  { code: 'OCP0101', occupation: 'Self-employed' },
  { code: 'OCP0102', occupation: 'Retired' },
  { code: 'OCP0103', occupation: 'Unemployed' },
  { code: 'OCP0104', occupation: 'Student' },
  { code: 'OCP0105', occupation: 'Others' },
];

export const occupationListSP = [
  { code: 'OCP0001', occupation: 'Administrador de Empresa Privada' },
  { code: 'OCP0002', occupation: 'Administrador Público' },
  { code: 'OCP0003', occupation: 'Abogado' },
  { code: 'OCP0004', occupation: 'Agricultor' },
  { code: 'OCP0005', occupation: 'Agrónomo' },
  { code: 'OCP0006', occupation: 'Agropecuarista' },
  { code: 'OCP0007', occupation: 'Analista y Desarrollador de Sistemas' },
  { code: 'OCP0008', occupation: 'Animador' },
  { code: 'OCP0009', occupation: 'Arqueólogo' },
  { code: 'OCP0010', occupation: 'Arquitecto' },
  { code: 'OCP0011', occupation: 'Arquivólogo' },
  { code: 'OCP0012', occupation: 'Artes y Diseño' },
  { code: 'OCP0013', occupation: 'Artista Plástico' },
  { code: 'OCP0014', occupation: 'Astronomo' },
  { code: 'OCP0015', occupation: 'Actor' },
  { code: 'OCP0016', occupation: 'Bibliotecólogo' },
  { code: 'OCP0017', occupation: 'Biomédico' },
  { code: 'OCP0018', occupation: 'Bioquímico' },
  { code: 'OCP0019', occupation: 'Biotecnólogo' },
  { code: 'OCP0020', occupation: 'Jefe de Cocina' },
  { code: 'OCP0021', occupation: 'Ciencias Sociales y Humanas' },
  { code: 'OCP0022', occupation: 'Científico de la Computación' },
  { code: 'OCP0023', occupation: 'Constructor Civil' },
  { code: 'OCP0024', occupation: 'Constructor Naval' },
  { code: 'OCP0025', occupation: 'Contador' },
  { code: 'OCP0026', occupation: 'Cooperativista' },
  { code: 'OCP0027', occupation: 'Bailarín' },
  { code: 'OCP0028', occupation: 'Dentista' },
  { code: 'OCP0029', occupation: 'Diseñador' },
  { code: 'OCP0030', occupation: 'Diseñador de Juegos' },
  { code: 'OCP0031', occupation: 'Diseñador de Interiores' },
  { code: 'OCP0032', occupation: 'Diseñador de Moda' },
  { code: 'OCP0033', occupation: 'Ecologista' },
  { code: 'OCP0034', occupation: 'Economista' },
  { code: 'OCP0035', occupation: 'Educador Físico' },
  { code: 'OCP0036', occupation: 'Educomunicador' },
  { code: 'OCP0037', occupation: 'Enfermero' },
  { code: 'OCP0038', occupation: 'Ingeniero' },
  { code: 'OCP0039', occupation: 'Especialista en Comercio Exterior' },
  { code: 'OCP0040', occupation: 'Estatístico' },
  { code: 'OCP0041', occupation: 'Esteticista' },
  { code: 'OCP0042', occupation: 'Farmaceutico' },
  { code: 'OCP0043', occupation: 'Filósofo' },
  { code: 'OCP0044', occupation: 'Físico' },
  { code: 'OCP0045', occupation: 'Fisioterapeuta' },
  { code: 'OCP0046', occupation: 'Fonoaudiólogo' },
  { code: 'OCP0047', occupation: 'Fotógrafo' },
  { code: 'OCP0048', occupation: 'Geofísico' },
  { code: 'OCP0049', occupation: 'Geógrafo' },
  { code: 'OCP0050', occupation: 'Geólogo' },
  { code: 'OCP0051', occupation: 'Gerente Comercial' },
  { code: 'OCP0052', occupation: 'Gerontólogo' },
  { code: 'OCP0053', occupation: 'Gestor Ambiental' },
  { code: 'OCP0054', occupation: 'Gestor de Calidad' },
  { code: 'OCP0055', occupation: 'Gestor de Tecnología de la Información' },
  { code: 'OCP0056', occupation: 'Gestor de Recursos Humanos' },
  { code: 'OCP0057', occupation: 'Gestor de Turismo' },
  { code: 'OCP0058', occupation: 'Gestor en Salud' },
  { code: 'OCP0059', occupation: 'Gestor Hospitalario' },
  { code: 'OCP0060', occupation: 'Gestor Público' },
  { code: 'OCP0061', occupation: 'Historiador' },
  { code: 'OCP0062', occupation: 'Historiador de Arte' },
  { code: 'OCP0063', occupation: 'Hoteleiro' },
  { code: 'OCP0064', occupation: 'Periodista' },
  { code: 'OCP0065', occupation: 'Lingüista' },
  { code: 'OCP0066', occupation: 'Matemático' },
  { code: 'OCP0067', occupation: 'Médico' },
  { code: 'OCP0068', occupation: 'Meteorólogo' },
  { code: 'OCP0069', occupation: 'Minerador' },
  { code: 'OCP0070', occupation: 'Museólogo' },
  { code: 'OCP0071', occupation: 'Músico' },
  { code: 'OCP0072', occupation: 'Musicoterapeuta' },
  { code: 'OCP0073', occupation: 'Nanotecnólogo' },
  { code: 'OCP0074', occupation: 'Nutricionista' },
  { code: 'OCP0075', occupation: 'Oceanógrafo' },
  { code: 'OCP0076', occupation: 'Pedagogo' },
  { code: 'OCP0077', occupation: 'Piloto de avión' },
  { code: 'OCP0078', occupation: 'Productor audiovisual' },
  { code: 'OCP0079', occupation: 'Productor escénico' },
  { code: 'OCP0080', occupation: 'Productor cultural' },
  { code: 'OCP0081', occupation: 'Productor fonográfico' },
  { code: 'OCP0082', occupation: 'Profesor' },
  { code: 'OCP0083', occupation: 'Psicólogo' },
  { code: 'OCP0084', occupation: 'Psicopedagogo' },
  { code: 'OCP0085', occupation: 'Publicista' },
  { code: 'OCP0086', occupation: 'Químico' },
  { code: 'OCP0087', occupation: 'Locutor' },
  { code: 'OCP0088', occupation: 'Radiólogo' },
  { code: 'OCP0089', occupation: 'Relaciones internacionales' },
  { code: 'OCP0090', occupation: 'Relaciones públicas' },
  { code: 'OCP0091', occupation: 'Salud y bienestar' },
  { code: 'OCP0092', occupation: 'Secretaria' },
  { code: 'OCP0093', occupation: 'Forestal' },
  { code: 'OCP0094', occupation: 'Sociólogo' },
  { code: 'OCP0095', occupation: 'Teólogo' },
  { code: 'OCP0096', occupation: 'Terapeuta ocupacional' },
  { code: 'OCP0097', occupation: 'Traductor e intérprete' },
  { code: 'OCP0098', occupation: 'Turismo' },
  { code: 'OCP0099', occupation: 'Veterinario' },
  { code: 'OCP0100', occupation: 'Científico de animales' },
  { code: 'OCP0101', occupation: 'Autoempleado' },
  { code: 'OCP0102', occupation: 'Jubilado' },
  { code: 'OCP0103', occupation: 'Desempleado' },
  { code: 'OCP0104', occupation: 'Estudiante' },
  { code: 'OCP0105', occupation: 'Otros' },
];
