import * as Style from '../Kyc.styles';
import * as Text from 'styles/text';

import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';

import { BigInput } from '~/components';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import { ReactComponent as MomIcon } from 'assets/images/icons/mom.svg';
import { StepProps } from '../Kyc.types';
import { nameMask } from '~/helpers';
import { useProgressBar } from '~/store';
import { useTranslation } from 'react-i18next';
import { userId } from '~/services/url';

export default function MotherName({ goToStep }: StepProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const { setProgressPct, totalOfSteps } = useProgressBar();

  const userUuid = userId()

  useEffect(() => {
     // @ts-ignore
     gtag('event', 'screen_view', {
      'screen_name': 'mother_name_step',
      userId: userUuid
    });
    
    setProgressPct((5 / totalOfSteps) * 100);
  }, []);

  const goBack = () => {
    goToStep('prev');
  };
  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<MomIcon />} />

      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t("What’s your mother's full name?")}
      </Text.Heading1Medium>

      <Controller
        name="motherName"
        control={control}
        render={({ field }) => (
          <BigInput
            {...field}
            autoFocus
            placeholder={t('E.g.') + ' Maria Santos'}
            error={errors[field.name]?.message as string}
            maskFunction={nameMask}
          />
        )}
      />

      <Text.Heading4 marginTop={24}>
        {t('Your mother’s name must be')}
      </Text.Heading4>

      <Footer
        onClickLeftButton={goBack}
        leftButtonContent={t('Back')}
        onClickGenericButton={() => goToStep('next')}
        disableGenericButton={!watch('motherName')}
        genericButtonType="secondary"
        genericButtonContent={t('Continue')}
      />
    </Style.StepContent>
  );
}
