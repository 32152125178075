import * as Style from "./Footer.styles";
import { When } from "react-if";
import GenericButton from "components/Buttons/GenericButton";
import { FooterProps } from "./Footer.types";
import useWindowDimensions from "hooks/windowDimension";

const Footer = (props: FooterProps) => {
  const { width } = useWindowDimensions();
  return (
    <Style.FooterWrapper
      screenWidth={width}
      hasLeftButton={!!props.onClickLeftButton}
      hasTopBorder={!!props.hasTopBorder}
    >
      <div
        className={`buttons-wrapper
        ${props.genericButtonOnLeft ? "generic-button-left" : ""}`}
      >
        <When condition={!!props.onClickLeftButton}>
          <button className="leftButton" onClick={props.onClickLeftButton}>
            {props.leftButtonContent}
          </button>
        </When>

        <GenericButton
          buttonType={props.genericButtonType || "primary"}
          width={props.genericButtonWidth || "auto"}
          onClick={props.onClickGenericButton}
          disabled={props.disableGenericButton}
          buttonLoading={props.loadingGenericButton}
        >
          <div className="align-text-center">
            {props.genericButtonContent || "Começar"}
          </div>
        </GenericButton>
      </div>
    </Style.FooterWrapper>
  );
};

export default Footer;
