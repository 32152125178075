import * as Style from "../Kyc.styles";
import * as Text from "styles/text";

import { useEffect, useState } from "react";

import ArrowDown from "assets/images/icons/arrow-down-blue.svg";
import { ReactComponent as CardIcon } from "assets/images/icons/card.svg";
import { CheckSelect } from "~/components";
import { ReactComponent as CnhIcon } from "assets/images/icons/cnh.svg";
import Footer from "~/components/Footer";
import Header from "~/components/Header";
import { ReactComponent as RgIcon } from "assets/images/icons/rg.svg";
import { StepProps } from "../Kyc.types";
import { When } from "react-if";
import { colors } from "~/styles";
import { useFlow, useProgressBar } from "~/store";
import { useTranslation } from "react-i18next";
import { FacetecInitializer } from "~/lib/Facetec";
import { userId } from "~/services/url";
import Modal from "~/components/Modal";
import { GenericButton } from "~/components/Buttons";

export default function SelectDocs({ goToStep }: StepProps) {
  const [selected, setSelected] = useState("");
  const [showMoreDocs, setShowMoreDocs] = useState(false);
  const { setProgressPct, totalOfSteps } = useProgressBar();
  const [isFacetecLoading, setIsFacetecLoading] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const [showModalWhenClosingFacetec, setShowModalWhenClosingFacetec] =
    useState(false);

  const { setFlow } = useFlow();

  const userUuid = userId();

  const selectItem = (value: string) => {
    if (selected === value) return setSelected("");
    setSelected(value);
  };

  const { t } = useTranslation();

  const goNextStep = () => {
    if (selected !== "") {
      goToStep("finish", { maskType: selected });
    }
  };

  useEffect(() => {
    // @ts-ignore
    gtag("event", "screen_view", {
      screen_name: "select_doc_step",
      userId: userUuid
    });
    setProgressPct((9 / totalOfSteps) * 100);
  }, []);

  const showMoreDocTypes = () => {
    setSelected("");
    setShowMoreDocs((prev) => !prev);
  };

  const initFaceTec = () => {
    setTryAgain(false);
    setIsFacetecLoading(true);

     // @ts-ignore
     gtag("event", "user_initialized_facetec_flow", {
      userId: userUuid
    });

    FacetecInitializer.onPhotoIDMatchPressed({
      userId: userUuid,
      callbackOnFinishCapFace: (rtn) => {
        console.log(rtn);
        setIsFacetecLoading(false);
        goNextStep();
      },
      callbackOnErrorCapFace: (rtn) => {
        console.log(rtn);
        setTryAgain(true);
        setIsFacetecLoading(false);
        setShowModalWhenClosingFacetec(true);
      },
      callbackChangeToDefaultFlow: () => {
        setTryAgain(false);
        setIsFacetecLoading(false);
        setShowModalWhenClosingFacetec(true);
      },
    });
  };

  const handleClickContinueModal = () => {
     // @ts-ignore
     gtag("event", "user_changed_to_default_flow", {
      userId: userUuid
    });
    setFlow("default");
    setShowModalWhenClosingFacetec(false);
    goToStep("selectDocDefaultFlow", { maskType: selected });
  };

  return (
    <>
      <Style.StepContent>
        <Header label="Personal Data" icon={<CardIcon />} />
        <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
          {t("Choose a document to photograph")}
        </Text.Heading1Medium>

        <CheckSelect
          icon={<RgIcon />}
          title={t("RG")}
          text={t("General Registration")}
          selected={selected === "rg"}
          onClick={() => selectItem("rg")}
        />
        <CheckSelect
          icon={<CnhIcon />}
          title={t("CNH")}
          text={t("National Driving License")}
          selected={selected === "cnh"}
          onClick={() => selectItem("cnh")}
        />

        {/* Others types */}

        <div className="container-more-docs">
          <Text.Heading5
            onClick={showMoreDocTypes}
            className="hover"
            color={colors.primary}
          >
            {t("More documents types")}
          </Text.Heading5>

          <div className={showMoreDocs ? "arrow active" : "arrow"}>
            <img src={ArrowDown} alt="" />
          </div>
        </div>

        <When condition={showMoreDocs}>
          <CheckSelect
            icon={<RgIcon />}
            title={t("RNE")}
            text={t("National Registry of Foreigners")}
            selected={selected === "rne"}
            onClick={() => selectItem("rne")}
          />

          <CheckSelect
            icon={<RgIcon />}
            title={t("RNM")}
            text={t("National Migration Registry")}
            selected={selected === "rnm"}
            onClick={() => selectItem("rnm")}
          />

          <CheckSelect
            icon={<RgIcon />}
            title={t("DNI")}
            text={t("National Identity Document")}
            selected={selected === "dni"}
            onClick={() => selectItem("dni")}
          />
        </When>
        <Footer
          onClickLeftButton={() => goToStep("preparationDoc")}
          leftButtonContent={t("Back")}
          onClickGenericButton={initFaceTec}
          genericButtonType="secondary"
          genericButtonContent={tryAgain ? t("Try again") : t("Continue")}
          disableGenericButton={isFacetecLoading || selected === ""}
        />

        {showModalWhenClosingFacetec && (
          <div className="modal-backdrop">
            <div className="modal-content">
              <h1>Está com problemas?</h1>
              <p>
                Para tornar o processo ainda mais simples, fizemos melhorias na
                câmera para facilitar o registro dos documentos e da selfie.
                <br />
                <br />
                Clique em "Continuar" para prosseguir. Ou tente novamente.
              </p>

              <div>
                <GenericButton
                  buttonType="inline"
                  onClick={() => {
                    initFaceTec();
                    setShowModalWhenClosingFacetec(false);
                  }}
                >
                  Tentar novamente
                </GenericButton>

                <GenericButton
                  buttonType="secondary"
                  onClick={handleClickContinueModal}
                >
                  Continuar
                </GenericButton>
              </div>
            </div>
          </div>
        )}
      </Style.StepContent>
    </>
  );
}
