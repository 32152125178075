import * as Style from "./CheckBox.styles";
import { CheckBoxProps } from "./CheckBox.types";
import { forwardRef } from "react";

const CheckSelect = forwardRef((props: CheckBoxProps, _ref) => {
  return (
    <Style.Container>
      <label className="container">
        <input
          {...props}
          onChange={(e) => props.onCheck(e.target.checked)}
          type="checkbox"
        />
        <div className="checkmark" />
        {props.label}
      </label>
    </Style.Container>
  );
});

export default CheckSelect;
