import * as Style from "./ImageViewer.styles";
import * as Text from "styles/text";
import { ImageViewerProps } from "./ImageViewer.types";
import { useTranslation } from "react-i18next";

import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";

const Modal = (props: ImageViewerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Style.FullImageContainer isSelfie={props.isSelfie}>
        <Text.Heading2Bold
          className="close-button"
          onClick={() => props.setOpenViewerImage(false)}
        >
          ✕
        </Text.Heading2Bold>
        <TransformWrapper>
          {({ resetTransform, zoomIn, zoomOut }) => (
            <>
              <TransformComponent>
                <img className="doc-image" src={props.image} alt="img" />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Style.FullImageContainer>
    </>
  );
};

export default Modal;
