export var Config = (function () {
  // -------------------------------------
  // REQUIRED
  // Available at https://dev.facetec.com/#/account
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var DeviceKeyIdentifier = "d8GDMp4MPQO4HPFstQbY25gXHzyEbIuv";

  // -------------------------------------
  // REQUIRED
  // The URL to call to process FaceTec SDK Sessions.
  // In Production, you likely will handle network requests elsewhere and without the use of this variable.
  // See https://dev.facetec.com/#/security-best-practices?link=zoom-server-rest-endpoint-security for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var BaseURL = `${process.env.REACT_APP_BACKEND}/api/v1.0/capface/`;

  // -------------------------------------
  // REQUIRED
  // The FaceMap Encryption Key you define for your application.
  // Please see https://dev.facetec.com/#/licensing-and-encryption-keys for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var PublicFaceScanEncryptionKey =
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA08ZCVslQfDcMeVYC/r9Z\n" +
    "FjPfHFFL6aeTAn64AED0jva6o3dv3shPbcnPAfT+VStS7l6kKF6sNyJwO90/TesT\n" +
    "YdMA8a7GZ0CcGd+xsH+7E6YIJtLC0QQ9Zzt5nxR690IJ9PZGMHcAxeaVuU6hRA7j\n" +
    "QQ6Mi/f8NauJjChSTSsdm7D1MxpvNIjMkWuuIjo9XZt0C9vArYif6tWW6APzrezR\n" +
    "pL5o7fa/XXds1631NQdFQhmuDTV5te2YjP+qwlnWwRYx9SoZgfMPjozICrvVaZzp\n" +
    "D7Db4dsU9Hy4lOcJH52bQeZkl3YxSw75I1RgSXUCCS1FXUnOSGJGUnfMPV2sk+pc\n" +
    "owIDAQAB\n" +
    "-----END PUBLIC KEY-----\n";

  var productionKey =
    '{  "domains": "capitual.net",  "expiryDate": "2023-10-10",  "key": "003045022100ceb286603b609f1ec55df0d94a116b2ce279a1b034df25f0501d4dc58be0f83202205d86885649b195967cb2d583f7ac1a176ef4e92f4aa4b094cf6cd0b2238bb055"}';

  return {
    DeviceKeyIdentifier,
    BaseURL,
    PublicFaceScanEncryptionKey,
    productionKey,
  };
})();
