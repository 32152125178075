import React, { useEffect, useState } from 'react';
import * as Text from 'styles/text';
import * as Style from '../Kyc.styles';
import { When } from 'react-if';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import DocumentCard from '~/components/DocumentCard';
import { colors } from '~/styles';
import { StepProps } from '../Kyc.types';
import { ReactComponent as PicturesIcon } from 'assets/images/icons/pictures.svg';
import { ReactComponent as FrontDocIcon } from 'assets/images/icons/front-document.svg';
import { ReactComponent as BackDocIcon } from 'assets/images/icons/back-document.svg';
import { ReactComponent as SelfieIcon } from 'assets/images/icons/selfie-document.svg';
import AnnotationWarningIcon from 'assets/images/icons/annotation-warning.svg';
import AlertModal from '~/components/AlertModal';
import { useTranslation } from 'react-i18next';
import { useProgressBar } from '~/store';
import { InputForm } from '~/components';
import { cellphoneMask, formatPhone } from '~/helpers';
import QR from 'react-qr-code';
import { userId } from '~/services/url';
import { useFormContext } from 'react-hook-form';

export default function QRCode({ goToStep }: StepProps) {
  const [openModal, setOpenModal] = useState(false);
  const [position, setPosition] = useState(0);
  const { setProgressPct } = useProgressBar();
  const [frontDocument, setFrontDocument] = useState(false);
  const [backDocument, setBackDocument] = useState(false);
  const [selfie, setSelfie] = useState(false);
  const [intervalDocumentsReq, setIntervalDocumentsReq] = useState(
    {} as NodeJS.Timer
  );
  const userUuid = userId();

  const changeModalPosition = (value: number) => {
    setPosition(value);
  };
  const changeLoadingStates = (resp: any) => {
    setFrontDocument(resp.frontDocument);
    setBackDocument(resp.backDocument);
    setSelfie(resp.selfie);
  };
  useEffect(() => {
    // @ts-ignore
    gtag('event', 'screen_view', {
      screen_name: 'qr_code_step',
      userId: userUuid,
    });
    setProgressPct(100);
  }, []);
  useEffect(() => {
    if (frontDocument && backDocument && selfie) {
      clearInterval(intervalDocumentsReq);
    }
  }, [frontDocument, backDocument, selfie]);

  const { t } = useTranslation();

  const { watch } = useFormContext();

  const phone = watch('phone');

  return (
    <Style.StepContent>
      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t('Please scan the QR Code to continue in your smartphone.')}
      </Text.Heading1Medium>
      <div id="qrcode" style={{ paddingLeft: '5rem' }}>
        <QR value={window.location.href} />
      </div>
      <Footer
        onClickLeftButton={() => setOpenModal(!openModal)}
        onClickGenericButton={() => goToStep('next')}
        genericButtonType="secondary"
        genericButtonContent={t('Continue')}
        disableGenericButton={!(backDocument && frontDocument && selfie)}
      />

      <When condition={openModal}>
        <AlertModal
          setOpenAlert={() => [setOpenModal(false), changeModalPosition(0)]}
          primaryButtonAction={() => changeModalPosition(0)}
          secondaryButtonAction={() => changeModalPosition(1)}
          primaryButtonText={position === 1 ? t('Update phone number') : ``}
          secondaryButtonText={
            position === 0 ? t('Send new SMS') : t('Resend link')
          }
          title={
            position === 0 ? t('Update your phone number') : t('Resend SMS')
          }
        >
          {position === 0 && (
            <>
              <Text.Heading5>{t('We’ll send you a link')}</Text.Heading5>

              <Text.Paragraph color={colors.grayMedium} marginTop={30}>
                {t('Phone Number')}
              </Text.Paragraph>

              <InputForm
                width="100%"
                maskFunction={cellphoneMask}
                placeholder="(99) 9 9999-9999"
                autoFocus
              />
            </>
          )}

          {position === 1 && (
            <p
              style={{
                fontFamily: 'Helvetica Now Display Medium',
                fontSize: '0.93rem',
              }}
            >
              {t("Didn't receive the SMS? No problem")}{' '}
              <span style={{ color: colors.primary }}>
                {formatPhone(phone || '')}
              </span>{' '}
              {t('to complete the procedure on your cell phone.')}
            </p>
          )}
        </AlertModal>
      </When>
    </Style.StepContent>
  );
}
