import React, { useEffect } from "react";
import * as Text from "styles/text";
import * as Style from "../Kyc.styles";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { StepProps } from "../Kyc.types";
import { ReactComponent as CardIcon } from "assets/images/icons/card.svg";
import { BigInput } from "~/components";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { useProgressBar } from "~/store";
import axiosInst from "~/services/axios";
import { userId } from "~/services/url";
import { cellphoneMask, cepMask, cpfMask, nameMask } from "~/helpers";

export default function FormCPF({ goToStep }: StepProps) {
  const { t } = useTranslation();
  const { setProgressPct, totalOfSteps } = useProgressBar();

  const userUuid = userId();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();

  useEffect(() => {
    // @ts-ignore
    gtag('event', 'screen_view', {
      'screen_name': 'CPF_step',
      userId: userUuid
    });
    
    setProgressPct((2 / totalOfSteps) * 100);

    axiosInst.get("getUserInfo/" + userUuid).then((resp) => {
      console.log(resp);
      for (const key of Object.keys(resp.data)) {
        switch (key) {
          case "country":
            if (resp.data[key] === "Brasil") {
              setValue("isBrazilian", true);
            }
            break;
          case "cep":
            setValue(
              key,
              resp.data[key]
                .replace(/\D/g, "")
                .replace(/(\d{2})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d{1,2})/, "$1-$2")
            );
            break;
          case "userPhone":
            const nPhone = resp.data[key].replace("+55", "");
            setValue("phone", cellphoneMask(nPhone.replace(/[^0-9]/g, "")));
            break;
          case "cpfCnpj":
            setValue(
              "cpf",
              cpfMask(String(resp.data[key]).replace(/[^0-9]/g, ""))
            );
            break;
          case "birthday":
            const d = new Date(resp.data[key]);
            d.setTime(d.getTime() + 3 * 60 * 60 * 1000);
            setValue("birthDate", d.toLocaleDateString("pt-BR"));
            break;
          default:
            setValue(key, resp.data[key]);
        }
      }
    });
  }, []);

  const goNextAndSetCpf = () => {
    goToStep("next", { cpfToCheck: getValues("cpf") });
  };

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<CardIcon />} />

      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t("What’s your CPF number?")}
      </Text.Heading1Medium>
      <Controller
        name="cpf"
        control={control}
        render={({ field }) => (
          <BigInput
            autoFocus
            maskFunction={cpfMask}
            {...field}
            placeholder="000.000.000-00"
            error={errors[field.name]?.message as string}
          />
        )}
      />

      <Footer
        onClickLeftButton={() => goToStep("prev")}
        leftButtonContent={t("Back")}
        onClickGenericButton={goNextAndSetCpf}
        disableGenericButton={!watch("cpf")}
        genericButtonType="secondary"
        genericButtonContent={t("Continue")}
      />
    </Style.StepContent>
  );
}
