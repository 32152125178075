export const monthlyIncome = [
  {
    label: 'Less than R$ 1.000,00',
    value: 'LESS_THAN_ONE_THOUSAND',
  },
  {
    label: 'Between R$ 1.000,00 and R$ 2.000,00',
    value: 'FROM_ONE_THOUSAND_TO_TWO_THOUSAND',
  },
  {
    label: 'Between R$ 2.000,00 and R$ 3.000,00',
    value: 'FROM_TWO_THOUSAND_TO_THREE_THOUSAND',
  },
  {
    label: 'Between R$ 3.000,00 and R$ 5.000,00',
    value: 'FROM_THREE_THOUSAND_TO_FIVE_THOUSAND',
  },
  {
    label: 'Between R$ 5.000,00 and R$ 10.000,00',
    value: 'FROM_FIVE_THOUSAND_TO_TEN_THOUSAND',
  },
  {
    label: 'Between R$ 10.000,00 and R$ 20.000,00',
    value: 'FROM_TEN_THOUSAND_TO_TWENTY_THOUSAND',
  },
  {
    label: 'Above R$ 20.000,00',
    value: 'OVER_TWENTY_THOUSAND',
  },
];

export const monthlyIncomePT = [
  {
    label: 'Menos de R$ 1.000,00',
    value: 'LESS_THAN_ONE_THOUSAND',
  },
  {
    label: 'Entre R$ 1.000,00 e R$ 2.000,00',
    value: 'FROM_ONE_THOUSAND_TO_TWO_THOUSAND',
  },
  {
    label: 'Entre R$ 2.000,00 e R$ 3.000,00',
    value: 'FROM_TWO_THOUSAND_TO_THREE_THOUSAND',
  },
  {
    label: 'Entre R$ 3.000,00 e R$ 5.000,00',
    value: 'FROM_THREE_THOUSAND_TO_FIVE_THOUSAND',
  },
  {
    label: 'Entre R$ 5.000,00 e R$ 10.000,00',
    value: 'FROM_FIVE_THOUSAND_TO_TEN_THOUSAND',
  },
  {
    label: 'Entre R$ 10.000,00 e R$ 20.000,00',
    value: 'FROM_TEN_THOUSAND_TO_TWENTY_THOUSAND',
  },
  {
    label: 'Acima de R$ 20.000,00',
    value: 'OVER_TWENTY_THOUSAND',
  },
];

export const monthlyIncomeSP = [
  {
    label: 'Menos de R$ 1.000,00',
    value: 'LESS_THAN_ONE_THOUSAND',
  },
  {
    label: 'Entre R$ 1.000,00 y R$ 2.000,00',
    value: 'FROM_ONE_THOUSAND_TO_TWO_THOUSAND',
  },
  {
    label: 'Entre R$ 2.000,00 y R$ 3.000,00',
    value: 'FROM_TWO_THOUSAND_TO_THREE_THOUSAND',
  },
  {
    label: 'Entre R$ 3.000,00 y R$ 5.000,00',
    value: 'FROM_THREE_THOUSAND_TO_FIVE_THOUSAND',
  },
  {
    label: 'Entre R$ 5.000,00 y R$ 10.000,00',
    value: 'FROM_FIVE_THOUSAND_TO_TEN_THOUSAND',
  },
  {
    label: 'Entre R$ 10.000,00 y R$ 20.000,00',
    value: 'FROM_TEN_THOUSAND_TO_TWENTY_THOUSAND',
  },
  {
    label: 'Por encima de R$ 20.000,00',
    value: 'OVER_TWENTY_THOUSAND',
  },
];

export const politicallyOptions = [
  {
    label: 'I am not a politically exposed person.',
    value: 'NONE',
  },
  {
    label: 'Yes, I am a politically exposed person.',
    value: 'SELF',
  },
  {
    label:
      'I have a family member, business partnership or am an associate of a politically exposed person.',
    value: 'RELATED',
  },
];

export const politicallyOptionsSP = [
  {
    label: 'No soy una persona políticamente expuesta.',
    value: 'NONE',
  },
  {
    label: 'Sí, soy una persona políticamente expuesta.',
    value: 'SELF',
  },
  {
    label:
      'Tengo un familiar, sociedad comercial o soy asociado de una persona políticamente expuesta.',
    value: 'RELATED',
  },
];

export const politicallyOptionsPT = [
  {
    label: 'Eu não sou uma pessoa politicamente exposta.',
    value: 'NONE',
  },
  {
    label: 'Sim, eu sou uma pessoa politicamente exposta.',
    value: 'SELF',
  },
  {
    label:
      'Eu tenho um membro da família, parceria comercial ou sou associado de uma pessoa politicamente exposta.',
    value: 'RELATED',
  },
];
