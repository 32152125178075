import * as Style from "../Kyc.styles";

import { ReactComponent as CardIcon } from "assets/images/icons/card.svg";
import { StepProps } from "../Kyc.types";
import { colors } from "~/styles";
import { useProgressBar } from "~/store";
import { useTranslation } from "react-i18next";
import { userId } from "~/services/url";
import Footer from "~/components/Footer";
import Header from "~/components/Header";
import * as Text from "styles/text";
import InfoImage from "assets/images/preparation-facetec.png";
import { ReactComponent as Sun } from "assets/images/icons/sun.svg";
import { ReactComponent as User } from "assets/images/icons/user-blue.svg";
import { ReactComponent as Happy } from "assets/images/icons/happy.svg";
import { ReactComponent as Glasses } from "assets/images/icons/glasses.svg";
import { useEffect } from "react";

export default function PreparationDoc(props: StepProps) {
  const { goToStep, pageArgs } = props;
  const { setProgressPct } = useProgressBar();

  const { t } = useTranslation();
  // end confirm modal

  const phrasesList = [
    {
      text: t(
        "Go to a well-lit location, preferably with natural light."
      ),
      icon: <Sun />,
    },
    {
      text: t("Remove the document from the protective plastic."),
      icon: <User />,
    },
    {
      text: t("Don't smile or make faces."),
      icon: <Happy />,
    },
    {
      text: t("Do not wear accessories such as glasses or caps."),
      icon: <Glasses />,
    },
  ];

  const userUuid = userId()

  useEffect(() => {
     // @ts-ignore
     gtag('event', 'screen_view', {
      'screen_name': 'preparation_step',
      userId: userUuid
    });
  }, [])

  return (
    <>
      <Style.ContainerCamera>
        <div className="content">
          <Header label="Documentos Pessoais" icon={<CardIcon />} />
          <Text.Heading1Medium
            fontSize={1.938}
            marginTop={24}
            marginBottom={24}
          >
            {t("Now let's validate your identity with some photos")}
          </Text.Heading1Medium>

          <img src={InfoImage} alt="Information" style={{ width: "100%" }} />

          <Text.Heading4 marginTop={24} marginBottom={16}>
            {t("Please carefully follow the guidelines below. In this way, we will avoid possible problems during the verification process.")}
          </Text.Heading4>

          <Text.Heading4Bold color={colors.primary}>
            {t("Before shooting")}
          </Text.Heading4Bold>

          <div className="list-item-wrapper-column">
            {phrasesList.map((p) => (
              <div
                key={p.text}
                style={{ alignItems: "center" }}
                className="list-item-row"
              >
                <div
                  style={{
                    backgroundColor: "rgba(15, 144, 255, 0.1)",
                    width: 50,
                    minWidth: 50,
                    height: 50,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 15,
                  }}
                  className="list-item-icon"
                >
                  {p.icon}
                </div>
                <Text.Heading4 style={{ alignSelf: "center" }}>
                  {p.text}
                </Text.Heading4>
              </div>
            ))}
          </div>
        </div>
        <Footer
          onClickLeftButton={() => {}}
          leftButtonContent="Voltar"
          onClickGenericButton={() => goToStep("selectDoc")}
          genericButtonType="secondary"
          genericButtonContent={t("Continue")}
        />
      </Style.ContainerCamera>
    </>
  );
}
