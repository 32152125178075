import * as Style from './SelectRadio.styles';

import { SelectRadioProps } from './SelectRadio.types';
import { forwardRef } from 'react';

const SelectRadio = forwardRef((props: SelectRadioProps, _ref) => {
  return (
    <Style.Container {...props}>
      <label>
        {props.label}
        <Style.Input {...props} type="radio" />
      </label>
    </Style.Container>
  );
});

export default SelectRadio;
