import { FaceTecSDK } from "./core-sdk/FaceTecSDK.js/FaceTecSDK";
import { Config } from "./config";
import { FacetecInitializerUtilities } from "./utilities/FacetecInitializerUtilities";
import { PhotoIDMatchProcessor } from "./processors/PhotoIDMatchProcessor";
import FaceTecStrings from "./core-sdk/FaceTecStrings.pt-br";

interface CustomData {
  userId: string;
  callbackOnFinishCapFace: (rtn: string) => void;
  callbackOnErrorCapFace: (rtn: string) => void;
  callbackChangeToDefaultFlow: () => void;
}

export var FacetecInitializer = (function () {
  var latestEnrollmentIdentifier = "";
  var latestSessionResult = null;
  var latestIDScanResult = null;
  var latestProcessor: any;
  var data: CustomData;

  // Wait for onload to be complete before attempting to access the Browser SDK.
  window.onload = function () {
    // Set a the directory path for other FaceTec Browser SDK Resources.
    FaceTecSDK.setResourceDirectory("/resources");

    // Set the directory path for required FaceTec Browser SDK images.
    FaceTecSDK.setImagesDirectory("/FaceTec_images");

    // const IS_PROD_CAPFACE_ENV = true; //process.env.CAPFACE_ENV === 'prod';


    //Prod
    FaceTecSDK.initializeInProductionMode(
      Config.productionKey,
      Config.DeviceKeyIdentifier,
      Config.PublicFaceScanEncryptionKey,
      function (initializedSuccessfully) {
        if (initializedSuccessfully) {
          FacetecInitializerUtilities.enableAllButtons();
          FaceTecSDK.configureLocalization(FaceTecStrings);
        }
        FacetecInitializerUtilities.displayStatus(
          FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
            FaceTecSDK.getStatus()
          )
        );
      }
    );


    //Dev
    // FaceTecSDK.initializeInDevelopmentMode(
    //   Config.DeviceKeyIdentifier,
    //   Config.PublicFaceScanEncryptionKey,
    //   function (initializedSuccessfully) {
    //     if (initializedSuccessfully) {
    //       FacetecInitializerUtilities.enableAllButtons();
    //       FaceTecSDK.configureLocalization(FaceTecStrings);
    //     }
    //     FacetecInitializerUtilities.displayStatus(
    //       FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
    //         FaceTecSDK.getStatus()
    //       )
    //     );
    //   }
    // );

    
  };

  // Clear previous session results;
  function initializeResultObjects(): void {
    latestSessionResult = null;
    latestIDScanResult = null;
  }

  // Perform a 3D Liveness Check, then an ID Scan, then Match the 3D FaceMap to the ID Scan.
  function onPhotoIDMatchPressed(customData: CustomData): void {
    data = customData;

    initializeResultObjects();
    // FacetecInitializerUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
    getSessionToken((sessionToken?: string): void => {
      latestEnrollmentIdentifier =
        "browser_settlekyc_" + FacetecInitializerUtilities.generateUUId();
      latestProcessor = new PhotoIDMatchProcessor(
        sessionToken as string,
        FacetecInitializer as any,
        customData
      );
    });
  }

  // Show the final result and transition back into the main interface.
  function onComplete() {
    // FacetecInitializerUtilities.showMainUI();

    if (!latestProcessor.isSuccess()) {
      // Reset the enrollment identifier.
      latestEnrollmentIdentifier = "";

      // Show early exit message to screen.  If this occurs, please check logs.
      // FacetecInitializerUtilities.displayStatus(
      //   "Session exited early, see logs for more details."
      // );

      return;
    }

    // Show successful message to screen
    // FacetecInitializerUtilities.displayStatus("Success");
    return "success";
  }

  // Get the Session Token from the server
  function getSessionToken(sessionTokenCallback: {
    (sessionToken: any): void;
    (arg0: string): void;
  }) {
    var XHR = new XMLHttpRequest();
    XHR.open("GET", Config.BaseURL + data.userId + "/Process_SessionToken");
    XHR.setRequestHeader("X-Device-Key", Config.DeviceKeyIdentifier);
    XHR.setRequestHeader(
      "X-User-Agent",
      FaceTecSDK.createFaceTecAPIUserAgentString("")
    );
    XHR.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        var sessionToken = "";
        try {
          // Attempt to get the sessionToken from the response object.
          sessionToken = JSON.parse(this.responseText).sessionToken;
          // Something went wrong in parsing the response. Return an error.
          if (typeof sessionToken !== "string") {
            onServerSessionTokenError();
            return;
          }
        } catch {
          // Something went wrong in parsing the response. Return an error.
          onServerSessionTokenError();
          return;
        }
        sessionTokenCallback(sessionToken);
      }
    };

    XHR.onerror = function () {
      onServerSessionTokenError();
    };
    XHR.send();
  }

  function onServerSessionTokenError() {
    FacetecInitializerUtilities.handleErrorGettingServerSessionToken();
  }

  //
  // DEVELOPER NOTE:  This is a convenience function for demonstration purposes only so the Sample App can have access to the latest session results.
  // In your code, you may not even want or need to do this.
  //
  function setLatestSessionResult(sessionResult: any) {
    latestSessionResult = sessionResult;
  }

  function setIDScanResult(idScanResult: any) {
    latestIDScanResult = idScanResult;
  }

  function getLatestEnrollmentIdentifier() {
    return latestEnrollmentIdentifier;
  }

  function clearLatestEnrollmentIdentifier() {
    latestEnrollmentIdentifier = "";
  }

  function setLatestServerResult(responseJSON: any) {}

  return {
    onComplete,
    setLatestSessionResult,
    setIDScanResult,
    getLatestEnrollmentIdentifier,
    setLatestServerResult,
    onPhotoIDMatchPressed,
    clearLatestEnrollmentIdentifier,
  };
})();
