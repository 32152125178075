import { Crop } from "react-image-crop";

export async function loadImg(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    let img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.src = src;
  });
}

export async function rotateImage(
  srcBase64: string,
  degrees: number,
  callback: any
) {
  const canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  let image = new Image();

  image.onload = function () {
    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    callback(canvas.toDataURL("image/jpeg", 1));
  };

  image.src = srcBase64;
}

export function compressImage(src: string, newX: number, newY: number) {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement("canvas");
      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext("2d");
      if (!ctx) return;
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      res(data);
    };
    img.onerror = (error) => rej(error);
  });
}

export const getCroppedImg = (image: any, crop: Crop, fileName: string) => {
  const canvas = document.createElement("canvas");

  if (!image || !crop) return null;

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  const base64Image = canvas.toDataURL("image/jpeg");
  return base64Image;
};

export function getImageDimensions(file: string) {
  if (!file || !file.length) return { w: 0, h: 0 };
  return new Promise(function (resolved, rejected) {
    var i = new Image();
    i.onload = function () {
      resolved({ w: i.width, h: i.height });
    };
    i.src = file;
  });
}

export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function toAspectRatio(url: string, aspectRatio: number) {
  return new Promise((resolve) => {
    const inputImage = new Image();

    inputImage.onload = () => {
      const inputWidth = inputImage.naturalWidth;
      const inputHeight = inputImage.naturalHeight;

      const inputImageAspectRatio = inputWidth / inputHeight;

      let outputWidth = inputWidth;
      let outputHeight = inputHeight;
      if (inputImageAspectRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio;
      } else if (inputImageAspectRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio;
      }

      const outputX = (outputWidth - inputWidth) * 0.5;
      const outputY = (outputHeight - inputHeight) * 0.5;

      const outputImage = document.createElement("canvas");

      outputImage.width = outputWidth;
      outputImage.height = outputHeight;

      const ctx = outputImage.getContext("2d");

      ctx.drawImage(inputImage, outputX, outputY);

      resolve(outputImage.toDataURL("image/jpeg", 1));
    };

    inputImage.src = url;
  });
}
