import React, { useEffect, useRef, useState } from "react";
import * as Style from "../Kyc.styles";
import * as Text from "styles/text";
import Footer from "~/components/Footer";
import { StepProps } from "../Kyc.types";
import { Else, If, Then, When } from "react-if";
import imageCompression from "browser-image-compression";
import Header from "~/components/Header";
import { ReactComponent as CardIcon } from "assets/images/icons/card.svg";
import { useTranslation } from "react-i18next";
import { getBase64, toAspectRatio } from "~/helpers/images/manipulate";
import { ReactComponent as Sun } from "assets/images/icons/sun.svg";
import { ReactComponent as User } from "assets/images/icons/user-blue.svg";
import { ReactComponent as Happy } from "assets/images/icons/happy.svg";
import { ReactComponent as Glasses } from "assets/images/icons/glasses.svg";
import selfieExample from "assets/images/selfie-model.png";
import wrongSelfie from "assets/images/modalExamples/wrong-selfie-model.png";
import correctSelfie from "assets/images/modalExamples/correct-selfie-model.png";
import holdingDocs from "assets/images/modalExamples/doc-selfie-model.png";
import glassesSelfie from "assets/images/modalExamples/without-glasses-selfie-model.png";
import noGlassesSelfie from "assets/images/modalExamples/glasses-selfie-model.png";
import arrowSelfie from "assets/images/arrow.png";
import { Trans } from "react-i18next";
import Selfie from "assets/images/selfie.png";
import "react-image-crop/dist/ReactCrop.css";
import { colors } from "~/styles";
import { Circles } from "react-loader-spinner";
import { useProgressBar } from "~/store";
import axiosInst from "~/services/axios";
import { userId } from "~/services/url";
import SeeCameraPage from "./CameraPages/Pages/SeeCameraPage";
import Modal from "~/components/Modal";
import ImageViewer from "~/components/ImageViewer";

export default function SendSelfieDefaultFlow({
  goToStep,
  goBack,
  setShowNativeCam,
  showNativeCam,
}: StepProps) {
  const { t } = useTranslation();
  const userUuid = userId();

  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSendSelfie, setLoadingSendSelfie] = useState(false);
  const [openViewerImage, setOpenViewerImage] = useState(false);
  const [showModalInfo, setShowModaInfo] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const openCamera = () => inputRef.current.click();

  const options = {
    maxSizeMB: 0.9216,
    maxWidthOrHeight: 1400,
    useWebWorker: true,
    fileType: "image/jpeg",
  };

  const webcamRef = useRef<any>(null);
  const [openWebCam, setOpenWebCam] = useState(false);

  const capture = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    let base64WebCam = null;

    if (openWebCam) {
      let base64 = await webcamRef.current.takePhoto();

      if (!base64) return;

      base64WebCam = await imageCompression.getFilefromDataUrl(
        base64,
        "selfie"
      );
    }

    try {
      const compressedFile = openWebCam ? base64WebCam : e.target.files[0];

      const imageB64 = await getBase64(compressedFile);

      toAspectRatio(String(imageB64), 3 / 4).then((image: any) => {
        setImg(String(image));
      });
    } catch (error) {
      console.log(error);
    }

    inputRef.current.value = "";
    setLoading(false);
  };

  const noImg = img === "";

  const sendSelfie = async () => {
    if (selfieModalPosition === 0) {
      setShowModaInfo(true);
      return;
    }

    setLoadingSendSelfie(true);

    const fileImage = await imageCompression.getFilefromDataUrl(img, "selfie");

    const compressedFile = await imageCompression(fileImage, options);

    const imageB64 = await getBase64(compressedFile);

    const resp = {
      userKycData: {
        selfieB64: imageB64,
      },
    };

    try {
      await axiosInst.post("registercustomer/" + userUuid, resp);
      goToStep("finish", {});
    } catch (e) {
      alert(t("Error on send docs, please retry."));
      console.error(e);
    }

    setLoadingSendSelfie(false);
  };

  const tryAgain = () => {
    setShowModaInfo(false);
    setSelfieModalPosition(0);
    setImg("");
  };

  const phrasesList = [
    {
      text: t("Go to a well-lit place"),
      icon: <Sun />,
    },
    {
      text: t("The photo must be taken from the front."),
      icon: <User />,
    },
    {
      text: t("Don't smile or make faces."),
      icon: <Happy />,
    },
    {
      text: t("Do not wear accessories such as glasses or caps."),
      icon: <Glasses />,
    },
  ];

  const lastPagePhrasesList = [
    {
      text: t("Clear environment"),
      icon: <Sun />,
    },
    {
      text: t("Correct position"),
      icon: <User />,
    },
    {
      text: t("No smiles or grimaces"),
      icon: <Happy />,
    },
    {
      text: t("No accessories"),
      icon: <Glasses />,
    },
  ];

  const { setProgressPct } = useProgressBar();

  useEffect(() => {
    if (!noImg) {
      setProgressPct(100);
    }
  }, [noImg]);

  useEffect(() => {
     // @ts-ignore
     gtag('event', 'screen_view', {
      'screen_name': 'send_selfie_native_step',
      userId: userUuid
    });
  }, [])

  const useNativeWebCam = () => {
    openCamera();
    setShowNativeCam(true);
  };

  ///// modal confirm selfie part

  const [selfieModalPosition, setSelfieModalPosition] = useState(0);

  const tryPhotoAgain = () => {
    setSelfieModalPosition(0);
    tryAgain();
  };

  const nextModalStep = () => {
    setSelfieModalPosition((prev) => prev + 1);
  };

  const selfieModalStep = [
    <Modal
      key={1}
      image={img}
      title={t("Is the photo well lit, with no shadows or blurs?")}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that the photo has good lighting, without shadows or blurs."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="selfie"
      stepPosition="1 / 5"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextModalStep}
    />,
    <Modal
      key={2}
      image={img}
      imageAndComponent={
        <Style.ContainerCamera style={{ height: "100%" }} noMargin>
          <div className="content">
            <div className="container-selfie-example">
              <img
                style={{
                  width: "45%",
                }}
                className="container-selfie-image"
                src={img}
                alt="selfie"
              />
              <div className="arrow-container">
                <img src={arrowSelfie} />
              </div>
              <img
                style={{}}
                className="container-selfie-image"
                src={selfieExample}
                alt="selfie-example"
              />
            </div>
          </div>
        </Style.ContainerCamera>
      }
      title={t(
        "Is your face positioned correctly, as shown in the illustration below?"
      )}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that my face is positioned correctly in the image."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="selfie"
      stepPosition="2 / 5"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextModalStep}
    />,
    <Modal
      key={3}
      image={img}
      secondImage={wrongSelfie}
      thirdImage={correctSelfie}
      title={t("Is your facial expression neutral, no smiles or grimaces?")}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that my expression is neutral, without smiles or grimaces."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="selfie"
      stepPosition="3 / 5"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextModalStep}
    />,
    <Modal
      key={4}
      image={img}
      secondImage={noGlassesSelfie}
      thirdImage={glassesSelfie}
      title={t("Are you wearing any accessories, like glasses or a hat?")}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that I am not wearing any accessories in the photo"
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="selfie"
      stepPosition="4 / 5"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={nextModalStep}
    />,
    <Modal
      key={5}
      image={img}
      thirdImage={glassesSelfie}
      secondImage={holdingDocs}
      title={t("Are your hands free, without holding any object?")}
      subTitle={t("Tap on a photo to see more details.")}
      checkBoxLabel={t(
        "I confirm that I am not holding any objects in the photo."
      )}
      buttonLeftText={t("Repeat photo")}
      buttonRigthText={t("Continue")}
      setOpenModal={() => {}}
      openModal={true}
      type="selfie"
      stepPosition="5 / 5"
      onClickImg={() => setOpenViewerImage(true)}
      buttonLeftClick={tryPhotoAgain}
      buttonRigthClick={sendSelfie}
    />,
  ];

  /// end modal

  return (
    <>
      <input
        ref={inputRef}
        style={{ display: "none" }}
        id="my_selfie"
        type="file"
        accept="image/png, image/jpeg"
        capture={"user"}
        onChange={(e) => {
          capture(e);
        }}
      />
      <If condition={!loading}>
        <Then>
          <Style.ContainerCamera style={{ overflow: "scroll" }}>
            <If condition={img === ""}>
              <Then>
                <When condition={openWebCam}>
                  <SeeCameraPage
                    webcamRef={webcamRef}
                    goBack={() => setOpenWebCam(false)}
                    setUseNativeCam={useNativeWebCam}
                    isSelfie
                    saveFile={capture}
                  />
                </When>

                <When condition={!openWebCam}>
                  <div className="content-wrapper-no-img">
                    <Header label="Selfie" icon={<CardIcon />} />
                    <Text.Heading1Medium
                      fontSize={1.938}
                      marginTop={24}
                      marginBottom={24}
                    >
                      {t("Selfie")}
                    </Text.Heading1Medium>

                    <div className="example-img-wrapper">
                      <div className="example-img-selfie">
                        <img src={Selfie} style={{ width: "100%" }} />
                      </div>
                    </div>

                    <Text.Heading3>
                      {t("Before taking the photo, remember:")}
                    </Text.Heading3>

                    <div className="list-item-wrapper-column">
                      {phrasesList.map((p) => (
                        <div
                          key={p.text}
                          style={{ alignItems: "center" }}
                          className="list-item-row"
                        >
                          <div
                            style={{
                              backgroundColor: "rgba(15, 144, 255, 0.1)",
                              width: 50,
                              minWidth: 50,
                              height: 50,
                              borderRadius: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: 15,
                            }}
                            className="list-item-icon"
                          >
                            {p.icon}
                          </div>
                          <Text.Heading4 style={{ alignSelf: "center" }}>
                            {p.text}
                          </Text.Heading4>
                        </div>
                      ))}
                    </div>
                  </div>
                </When>
              </Then>
              <Else>
                <div className="content">
                  <Header label={t("Selfie")} icon={<CardIcon />} />
                  <Text.Heading1Medium
                    marginTop={24}
                    marginBottom={24}
                    fontSize={1.938}
                  >
                    {t("Is everything ok with the captured photo?")}
                  </Text.Heading1Medium>
                  <div className="center-img">
                    <div className="display-center">
                      <img
                        className="container-selfie-image"
                        src={img}
                        alt="selfie"
                      />
                    </div>
                  </div>
                  <div className="list-item-wrapper-row">
                    {lastPagePhrasesList.map((p) => (
                      <div key={p.text} className="list-item-column">
                        <div
                          style={{
                            backgroundColor: "rgba(15, 144, 255, 0.1)",
                            width: 50,
                            minWidth: 50,
                            height: 50,
                            marginBottom: 10,
                            borderRadius: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="list-item-icon"
                        >
                          {p.icon}
                        </div>
                        <Text.SmallBold style={{ alignSelf: "center" }}>
                          {p.text}
                        </Text.SmallBold>
                      </div>
                    ))}
                  </div>
                  <Trans t={t}>If the selfie’s blurred</Trans>
                  <Style.Space style={{ marginBottom: 22 }} />
                </div>
              </Else>
            </If>
          </Style.ContainerCamera>
        </Then>

        <Else>
          <Circles
            wrapperStyle={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            color={colors.primary}
            height={80}
            width={80}
          />
        </Else>
      </If>
      <When condition={!openWebCam || img.length > 1}>
        <Footer
          onClickLeftButton={noImg ? goBack : tryAgain}
          leftButtonContent={noImg ? t("Back") : t("Repeat photo")}
          onClickGenericButton={
            noImg
              ? !showNativeCam
                ? () => setOpenWebCam(true)
                : openCamera
              : sendSelfie
          }
          genericButtonType="secondary"
          genericButtonContent={noImg ? t("Continue") : t("Use this photo")}
          loadingGenericButton={loadingSendSelfie}
        />
      </When>

      <When
        condition={img.length > 1 && showModalInfo && selfieModalPosition < 5}
      >
        {selfieModalStep[selfieModalPosition]}
      </When>

      <When condition={openViewerImage}>
        <ImageViewer
          isSelfie
          image={img}
          setOpenViewerImage={setOpenViewerImage}
        />
      </When>
    </>
  );
}