import React, { useEffect } from "react";
import * as Text from "styles/text";
import * as Style from "../Kyc.styles";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { StepProps } from "../Kyc.types";
import { ReactComponent as BirthDateIcon } from "assets/images/icons/birth.svg";
import { BigInput } from "~/components";
import { dateMask } from "~/helpers";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { useProgressBar } from "~/store";
import { userId } from "~/services/url";

export default function FormBirthDate({ goToStep }: StepProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const { setProgressPct, totalOfSteps } = useProgressBar();

  const userUuid = userId()

  useEffect(() => {
    // @ts-ignore
    gtag('event', 'screen_view', {
      'screen_name': 'birth_step',
      userId: userUuid
    });
    setProgressPct((6 / totalOfSteps) * 100);
  }, []);

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<BirthDateIcon />} />
      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t("What’s your birth date?")}
      </Text.Heading1Medium>

      <Controller
        name="birthDate"
        control={control}
        render={({ field }) => (
          <BigInput
            {...field}
            autoFocus
            placeholder={t("dd/mm/yyyy")}
            maskFunction={dateMask}
            error={errors[field.name]?.message as string}
          />
        )}
      />

      <Footer
        onClickLeftButton={() => goToStep("prev")}
        leftButtonContent={t("Back")}
        onClickGenericButton={() => goToStep("next")}
        disableGenericButton={!watch("birthDate")}
        genericButtonType="secondary"
        genericButtonContent={t("Continue")}
      />
    </Style.StepContent>
  );
}
