import { breakpoints, screen } from "styles/metrics";
import styled, { css } from "styled-components";

import { GeneralStyleProps } from "~/types/general.types";
import { colors } from "styles";
import { transparentize } from "polished";

export const KycContainer = styled.main`
  justify-content: center;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;

  .text-right {
    text-align: right;
    padding-right: 9px;
    cursor: pointer;
  }

  .image-container {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .mask-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    img {
      width: 95%;
      padding: 5px;
      z-index: 999;
      position: absolute;
    }
  }

  @media ${screen.tablet} {
    flex-direction: column;
  }
`;

export const Space = styled.div`
  width: 20px;
  height: 20px;
`;

interface ContainerCameraProps {
  noMargin?: boolean;
}

export const ContainerCamera = styled.div<ContainerCameraProps>`
  flex-direction: column;
  display: flex;
  width: 100%;
  height: ${({ noMargin }) => (!noMargin ? 100 : 50)}%;
  margin-bottom: ${({ noMargin }) => (!noMargin ? 75 : 0)} px;

  .list-item-icon {
    background-color: rgba(15, 144, 255, 0.1);
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .loading-rotate-container {
    position: absolute;
    z-index: 1;
  }

  .container-rotate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10%;
    align-self: center;
    margin-bottom: 24px;
    margin-top: 25%;

    button {
      border-radius: 15px;
      border: none;
      padding: 15px;
      background-color: ${colors.primary};
      width: 57px;
      height: 54px;
    }

    img {
      width: 112px;
      height: 84;
    }
  }

  .container-docs-image {
    width: 90%;
    height: 100%;
    border-radius: 8px;
    margin-top: 15%;
  }

  .hide_input {
    display: none;
  }

  .image-selfie {
    width: 100%;
    height: 100%;
  }

  .container-aspect-ratio {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: ${({ noMargin }) => (!noMargin ? 80 : 0)}px;

    .instruction-confirm-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 16px;
      background-color: ${colors.gray};
      border-radius: 16px;
      margin-bottom: 30px;

      p {
        max-width: 65%;
        margin-left: 16px;
      }
    }

    .align-selfie-resized {
      width: 100%;
      height: 50%;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 20px;
    }

    .center-img {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 20px;
    }

    .display-center {
      width: 60%;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 24px;
      border-radius: 10px;

      .container-selfie-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        object-fit: contain;
      }
    }

    .container-selfie-example {
      width: 100%;
      gap: 5%;
      justify-content: center;
      align-items: center;
      display: flex;

      .container-selfie-image {
        width: 50%;
        height: 100%;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        border-radius: 10px;
        object-fit: contain;
      }

      .arrow-container {
        position: relative;
        img {
          position: absolute;
          z-index: 99999;
          width: ${window.screen.width * 0.1}px;
          right: -20px;
          top: -15px;
        }
      }
    }

    .list-item-wrapper-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .list-item-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      min-width: 76px;
    }
  }

  .pic-container {
    margin-bottom: 24px;
    width: 100%;
  }

  .mask-image {
    z-index: 999;
    position: absolute;
  }

  .mask-image-selfie {
    z-index: 999;
    position: absolute;
  }

  .crop-image-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const SideImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 50%;
  padding: 31px 40px;
  background-color: ${colors.primary};

  .logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .text-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 170px;

      select {
        color: ${colors.white};
        border: none;
        background-color: ${colors.transparent};
      }
    }
  }
`;

export const Content = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  width: ${(props) => props.width || 100}%;
  height: 100%;

  .content-wrapper-no-img {
    width: 100%;
    padding: 24px;
    overflow-y: auto;
    padding-bottom: 150px;
  }

  .example-img-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .example-img {
    width: 100%;
    height: 100%;
    margin-right: 5px;
  }

  .example-img-selfie {
    width: 100%;
    margin-bottom: 24px;
    border-radius: 16px;
  }

  .list-item-wrapper {
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }

  .list-item-wrapper-column {
    display: flex;
    flex-direction: column;
  }

  .list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    text-align: center;
  }

  .list-item-row {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  @media ${screen.tablet} {
    justify-content: flex-start;

    width: 100%;
  }
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${transparentize(0.7, colors.grayLight)};
  position: absolute;
  bottom: 74px;
  right: 0;

  .bar {
    transition: width 0.3s ease-out;
    max-width: 100%;
    height: 3px;
    background-color: ${colors.black};
  }
`;

export const StepContent = styled.div<GeneralStyleProps>`
  width: 100%;
  padding: 0 100px;

  .container-more-docs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    gap: 5px;

    :hover {
      cursor: pointer;
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      transition: transform 0.3s ease-out;
    }

    .active {
      transform: rotate(-180deg);
    }
  }

  margin-bottom: ${({ marginBottom }) => marginBottom || 74}px;
  &.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
  }

  .hide_input {
    display: none;
  }

  .user-icon {
    border-radius: 15px;
  }

  .instruction-container {
    display: flex;
    justify-content: space-between;

    @media ${screen.tablet} {
      flex-direction: column;
    }

    .instruction-card {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 32%;
      height: 128px;
      background-color: ${colors.transparent};
      border: 1px solid ${colors.grayLight};
      padding: 23px 17px;
      border-radius: 8px;
      margin-bottom: 8px;

      img {
        margin-bottom: 15px;
      }

      p {
        text-align: center;
      }

      @media (min-width: ${breakpoints.tablet}px) and (max-width: 1070px) {
        p {
          font-size: 0.8rem;
        }
      }

      @media ${screen.tablet} {
        flex-direction: row;
        justify-content: flex-start;

        width: 100%;
        height: auto;
        background-color: ${colors.gray};
        border: none;

        img {
          margin-bottom: 0px;
        }

        p {
          margin-left: 31px;
          text-align: initial;
        }
      }
    }
  }

  .input-group {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
    gap: 20px;
    margin: 10px 0;

    select {
      -webkit-appearance: menulist-button;
      border-width: 0px 0px 1px 0px;
      border-color: ${colors.grayRegular};
      padding-left: 12px;
    }

    select:invalid {
      color: ${colors.grayRegular};
    }
  }

  @media (min-width: ${breakpoints.largeDesktop}px) {
    max-width: 520px;
    padding: 0;
  }

  @media (max-width: 1200px) {
    padding: 0 50px;
  }

  @media ${screen.tablet} {
    max-width: 100%;
    padding: 24px;
    overflow-y: auto;
  }

  .modal-backdrop {
    background-color: ${transparentize(0.5, colors.black)};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: ${colors.white};
      border-radius: 8px;
      padding: 24px;
      gap: 24px;

      h1 {
        font-weight: 400;
      }

      div {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 12px;
      }
    }
  }
`;

export const List = styled.div`
  height: 390px;
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;

  @media ${screen.tablet} {
    height: calc(100% - 240px);
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 24px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
`;
