import React, { useEffect, useState } from "react";
import * as Text from "styles/text";
import * as Style from "../Kyc.styles";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { StepProps } from "../Kyc.types";
import { ReactComponent as CardIcon } from "assets/images/icons/card.svg";
import { BigInput } from "~/components";
import { useProgressBar } from "~/store";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { userId } from "~/services/url";
import { nameMask } from "~/helpers";
import { nameAndCpfMatch, checkUserExists } from "~/helpers/validation/personalValidation";
import { colors } from "~/styles";

export default function FormName({ goToStep, pageArgs }: StepProps) {
  const { t } = useTranslation();
  const { setProgressPct, totalOfSteps } = useProgressBar();
  const [nameError, setNameError] = useState("");

  const userUuid = userId();


  const {
    control,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();

  const goNext = async () => {
    let resp = await nameAndCpfMatch(
      pageArgs?.cpfToCheck,
      getValues("fullName")
    );

    if (resp === "E_INVALID_CPF") {
      setNameError("Invalid cpf");
      return;
    }
    if (resp === "E_MISSING_NAME") {
      setNameError("Missing name");
      return;
    }
    if (resp === true) {
      const cpf = pageArgs?.cpfToCheck.replace(/[^\d]+/g, '')
      let userExists = await checkUserExists(userUuid, cpf);

      if (userExists) {
        setNameError("User already exists");
        return;
      }
      
      goToStep("next");
    } else {
      setNameError("Name and CPF don't match");
    }
  };

  useEffect(() => {
    if (getValues("fullName") === "") {
      setNameError("");
    }
  }, [watch("fullName")]);

  useEffect(() => {
    setProgressPct((1 / totalOfSteps) * 100);
  }, []);

  useEffect(() => {
    // @ts-ignore
    gtag('event', 'screen_view', {
      'screen_name': 'Name_step',
      userId: userUuid
    });
    document.addEventListener("keypress", function (event) {
      if (event.keyCode == 13 && watch("fullName")) {
        goToStep("next");
      }
    });
  }, []);

  return (
    <Style.StepContent>
      <Header label="Personal Data" icon={<CardIcon />} />

      <Text.Heading1Medium fontSize={1.938} marginTop={24} marginBottom={24}>
        {t("Let's get started")}
      </Text.Heading1Medium>

      <Text.Heading4 marginTop={24}>{t("Your name must be")}</Text.Heading4>

      <Controller
        name="fullName"
        render={({ field }) => (
          <>
            <BigInput
              {...field}
              autoFocus
              placeholder={t("E.g.") + " André Batista"}
              error={errors[field.name]?.message as string}
              maskFunction={nameMask}
            />
            <Text.Small color={colors.danger}>{t(nameError)}</Text.Small>
          </>
        )}
        control={control}
      />

      <Footer
        onClickLeftButton={() => goToStep("prev")}
        leftButtonContent={t("Back")}
        onClickGenericButton={goNext}
        disableGenericButton={!watch("fullName")}
        genericButtonType="secondary"
        genericButtonContent={t("Continue")}
      />
    </Style.StepContent>
  );
}
