import React, { useEffect } from "react";
import Lottie from "lottie-react-web";
import animation from "~/data/loadingAnim.json";
import * as Style from "../Kyc.styles";
import * as Text from "styles/text";
import { StepProps } from "../Kyc.types";
import { useProgressBar } from "~/store";
import { qs, userId } from "~/services/url";
import KycAnalise from "assets/images/analise.png";
import { GenericButton } from "~/components/Buttons";
import { useTranslation } from "react-i18next";
import { StringSelect } from "~/helpers/string";

export default function Finish({ onSubmit, pageArgs }: StepProps) {
  const { setProgressPct } = useProgressBar();
  const userUuid = userId()

  const [isValidReturnUrl, setIsValidReturnUrl] = React.useState(false);

  useEffect(() => {
    // @ts-ignore
    gtag("event", "screen_view", {
      screen_name: "finish_step",
      userId: userUuid
    });
    setProgressPct(0);

    if (onSubmit) {
      onSubmit();
    }

    if (!String(qs("return_url")).includes("undefined")) {
      setIsValidReturnUrl(true);
    }
  }, []);

  const { t } = useTranslation();

  const goHome = () => {
    var returnUrl = qs("return_url");
    if (returnUrl && Array.isArray(returnUrl) && returnUrl.length) {
      if (returnUrl[0] === "close") {
        if (window.opener != null || window.history.length == 1) {
          window.close();
        } else {
          console.warn(
            "Browser windows can only be closed if they are a popup or a new tab opened by another tab."
          );
        }
      } else {
        (window as any).location = returnUrl; 
      }
    } else {
      console.warn('"return_url" query parameter does not exist');
    }
  };

  // <img src={pageArgs.frontImg} style={{ width: 150, height: 150 }} />
  // <img src={pageArgs.backImg} style={{ width: 150, height: 150 }} />

  return (
    <Style.StepContent
      style={{ marginBottom: 0, backgroundColor: "#fff" }}
      className="center-container"
    >
      {pageArgs.uploadStatus !== "complete" ? (
        <>
          <Text.Heading1Medium fontSize={1.938} marginTop={8}>
            {t("Please wait...")}
          </Text.Heading1Medium>
          <Lottie
            options={{
              animationData: animation,
              autoplay: true,
              loop: true,
            }}
          />
          <Text.Heading4
            style={{ position: "absolute", bottom: 40 }}
            textAlign="center"
          >
            {t(
              StringSelect(pageArgs.uploadStatus)({
                sending: "Hold on while we store your documents securely.",
                failed: "An error occurred. Trying again...",
              })
            )}
          </Text.Heading4>
        </>
      ) : (
        <>
          <img src={KycAnalise} alt="Tech guys" />

          <Text.Heading1Medium fontSize={1.938} marginTop={8}>
            {t("Now it's our turn!")}
          </Text.Heading1Medium>
          <Text.Heading4 marginTop={24} marginBottom={24} textAlign="center">
            {t("We will review the submitted")}
          </Text.Heading4>

          {isValidReturnUrl && (
            <GenericButton buttonType="inline" onClick={goHome}>
              <Text.Heading5>{t("Back to home")}</Text.Heading5>
            </GenericButton>
          )}
        </>
      )}
    </Style.StepContent>
  );
}
